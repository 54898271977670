import store from '@/misc/vuex-store.js'

const tenantData = JSON.parse(process.env.VUE_APP_NRGMAP_TENANT_CONFIG);
let tenantSet = false;
for(let tData of tenantData)
{
  if(tData["NRGMAP_TENANT_HOST"] == window.location.host)
  {
    store.commit('setTenantConfig', tData);
    tenantSet = true;
    break;
  }
}
if(!tenantSet && process.env.VUE_APP_ALLOW_DEFAULT_TENANT == "true")
{
    store.commit('setTenantConfig', tenantData[0]);
}

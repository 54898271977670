<template>
  <div id="PropertiesPage" class="h-screen">
    <BaseLayout>
      <template #mainContent>
        <div>
          <div class="mt-2">
            <header class="w-full shadow-sm body-font">
              <div class="items-start px-6 pb-6 mx-auto md:flex-row">
                <p
                  class="flex mb-4 text-xl text-secondary md:mb-0 uppercase font-bold"
                >
                  {{ $t("message.properties") }}
                </p>
              </div>
            </header>
          </div>
          <TransitionGroup name="fade">
            <!--PROPERTIES-->
            <div
                v-if="state.tab == 1"
                class="flex flex-col im-main-card rounded-xl im-main-card-starter px-6"
              >
                <div>
                  <button
                    @click="
                      state.tab = 2;
                      state.propertyId = 0;
                      clearData(state.propertyBasicData);
                      clearData(state.propertyAddress)
                    "
                    class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8"
                  >
                    {{ $t("message.addNew") }}
                  </button>
                  <hr class="mb-8" />
                  <div class="im-card-form">
                    <RobberVueGrid
                      ref="propertiesGrid"
                      :config="propertiesGridConfigComputed"
                    />
                  </div>
                </div>
              </div>
              <!--ADD PROPERTY-->
              <div
                class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                v-if="state.tab == 2"
              >
                <div>
                  <button
                    class="btn btn-secondary btn-sm btn-circle mb-8"
                    @click="setConfirmation()"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                      />
                    </svg>
                  </button>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    {{ $t("message.addNew") }}
                  </h2>
                  <p class="text-sm text-error w-full font-bold">{{$t("message.propAddressError")}}</p>
                  <div class="im-card-form">
                    <div
                      v-for="item in state.propertyBasicData"
                      v-bind:key="item.model"
                      class="mb-8"
                    >
                     <!-- eslint-disable -->
            
                      <ImFormInput
                      v-model:inputValue="item.value"
                        v-model:parent="item.parent" 
                        :title="$t('message.' + item.title)"
                        :type="item.type"
                        :valueLabel="item.valueLabel"
                        :optionValue="item.optionValue"
                        :endPoint="item.endPoint"
                        :displayValue="item.displayValue"
                        :searchable="item.searchable"
                        :validationMethod="item.validationMethod"
                        :onchangeMethod="item.onchangeMethod"
                        :helperText="item.helperText ? $t('message.' + item.helperText) : null"
                      >
                      </ImFormInput>
                      <!-- eslint-enable -->
                    </div>

                    <div
                      v-for="item in state.propertyAddress"
                      v-bind:key="item.model"
                      class="mb-8"
                    >
                      <!-- eslint-disable -->
            
                      <ImFormInput
                      v-model:inputValue="item.value"
                        v-model:parent="item.parent"
                        v-model:validated="item.validated"
                        :title="$t('message.' + item.title)"
                        :type="item.type"
                        :valueLabel="item.valueLabel"
                        :optionValue="item.optionValue"
                        :endPoint="item.endPoint"
                        :displayValue="item.displayValue"
                        :searchable="item.searchable"
                        :validationMethod="item.validationMethod"
                        :onchangeMethod="item.onchangeMethod"
                        :helperText="item.helperText ? $t('message.' + item.helperText) : null"
                        :mandatory="item.mandatory"
                      >
                      </ImFormInput>
                      <!-- eslint-enable -->
                    </div>

                    <button
                      @click="saveProperty"
                      class="btn btn-md btn-secondary normal-case text-sm im-round-btn"
                    >
                      {{ $t("message.save") }}
                    </button>
                  </div>
                  <input type="checkbox" class="modal-toggle" v-model="state.showConfirmation" />
                    <div class="modal">
                      <div class="modal-box">
                        <h3 class="text-lg text-left font-normal hover:text-gray-100 text-gray-100">
                          {{ $t("message.saveConfirmation") }}
                        </h3>
                        <div class="modal-action">
                          <label class="btn btn-sm text-white font-light text-xs" @click="() => {
                              state.showConfirmation = false;
                              state.tab = 1;
                            }
                            ">{{ $t("message.no") }}</label>
                          <label class="btn btn-sm bg-magenta text-white font-light text-xs" @click="() => {
                              saveProperty();
                              state.showConfirmation = false;
                            }
                            ">{{ $t("message.yes") }}</label>
                        </div>
                      </div>
                    </div>
                </div>
              </div>

              <!--EDIT PROPERTY-->
              <div
                class="flex flex-col im-main-card rounded-xl im-main-card-starter"
                v-if="state.tab == 3"
              >
                <div>
                  <button
                    class="btn btn-secondary btn-sm btn-circle mb-8"
                    @click="setConfirmation()"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                      />
                    </svg>
                  </button>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    {{ state.propertyBasicData[1].value }}
                  </h2>
                  <div class="im-card-form">
                    <div
                      v-for="item in state.propertyBasicData"
                      v-bind:key="item.model"
                      class="mb-8"
                    >
                      <!-- eslint-disable -->
            
                      <ImFormInput
                      v-model:inputValue="item.value"
                        v-model:parent="item.parent"
                        :title="$t('message.' + item.title)"
                        :type="item.type"
                        :valueLabel="item.valueLabel"
                        :optionValue="item.optionValue"
                        :endPoint="item.endPoint"
                        :displayValue="item.displayValue"
                        :searchable="item.searchable"
                        :validationMethod="item.validationMethod"
                        :onchangeMethod="item.onchangeMethod"
                        :helperText="item.helperText ? $t('message.' + item.helperText) : null"
                      >
                      </ImFormInput>
                      <!-- eslint-enable -->
                    </div>

                    <button
                      @click="saveProperty"
                      class="btn btn-md btn-secondary normal-case text-sm im-round-btn"
                    >
                      {{ $t("message.save") }}
                    </button>
                  </div>
                  <input type="checkbox" class="modal-toggle" v-model="state.showConfirmation" />
                    <div class="modal">
                      <div class="modal-box">
                        <h3 class="text-lg text-left font-normal hover:text-gray-100 text-gray-100">
                          {{ $t("message.saveConfirmation") }}
                        </h3>
                        <div class="modal-action">
                          <label class="btn btn-sm text-white font-light text-xs" @click="() => {
                              state.showConfirmation = false;
                              state.tab = 1;
                            }
                            ">{{ $t("message.no") }}</label>
                          <label class="btn btn-sm bg-magenta text-white font-light text-xs" @click="() => {
                              saveProperty();
                              state.showConfirmation = false;
                              state.tab = 1;
                            }
                            ">{{ $t("message.yes") }}</label>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
          </TransitionGroup>
        </div>
      </template>
    </BaseLayout>
  </div>
</template>

<script setup>
import BaseLayout from "../layouts/BaseLayout.vue";
import { ref, onMounted, watch } from "vue";
import { computedAsync } from '@vueuse/core'
import { useI18n } from "vue-i18n";
import formdata from "./PropertiesPageComponents/FormData";
import propertiesGridConfig from "@/misc/gridConfigs/propertiesGridConfig.js"
import propertiesUtils from "./PropertiesPageComponents/propertiesUtils"
import RobberVueGrid from "../components/RVG/RobberVueGrid.vue";
import ImFormInput from "../components/ImFormInput.vue";
import toast from "@/misc/toast.js"
import RVGUtils from "../components/RVG/RobberVueGridUtils.js";

const propertiesGrid = ref();

const { t } = useI18n();

const state = ref({
  tab: 1,
  propertyBasicData: formdata.propertyBasicData,
  propertyAddress: formdata.propertyAddress,
  propertyId: 0,
  showConfirmation: false,
  addressMainValidation: false, 
})


state.value.propertyAddress.forEach((e, i) => {
watch(() => state.value.propertyAddress[i].validated,
    () => {
        let lever = true;
        state.value.propertyAddress.forEach((d) => {
            if (d.validated == false) {
                lever = false
            }
        })
        state.value.addressMainValidation = lever
    });
})

//PROPERTIES
const propertiesGridConfigComputed = computedAsync(async () => {

  const [
      basicDataTypeMapping,
      basicDataClassificationMapping,
      basicDataStatusMapping,
    ] = await Promise.all([
    RVGUtils.getFormatterMappingForOptionValue("PROPERTY_TYPE"),
    RVGUtils.getFormatterMappingForOptionValue("PROPERTY_CLASSIFICATION"),
    RVGUtils.getFormatterMappingForOptionValue("PROPERTY_STATUS"),
  ])
  
  return propertiesGridConfig(state, t, propertiesUtils.toProperty, basicDataTypeMapping, basicDataClassificationMapping, basicDataStatusMapping);
}, propertiesGridConfig(state, t, propertiesUtils.toProperty));

const saveProperty = () => {
  let lever = true;
    state.value.propertyAddress.forEach((e) => {
        if (e.validated == false) {
            lever = false
        }       
    })

    if (lever == true) {
      propertiesUtils.saveProperty(state, t);
      propertiesGrid.value.refresh();
    } else {
      toast.error(t("message.missingFormFields"));
    }
};

function clearData(vArray) {
  vArray.forEach((element) => {
    element.value = "";
  });
}

const setConfirmation = () => {
  state.value.showConfirmation = true;
}

onMounted(() => {
  propertiesUtils.setup(state);
  
});

//WATCHERS

watch(() => state.value.propertyAddress[2].value,
  () => {
    setTimeout(() => {
      setTimeout(() => {
          state.value.propertyAddress[3].parent = state.value.propertyAddress[2].value;
          state.value.propertyAddress[4].parent = state.value.propertyAddress[2].value;
          state.value.propertyAddress[5].parent = state.value.propertyAddress[2].value;
        }, 700);
    }, 700);
  });

</script>

<style scoped>
.cardBg {
  background-color: hsl(220, 17.647%, 20%);
}

.im-main-card {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
  padding: 3rem;
  width: 100%;
  max-width: 100%;
  max-height: 75vh;
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  .im-main-card {
    margin-bottom: 30px;
  }
}

.im-main-card-starter {
  animation: 0.5s ease-out 0s 1 scaleupY;
  overflow: auto;
}

.im-main-card-starter div {
  animation: 1.5s ease-out 0s 1 fadein;
}

@keyframes scaleupY {
  0% {
    transform-origin: left top;
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.im-sidemenu-contanier {
  animation: 0.5s ease-out 0s 1 scaleupY;
  padding: 1rem;

  margin-right: 2rem;
  height: fit-content;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
}

.im-sidemenu-contanier div {
  animation: 1.5s ease-out 0s 1 fadein;
}

.im-card-form {
  padding-top: 2rem;
}

.fade-enter-active {
  transition: opacity 1s ease 1s, padding 1s ease 1s, width 1s ease 1s;
}

.fade-leave-active {
  transition: opacity 0.5s ease, padding 0.5s ease, width 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  padding: 0;
  width: 0;
  opacity: 0;
}

.im-round-btn {
  border-radius: 9999px;
}

/* button */

.btn-circle {
  transition: width 0.5s;
}

.im-sidemenu-contanier:hover .btn-circle {
  width: 15rem;
}

@keyframes fadeinbtn {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.im-btn-text {
  display: none;
}

.im-sidemenu-contanier:hover .im-btn-text {
  animation: 0.5s ease-out 0s 1 fadeinbtn;
  display: inline;
}

.im-btn-icon {
  display: inline;
}
.im-button-active {
  border-color: lighter hsl(var(--s));
}

.im-sidemenu-contanier:hover .im-btn-icon {
  display: none;
}
</style>

import store from '@/misc/vuex-store.js'

const i18n = {
  en: {
    'rvg-automatic-list-update':'Automatic list update',
    'rvg-list-options-title':'List options',
    'rvg-select-columns': 'Select columns',
    'select-displayable-columns-modal-title': 'Select displayable columns',
    'rvg-automatic-list-update-disabled': 'Automatic list update disabled',
    'rvg-rows-per-page':'Rows per page',
    'rvg-page-size':'Page size',
    'rvg-export-records-link-text':'Export table to excel file',
    'xlsx-export-modal-title':'Export table to Excel',
    'xlsx-export-modal-submit-button':'Export table',
    'cancel':'Cancel',
    'apply':'Apply'
  },
  hu: {
    'rvg-automatic-list-update':'Automatikus lista frissítés',
    'rvg-list-options-title':'Lista opciók',
    'rvg-select-columns': 'Oszlopok kiválasztása',
    'select-displayable-columns-modal-title': 'Megjelenítendő oszlopok kiválasztása',
    'rvg-automatic-list-update-disabled': 'Automatkus lista frissítés kikapcsolása',
    'rvg-rows-per-page': 'Sorok száma',
    'rvg-page-size':'Oldal mérete',
    'rvg-export-records-link-text':'Tábla exportálása excel fájlba',
    'xlsx-export-modal-title':'Tábla exportálása Excel fájlba',
    'xlsx-export-modal-submit-button':'Tábla exportálása',
    'cancel':'Mégse',
    'apply':'Mentés'
  }
}

const STORE_MISSING_LANG_KEYS = true;

function storeMissingLangKeys(key, lang) {
  let cVal = localStorage.getItem('missing-lang-keys-' + lang);
  if(!cVal) {
    cVal = "";
  }

  let cArray = cVal.split(",");
  if(!cArray.includes(key)) {
    cArray.push(key)
  }
  localStorage.setItem('missing-lang-keys-' + lang, cArray.join(","));
}

window.getMissingLangKeys = (lang) => {
  const langKeys = localStorage.getItem('missing-lang-keys-' + lang).split(",");
  let tempStr = "";
  for(var i in langKeys) {
    if(!langKeys[i]) continue;
    tempStr += "'" + langKeys[i] + "': '',\n"
  }
  console.log(tempStr);
};

export default (key, params = null) => {
  // let currentLang = localStorage.getItem('lang') || 'hu';
  let currentLang = store.getters.getLanguage

  if(!i18n[currentLang]) {
    localStorage.setItem('lang', 'hu');
    currentLang = 'hu';
  }

  if(STORE_MISSING_LANG_KEYS && !i18n[currentLang][key]) {
    storeMissingLangKeys(key, currentLang)
  }

  if(params) {
    let tempStr = i18n[currentLang][key];
    if(!tempStr) return key;
    
    for(let i in params) {
      tempStr = tempStr.replace("{" + i + "}", params[i]);
    }

    return tempStr;
  }
  else {
    return i18n[currentLang][key] || key;
  }
}
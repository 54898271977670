import TenantConfigHandler from "@/misc/TenantConfigHandler";

export default {
  premiseData: [
    {
      title: "function",
      value: "",
      valueLabel: "",
      model: "premiseFunction",
      type: "option",
      optionValue: "PREMISE_FUNCTION",
    },
    {
      title: "cremClassification",
      value: "",
      valueLabel: "",
      model: "cremClass",
      type: "option",
      optionValue: "CREM_CLASSIFICATION",
    },
    {
      title: "identifier",
      value: "",
      model: "identifier",
      type: "text",
      helperText: "codeFHelperText"
    },
    {
      title: "buildingPagePremiseNumber",
      value: "",
      model: "premiseNumber",
      type: "text",
    },
    {
      title: "floorArea",
      value: "",
      model: "floorSpace",
      type: "number",
    },
    {
      title: "isAccessible",
      value: "",
      model: "accessibility",
      type: "checkbox",
    },
  ],
  staircaseData: [
    {
      title: "stairwayId",
      value: "",
      model: "basicDataStairwayIdentifier",
      type: "text",
    },
    {
      title: "stairwayType",
      value: "",
      model: "basicDataStairwayType",
      type: "option",
      optionValue: "STAIRWAY_TYPE",
    },
    {
      title: "isEscapeRoute",
      value: "",
      model: "basicDataFireRoute",
      type: "checkbox",
    },
    {
      title: "isSmokeFree",
      value: "",
      model: "basicDataSmokeFree",
      type: "checkbox",
    },
  ],
  elevatorData: [
    {
      title: "numberOfPerson",
      value: "",
      model: "basicDataElevatorNumberPersons",
      type: "text",
    },
    {
      title: "elevatorType",
      value: "",
      valueLabel: "",
      model: "basicDataElevatorType",
      type: "option",
      optionValue: "ELEVATOR_TYPE",
    },
    {
      title: "loadCapacity",
      value: "",
      model: "basicDataElevatorLoadCapacity",
      type: "number",
    },
    {
      title: "isEmergencyElevator",
      value: "",
      model: "basicDataEmergencyElevator",
      type: "checkbox",
    },
  ],
  legalsitData: [
    {
      title: "legalSituation",
      value: "",
      valueLabel: "",
      model: "legalSituationType",
      type: "option",
      optionValue: "LEGAL_SITUATION",
    },
    {
      title: "legalRelationship",
      value: "",
      valueLabel: "",
      model: "legalRelationshipType",
      type: "option",
      optionValue: "LEGAL_RELATIONSHIP_TYPE",
    },
    {
      title: "ownership",
      value: "",
      model: "ownership",
      type: "text",
    },
    {
      title: "legalRelationshipStart",
      value: "",
      model: "legalRelationshipStart",
      type: "date",
    },
    {
      title: "legalRelationshipEnd",
      value: "",
      model: "legalRelationshipEnd",
      type: "date",
    },
    {
      title: "areaSize",
      value: "",
      model: "squareMeter",
      type: "number",
    },
  ],
  utilizationData: [
    {
      title: "floorSpace",
      value: "",
      model: "floorSpace",
      type: "number",
      validated: true,
    },
    {
      title: "legalRelationship",
      value: "",
      model: "legalRelationship",
      type: "option",
      optionValue: "UTILIZATION_LEGAL_RELATIONSHIP",
      validated: true,
    },
    {
      title: "legalRelationshipStart",
      value: "",
      model: "legalRelationshipStart",
      type: "date",
      validated: true,
    },
    {
      title: "legalRelationshipEnd",
      value: "",
      model: "legalRelationshipEnd",
      type: "date",
      validated: true,
    },
    {
      title: "partner",
      value: "",
      model: "partnerId",
      type: "optionapi",
      mandatory: true,
      validated: true,
      endPoint: TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/partner",
      displayValue: "name",
    },
  ],
  meterData: [
    // {
    //   title: "gist",
    //   value: "",
    //   model: "gistId",
    //   optionSearchEndpoint: "gist",
    //   type: "optionSearchEndpoint",
    // },
    {
      title: "meterType",
      value: "",
      valueLabel: "",
      model: "type",
      type: "option",
      optionValue: "METER_TYPE",
    },
    {
      title: "contractingParty",
      value: "",
      model: "partnerId",
      type: "optionapi",
      endPoint: TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/partner",
      displayValue: "name",
    },
    {
      title: "publicUtilityType",
      value: "",
      model: "utilityType",
      type: "option",
      optionValue: "METER_UTILITY_TYPE",
    },
    {
      title: "identifier",
      value: "",
      model: "identifier",
      type: "text",
    },
    {
      title: "placement",
      value: "",
      model: "placement",
      type: "text",
    },
    {
      title: "readings",
      value: "",
      model: "readings",
      type: "text",
    },
    {
      title: "readingFrequency",
      value: "",
      model: "readingFrequency",
      type: "option",
      optionValue: "METER_READING_FREQUENCY",
    },
    {
      title: "commType",
      value: "",
      type: "option",
      model: "commType",
      optionValue: "METER_COMM_TYPE",
    },
    {
      title: "expirationDate",
      value: "",
      model: "expirationDate",
      type: "date",
    },
  ],
  buildingBasicData:[
    {
      title: "baseDatas",
      type: "label"
    },
    {
      title: "floorArea",
      value: "",
      model: "floorArea",
      type: "number",
    },
    {
      title: "mtArea",
      value: "",
      model: "mtArea",
      type: "number",
    },
    {
      title: "numberOfPremises",
      value: "",
      model: "premiseNumber",
      type: "number",
    },
    {
      title: "mainFunction",
      value: "",
      valueLabel: "",
      model: "mainFunction",
      type: "option",
      optionValue: "BUILDING_MAIN_FUNCTION",
    },
    {
      title: "isAccessible",
      value: "",
      model: "isAccessible",
      type: "checkbox",
    },
    {
      title: "numberDedicated",
      value: "",
      valueLabel: "",
      model: "numberDedicated",
      type: "number",
      helperText: "numberDedicatedHelperText"
    },
    {
      title: "numberCanBeSeated",
      value: "",
      valueLabel: "",
      model: "numberCanBeSeated",
      type: "number",
      helperText: "numberCanBeSeatedHelperText"
    },
    {
      title: "numberSeated",
      value: "",
      valueLabel: "",
      model: "numberSeated",
      type: "number",
      helperText: "numberSeatedHelperText"
    },
    {
      title: "buildingMaxHeight",
      value: "",
      model: "maxHeight",
      type: "number",
    },
    {
      title: "status",
      value: "",
      valueLabel: "",
      model: "status",
      type: "option",
      optionValue: "BUILDING_STATUS",
    },
    {
      title: "floorsBelowSurface",
      value: "",
      model: "floorsBelowSurface",
      type: "text",
    },
    {
      title: "floorsAboveSurface",
      value: "",
      model: "floorsAboveSurface",
      type: "text",
    },
    {
      title: "attic",
      value: "",
      model: "attic",
      type: "checkbox",
    },
    {
      title: "atticCanBeUsed",
      value: "",
      model: "atticCanBeUsed",
      type: "checkbox",
    },
    {
      title: "sapBuildingId",
      value: "",
      valueLabel: "",
      model: "sap",
      type: "option",
      optionValue: "SAP_BUILDING_IDENTIFIER",
    },
    {
      title: "tcafm",
      value: "",
      model: "tcafm",
      type: "text",
    },
    {
      title: "parcelNumber",
      value: "",
      model: "parcelNumber",
      type: "text",
    },
    {
      title: "mtResponsibles",
      type: "label"
    },
    {
      title: "operation",
      value: "",
      model: "operation",
      type: "text",
    },
    {
      title: "cleaning",
      value: "",
      model: "cleaner",
      type: "text",
    },
    {
      title: "supplies",
      value: "",
      model: "supplies",
      type: "text",
    },
    {
      title: "tui",
      value: "",
      model: "tui",
      type: "text",
    },
    {
      title: "ownerOfAssets",
      value: "",
      model: "ownerOfAssets",
      type: "text",
    },
    {
      title: "assetManager",
      value: "",
      model: "assetManager",
      type: "text",
    },
  ],
  buildingData: [
    {
      title: "additionalInfo",
      type: "label"
    },
    {
      title: "siteDesignPlaceholder",
      value: "",
      optionSearchEndpoint: "siteDesign",
      model: "siteDesignId",
      type: "optionSearchEndpoint",
    },
    {
      title: "paymentObligations",
      value: "",
      model: "paymentObligation",
      type: "text",
    },
    {
      title: "numberOfStairways",
      value: "",
      model: "numberOfStairways",
      type: "number",
    },
    {
      title: "numberOfElevators",
      value: "",
      model: "numberOfElevators",
      type: "number",
    },
    {
      title: "structureTypes",
      type: "label"
    },
    {
      title: "foundation",
      value: "",
      valueLabel: "",
      model: "foundation",
      type: "option",
      optionValue: "FOUNDATION",
    },
    {
      title: "bearingMasonry",
      value: "",
      valueLabel: "",
      model: "bearingMasonry",
      type: "option",
      optionValue: "BEARING_MASONRY",
    },
    {
      title: "slabStructInter",
      value: "",
      valueLabel: "",
      model: "slabStructureInter",
      type: "option",
      optionValue: "SLAB_STRUCTURE_INTERMEDIATE",
    },
    {
      title: "slabSubStructInter",
      value: "",
      valueLabel: "",
      model: "slabSubStructureInter",
      type: "option",
      optionValue: "SLAB_SUB_STRUCTURE_INTERMEDIATE",
      onchangeMethod: (
        options,
        optionsRepo,
        value,
        
        inputValue
      ) => {
        options.value = optionsRepo.filter((elem) => elem.parent == value);
        if (options.value.filter((elem) => elem.value == inputValue.value)) {
          inputValue.value = "";
        } 
      },
    },
    {
      title: "slabStructClosing",
      value: "",
      valueLabel: "",
      model: "slabStructureClosing",
      type: "option",
      optionValue: "SLAB_STRUCTURE_CLOSING",
    },
    {
      title: "slabSubStructClosing",
      value: "",
      valueLabel: "",
      model: "slabSubStructureClosing",
      type: "option",
      optionValue: "SLAB_SUB_STRUCTURE_CLOSING",
      onchangeMethod: (
        options,
        optionsRepo,
        value,

        inputValue
      ) => {
        options.value = optionsRepo.filter((elem) => elem.parent == value);
        if (options.value.filter((elem) => elem.value == inputValue.value)) {
          inputValue.value = "";
        }
      },
    },
    {
      title: "flatRoof",
      value: "",
      valueLabel: "",
      model: "flatRoof",
      type: "option",
      optionValue: "FLAT_ROOF",
    },
    {
      title: "roofType",
      value: "",
      valueLabel: "",
      model: "roofType",
      type: "option",
      optionValue: "ROOF_TYPE",
    },
    {
      title: "roofing",
      value: "",
      valueLabel: "",
      model: "roofing",
      type: "option",
      optionValue: "ROOFING",
    },
    {
      title: "insulation",
      value: "",
      valueLabel: "",
      model: "insulation",
      type: "option",
      optionValue: "INSULATION",
    },
  ],
  partnerData: [
    {
      title: "name",
      value: "",
      model: "name",
      type: "text",
    },
    {
      title: "mainType",
      value: "",
      model: "partnerMainType",
      type: "option",
      optionValue: "PARTNER_MAIN_TYPE",
    },
    {
      title: "subType",
      value: "",
      model: "partnerSubType",
      type: "option",
      optionValue: "PARTNER_SUB_TYPE",
      onchangeMethod: (options, optionsRepo, value, inputValue,) => {
        options.value = optionsRepo.filter((elem) => elem.parent == value);
        if (options.value.filter((elem) => elem.value == inputValue.value)) {
          inputValue.value = "";
        } 
      },
    },
    {
      title: "privateIndividual",
      value: "",
      model: "",
      type: "checkbox",
    },
    {
      title: "taxId",
      value: "",
      model: "taxId",
      type: "text",
    },
    {
      title: "taxNumber",
      value: "",
      model: "taxNumber",
      type: "text",
      validationMethod: (options, optionsRepo, value, newValue, oldValue, inputValue, inputError) => {
        
        if(newValue.length == 8 && oldValue.length < 8){
          inputValue.value = inputValue.value + "-"
        }
        else if(newValue.length == 10 && oldValue.length < 10){
          inputValue.value = inputValue.value + "-"
        }
        else if(newValue.length > 13){
          inputError.value = "tulhosszuAdoszam"
        }
      }
    },
  ],
};

import Axios from '@/misc/axios.js';
import TenantConfigHandler from '@/misc/TenantConfigHandler';

export default {
  getScenarioTypes(targetGist, onReady, onError) {
    let uri = TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/scenario-types';
    if(targetGist) {
      uri = TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/scenario-types/for-responder/' + targetGist;
    }

    Axios.get(uri, {
      onTokenRefreshed: () => {
        this.getScenarioTypes(targetGist, onReady, onError)
      }
    }).then(response => {
      if(onReady) {
        onReady(response)
      }
    }).catch(error => {
      if(onError) {
        onError(error)
      }
    })
  },
  createScenario(type, inputs, output, conditions, onReady, onError) {
    Axios.post(TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/scenario', {
      type: type,
      inputs: inputs.map(input => { input.id }),
      outputGistId: output?.id.toString(),
      conditions: conditions
    }, {
      onTokenRefreshed: () => {
        this.createScenario(type, inputs, output, conditions, onReady, onError)
      }
    }).then(response => {
      if(onReady) {
        onReady(response)
      }
    }).catch(error => {
      if(onError) {
        onError(error)
      }
    })
  },
  updateScenario(scenarioId, type, inputs, output, conditions, onReady, onError) {
    Axios.put(TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/scenario/' + scenarioId, {
      type: type,
      inputs: inputs.map(input => { input.id }),
      outputGistId: output?.id.toString(),
      conditions: conditions
    }, {
      onTokenRefreshed: () => {
        this.updateScenario(type, inputs, output, conditions, onReady, onError)
      }
    }).then(response => {
      if(onReady) {
        onReady(response)
      }
    }).catch(error => {
      if(onError) {
        onError(error)
      }
    })
  }
}
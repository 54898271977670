<template>
  <div class="p-0">
    <div class="p-5 pb-2 mr-2 flex flex-col grow border-b-0 border-base-300">
      <div class="flex flex-row items_center justify-between">
        <h2 class="text-base font-sans font-semibold uppercase mb-1 text-secondary flex flex-row items-center gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z" />
          </svg>
          <span class="text-white">
            {{ $t("message.editZone") }}
          </span>
        </h2>
        <a href="javascript:void(null)" class="hover:text-secondary" @click="hideSidebar">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </a>
      </div>
    </div>

    <div class="px-5">
      <div class="form-control w-full ">
        <label class="label">
          <span class="label-text">{{ $t("message.designation") }}</span> 
          <span class="label-text-alt">{{ $t("message.zoneName") }}</span>
        </label>
        <input type="text" v-model="displayName" :placeholder="$t('message.zoneNamePlaceholder')" class="input input-sm input-bordered w-full " />
      </div>

      <div class="form-control w-full ">
        <label class="label">
          <span class="label-text">{{ $t("message.externalIdentifier") }}</span>
          <span class="label-text-alt"></span>
        </label>
        <input type="text" v-model="externalIdentifier" placeholder="pl: ABCD1111-1" class="input input-sm input-bordered w-full " />
      </div>

      <div class="flex flex-row w-full gap-3">
        <div class="form-control w-1/2 ">
          <label class="label">
            <span class="label-text">{{ $t("message.numberOfPossibleWorkplaces") }}</span>
            <span class="label-text-alt"></span>
          </label>
          <input type="number" v-model="numberOfPossibleWorkplaces" :placeholder="$t('message.egNumPlaceholder')" class="input input-sm input-bordered w-full " />
        </div>

        <div class="form-control w-1/2 ">
          <label class="label">
            <span class="label-text">{{ $t("message.numberOfWorkplaces") }}</span>
            <span class="label-text-alt"></span>
          </label>
          <input type="number" v-model="numberOfWorkplaces" :placeholder="$t('message.egNumPlaceholder')" class="input input-sm input-bordered w-full " />
        </div>
      </div>

      <div class="form-control w-full ">
        <label class="label">
          <span class="label-text">{{ $t("message.floorSpace") }}</span>
          <span class="label-text-alt">{{ $t("message.sqm") }}</span>
        </label>
        <input type="number" v-model="sqm" :placeholder="$t('message.egNumPlaceholder')" class="input input-sm input-bordered w-full " />
      </div>

      <div class="form-control w-full ">
        <label class="label">
          <span class="label-text">{{ $t("message.zoneType") }}</span>
          <span class="label-text-alt"></span>
        </label>
        <select v-model="zoneType" class="select select-sm select-bordered">
          <option disabled selected>{{ $t("message.choose") }}</option>
          <option v-for="(zoneType, key) in zoneTypes" :key="key" :value="zoneType">{{ zoneType }}</option>
        </select>
      </div>

      <div class="form-control w-full">
        <label class="label">
          <span class="label-text">{{ $t("message.zoneGroups") }}</span>
          <span class="label-text-alt"></span>
        </label>
        <VueTagsInput
          v-model="searchText"
          :select="true"
          :autocomplete-items="selectableZoneGroups"
          :tags="selectedZoneGroups"
          :autocomplete-filter-duplicates="true"
          @before-adding-tag="createZoneGroup"
          @before-deleting-tag="removeZoneGroup"
          @on-select="onSelectZoneGroup"
          @on-focus="fetchZoneGroups"
          placeholder=""
          class="rounded-lg !border-none !shadow-none !outline-none !bg-[#2a303c]">
        </VueTagsInput>
      </div>

      <div class="form-control  pt-4">
        <label class="label cursor-pointer">
          <span class="label-text">{{ $t("message.isActive") }}</span>
          <input type="checkbox" v-model="active" class="toggle toggle-secondary" checked />
        </label>
      </div>

      <div class="form-control w-full  pt-5">
        <button type="button" class="btn btn-secondary" @click="update">
          Mentés
        </button>
      </div>
    </div>

  </div>
</template>

<script>
  import Utils from '../../misc/Utils';
  import { VueTagsInput } from '@vojtechlanka/vue-tags-input';
  import Axios from '@/misc/axios.js';
  import TenantConfigHandler from '@/misc/TenantConfigHandler';

  export default {
    name: 'ZoneUpdaterSidebarContent',
    props: {
      entity: {
        type: Object,
        required: true
      },
      params: {
        type: Object,
        required: false
      }
    },
    data() {
      return {
        id: this.entity.id,
        displayName: this.entity.displayName,
        externalIdentifier: this.entity.externalIdentifier,
        numberOfPossibleWorkplaces: this.entity.numberOfPossibleWorkplaces,
        numberOfWorkplaces: this.entity.numberOfWorkplaces,
        siteDesignId: this.entity.siteDesignId,
        sqm: this.entity.sqm,
        active: this.entity.active !== false,
        points: this.entity.points,
        zoneType: this.entity.zoneType,

        searchText: '',
        selectableZoneGroups: [],
        selectedZoneGroups: this.entity.zoneGroups,
        loadingZones: false,
        timeoutRef: null
      }
    },
    computed: {
      zoneTypes() {
        return this.$store.getters.getZoneTypes
      }
    },
    mounted() {
    },
    components: {
      VueTagsInput
    },
    watch: {
      async searchText() {
        if(this.timeoutRef) {
          window.clearTimeout(this.timeoutRef);
        }

        this.selectableZoneGroups = [];
        await this.fetchZoneGroups();
      }
    },
    methods: {
      saveZoneGroup(displayName, onReady) {
        Axios.post(TenantConfigHandler.getVariable('ADMINV2_API_URL') + '/zone-group/create', {
          active: true,
          displayName: displayName,
          points: null,
          siteDesignId: this.siteDesignId,
          zoneIds: []
        }, {
          onTokenRefreshed: () => {
            this.saveZoneGroup(displayName, onReady)
          }
        }).then((response) => {
          if(onReady) {
            onReady(response.data.id)
          }
        })
        .catch((err) => {
          console.log(err)
        })
      },
      createZoneGroup(zoneGroup) {
        let tag = zoneGroup.tag;
        if(!tag) {return}

        if(tag.id) {
          zoneGroup.addTag()
          console.log(zoneGroup.tag)
          this.selectedZoneGroups.push(zoneGroup.tag)
        }
        else {
          this.saveZoneGroup(tag.text, (tagId) => {
            tag.id = tagId;
            zoneGroup.addTag()
            this.selectedZoneGroups.push(zoneGroup.tag)
          })
        }
        
      },
      removeZoneGroup(zoneGroup) {
        this.selectedZoneGroups.splice(zoneGroup.index, 1)
        zoneGroup.deleteTag()
      },
      onSelectZoneGroup(zoneGroup) {
        this.selectedZoneGroups.push(zoneGroup)
        this.searchText = '';
        this.selectableZoneGroups = [];
      },
      fetchZoneGroups() {
        if(!this.searchText) { return }

        Axios.get(TenantConfigHandler.getVariable('ADMINV2_API_URL') + '/zone-group/search?displayName=' + this.searchText, {
          onTokenRefreshed: () => {
            this.fetchZoneGroups()
          }
        }).then((res) => {
          this.selectableZoneGroups = res.data.map((zoneGroup) => {
            return {
              id: zoneGroup.id,
              text: zoneGroup.displayName
            }
          })
        })
        .catch((err) => {
          console.log(err)
        })
      },
      onRemoveZoneGroup(zoneGroup) {
        var ind = -1;
        for(var i in this.selectedZoneGroups) {
          if(this.selectedZoneGroups[i].id === zoneGroup.id) {
            ind = i;
            break;
          }
        }
        
        if(ind !== -1) {
          this.selectableZoneGroups.splice(ind, 1);
        }
      },
      update() {
        var that = this;
        const postValue = {
          active: this.active,
          displayName: this.displayName,
          externalIdentifier: this.externalIdentifier,
          id: this.id,
          numberOfPossibleWorkplaces: this.numberOfPossibleWorkplaces,
          numberOfWorkplaces: this.numberOfWorkplaces,
          points: this.points,
          siteDesignId: this.siteDesignId,
          sqm: this.sqm,
          zoneGroupIds: this.selectedZoneGroups.map((zoneGroup) => {
            return zoneGroup.id
          }),
          zoneType: this.zoneType
        };

        Axios.post(TenantConfigHandler.getVariable('ADMINV2_API_URL') + '/zone/create', postValue, {
          onTokenRefreshed: () => {
            this.update()
          }
        }).then(() => {
          Utils.showToast('success', this.$t("message.successfulSave"));
          that.hideSidebar();
          if(this.params && this.params.onSave) {
            this.params.onSave(postValue)
          }
        })
        .catch((err) => {
          console.log(err)
          Utils.showToast('error', this.$t("message.notSuccessfulSave"));
        })
      },
      hideSidebar() {
        this.$store.commit('hideSidebar');
      }
    },
  }
</script>

<style scoped>
  .vue-tags-input >>> .ti-input {
    border-radius: 8px;
    border: 1px solid #434955;
  }

  .vue-tags-input >>> .ti-input input {
    background-color: transparent !important;
  }

  .vue-tags-input >>> .ti-tag {
    display: flex;
    flex-direction: row;
    background: #e53010;
  }

  .vue-tags-input >>> .ti-tag .ti-content, .vue-tags-input >>> .ti-tag .ti-actions {
    padding: 2px;
  }
</style>
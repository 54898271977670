export default {
    data: [
        {
            title: "name",
            value: "",
            model: "name",
            type: "text",
          },
          {
            title: "mainType",
            value: "",
            model: "partnerMainType",
            type: "option",
            optionValue: "PARTNER_MAIN_TYPE",
          },
          {
            title: "subType",
            value: "",
            model: "partnerSubType",
            type: "option",
            optionValue: "PARTNER_SUB_TYPE",
            onchangeMethod: (options, optionsRepo, value, inputValue,) => {
              options.value = optionsRepo.filter((elem) => elem.parent == value);
              if (options.value.filter((elem) => elem.value == inputValue.value)) {
                inputValue.value = "";
              } 
            },
          },
          {
            title: "privateIndividual",
            value: "",
            model: "",
            type: "checkbox",
          },
          {
            title: "taxId",
            value: "",
            model: "taxId",
            type: "text",
          },
          {
            title: "taxNumber",
            value: "",
            model: "taxNumber",
            type: "text",
            validationMethod: (options, optionsRepo, value, newValue, oldValue, inputValue, inputError) => {
              
              if(newValue.length == 8 && oldValue.length < 8){
                inputValue.value = inputValue.value + "-"
              }
              else if(newValue.length == 10 && oldValue.length < 10){
                inputValue.value = inputValue.value + "-"
              }
              else if(newValue.length > 13){
                inputError.value = "tulhosszuAdoszam"
              }
            }
          },
    ]
}
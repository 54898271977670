<template>
  <div id="SiteDesignSelectorPage" class="h-screen">
    <BaseLayout>
      <template #mainContent>
        <l-map 
          ref="map" 
          class="lMap !bg-white rounded-box z-10"
          :options="{ center: [getEnv('MAIN_MAP_CENTER_LAT'), getEnv('MAIN_MAP_CENTER_LNG')], zoomControl: false, attributionControl: false, }" :zoom="getEnv('MAIN_MAP_CENTER_ZOOM_LEVEL')"
        >
          <l-tile-layer :url="'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'"></l-tile-layer>
          <LControlZoom position="bottomright"></LControlZoom>
          <LControlAttribution position="bottomright" prefix=""></LControlAttribution>
          <LMarker v-for="(marker, key) in siteDesignMarkers" :key="key" :lat-lng="marker.latLng" @click="() => { onMarkerClick(marker.id); }" class="hover:!z-[9999]" @mouseover="() => { fetchData(marker.id) }">
            <l-icon
              :icon-anchor="[10, 20]"
              class-name="hover:!z-[9999] absolute"
            >
              <!-- BUG: https://github.com/vue-leaflet/Vue2Leaflet/issues/480 -->
              <WeatherTooltip :_sdid="marker.id" ref="wTooltip" :marker="marker" />
            </l-icon>
          </LMarker>
        </l-map>

        <SDSelectorModal :currentSiteDesign="currentSiteDesign" :changeSiteDesignFromModal="(siteDesign) => { currentSiteDesign = siteDesign; }" />
      </template>
    </BaseLayout>
  </div>
</template>

<script>

import BaseLayout from '../layouts/BaseLayout.vue'
import { LControlZoom, LControlAttribution, LMarker, LIcon, LTileLayer, LMap } from '@vue-leaflet/vue-leaflet';
import Utils from '../misc/Utils';
import WeatherTooltip from './SiteDesignSelectorPageComponents/WeatherTooltip.vue';
import { Icon } from 'leaflet';
import WeatherUtils from '../misc/WeatherUtils';
import SDSelectorModal from '../components/SDSelectorModal.vue';
import TenantConfigHandler from '../misc/TenantConfigHandler';
import moment from 'moment';
import store from "@/misc/vuex-store.js";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('../../public/assets/icons/marker.svg'),
  iconUrl: require('../../public/assets/icons/marker.svg'),
  shadowUrl: null,
});

export default {
  name: 'SiteDesignSelectorPage',
  data() {
    return {
      loading: false,
      currentSiteDesign: null,
      weatherData: null
    }
  },
  computed: {
    siteDesignMarkers() {
      if(!this.siteDesigns) {
        return [];
      }

      var result = [];

      this.siteDesigns.forEach((siteDesign) => {
        if(siteDesign.latitude && siteDesign.longitude && siteDesign.type == 'building') {
          result.push({
            id: siteDesign.id,
            latLng: [siteDesign.latitude, siteDesign.longitude],
            name: siteDesign.name
          })
        }
      })

      return result;
    },
    siteDesigns() {
      return this.$store.getters.getSiteDesigns;
    }
  },
  components: {
    BaseLayout,
    LControlZoom,
    LControlAttribution,
    LMarker,
    SDSelectorModal,
    LIcon,
    WeatherTooltip,
    LTileLayer,
    LMap
  },
  async mounted() {
    await WeatherUtils.fetchWeatherData(moment().format('YYYY-MM-DD'));
    this.checkIfFromRem()
  },
  watch: {
  },
  methods: {
    getEnv(name) {
      return TenantConfigHandler.getVariable(name);
    },
    onMarkerClick(siteDesignId) {
      var siteDesign = Utils.getSiteDesignById(siteDesignId);
      if(!siteDesign) return ;

      this.$store.commit('setSiteDesignSidebarCurrentTab', 'floors')
      this.$store.commit('showSidebar', {
        type: 'siteDesign',
        entity: siteDesign
      })
      //this.currentSiteDesign = siteDesign;

      // if(siteDesign.map) { // LEAF element, go to map view
      //   this.$store.commit('setSelectedSiteDesign', siteDesign)
      //   this.$router.push({ name: 'map', params: { siteDesignId: siteDesign.id } })
      // }
      // else { // non-leaf element, open siteDesign selector
      //   this.currentSiteDesign = siteDesign;
      // }
    },
    fetchData(siteDesignId) {
      var vm = this;
      if(vm.weatherData && vm.weatherData.siteDesignId == siteDesignId) return;
      // var currentElement = null;
      // for(var i in this.$refs.wTooltip) {
      //   if(this.$refs.wTooltip[i].$el.getAttribute("_sdid") == siteDesignId) {
      //     currentElement = this.$refs.wTooltip[i];
      //     break;
      //   }
      // }

      window.setTimeout(function() {
        vm.weatherData = {
          siteDesignId: siteDesignId,
          temperature: 20,
          humidity: 50,
          wind: 10,
          rain: 0
        };
        // console.log(vm.weatherData[siteDesignId])
        // currentElement.forceRerender()
        // window.t = currentElement
      }, 1000);
    },
    checkIfFromRem(){
      const isFromRem = store.getters.getIsFromRem
      const siteDesign = store.getters.getSelectedSiteDesign
      if(isFromRem){
        store.commit("showSidebar", {
        type: "siteDesign",
        entity: siteDesign,
        });
      }
    }
  }
}
</script>

<style scoped>
</style>

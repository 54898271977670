<template>
  <div class="text-white w-full">

    <div class="tabs w-full flex flex-row flex-nowrap mb-3">
      <a class="tab tab-lifted text-secondary flex flex-row gap-1 tab-active">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
        </svg>
        <span class="text-xs text-white">{{ $t("message.data") }}</span>
      </a> 
    </div>

    <div>
      <div class="form-control w-full mt-0">
        <label class="label mt-0 pt-0">
          <span class="label-text">{{ $t("message.displayName") }}</span>
          <span class="label-text-alt"></span>
        </label>
        <input v-model="state.name" type="text" :placeholder="$t('message.aggregationNamePlaceholder')" class="input input-bordered w-full mb-3" />
      </div>

      <div class="flex flex-col items-center justify-center gap-2 w-full">
        <div class="flex flex-row gap-3 w-full">
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0">
              <span class="label-text">{{ $t("message.deviceType") }}</span>
              <span class="label-text-alt"></span>
            </label>
            <select v-model="state.gistType" class="select select-sm w-full select-bordered ">
              <option disabled selected>{{ $t("message.choose") }}</option>
              <option v-for="(gistType, key) in gistTypes" :key="key" :value="gistType">{{ gistType }}</option>
            </select>
          </div>
          <div class="form-control w-full mt-0">
            <label class="label mt-0 pt-0">
              <span class="label-text">{{ $t("message.aggregationFunction") }}</span>
              <span class="label-text-alt"></span>
            </label>
            <select v-model="state.aggregationFunction" class="select select-sm w-full select-bordered">
              <option disabled selected>{{ $t("message.choose") }}</option>
              <option v-for="(aggregationFunction, key) in aggregationFunctions" :key="key" :value="aggregationFunction">{{ aggregationFunction }}</option>
            </select>
          </div>
        </div>

        <div class="w-full mt-3">
          <button v-if="state.parentId == null" :disabled="!validation()" class="disabled:opacity-60 btn btn-sm bg-magenta text-white font-light text-xs border-none" @click="addNewAggregation">
            {{ $t("message.save") }}
          </button>
          <button :disabled="!saved()" class="disabled:opacity-60 btn btn-red btn-sm text-white font-light text-xs ml-3 border-none" @click="updateAggregationGists">
            <span v-if="isUpdatedingAggregationGists()" class="loading loading-spinner loading-xs"></span> {{ $t("message.updateAggregationGists") }}
          </button>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
  import Axios from '@/misc/axios';
  import store from '@/misc/vuex-store';
  import { reactive, computed, watch } from 'vue';
  import toast from '@/misc/toast.js'
  import TenantConfigHandler from '@/misc/TenantConfigHandler';

  const props = defineProps({
    backToList: {
      type: Function,
      default: () => {}
    },
    editedAggregationFunction: {
      type: String,
      default: ''
    },
    editedName: {
      type: String,
      default: ''
    },
    editedGistType: {
      type: String,
      default: ''
    },
    editedId: {
      type: Number,
      default: 0
    },
  })

  const state = reactive({
    name: props.editedName ? props.editedName : "",
    gistType: props.editedGistType ? props.editedGistType : null,
    aggregationFunction: props.editedAggregationFunction ? props.editedAggregationFunction : null,
    id: props.editedId ? props.editedId : null,
    isUpdatedingAggregationGists: false,
  })

  const sidebarContent = computed(() => {
    var entity = store.getters.getSidebarEntity;
    return entity;
  });

  const gistTypes = computed(() => {
    let gistTypes = store.getters.getGistTypes;
    return gistTypes;
  });
  const aggregationFunctions = computed(() => {
    let aggregationFunctions = store.getters.getAggregationFunctions;
    return aggregationFunctions;
  });



  function addNewAggregation() {
    const entityType = sidebarContent.value.params.entityType;
    const entityId = sidebarContent.value.entity.id ? sidebarContent.value.entity.id : "";

    let uri = TenantConfigHandler.getVariable('ADMINV2_API_URL') + '/' + entityType + "/" + entityId + '/add-aggregation';

    Axios.post(uri, {
      aggregationFunction: state.aggregationFunction,
      name: state.name,
      gistType: state.gistType,
      id: state.id
    }, {
      onTokenRefreshed: () => {
        addNewAggregation()
      }
    }).then(() => {
      state.name = "";
      state.gistType = null;
      state.aggregationFunction = null;
      state.id = null;
      props.backToList();
      toast.success("message.saved")
    })
    .catch((err) => {
      console.log(err)
      toast.error("message.error")
    })
  }

  function updateAggregationGists() {
    state.isUpdatedingAggregationGists = true;
    let uri = TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/aggregation/' + state.id + '/update';

    Axios.get(uri, {}, {
      onTokenRefreshed: () => {
        updateAggregationGists()
      }
    }).then(() => {
      state.isRefreshingAggregation = false;
      toast.success("message.updated")
    }).catch((err) => {
      console.log(err)
    })
  }

  function validation() {
    if(!state.name || !state.gistType || !state.aggregationFunction) return false;
    return true;
  }

  function saved() {
    return (state.id !== null);
  }


  function isUpdatedingAggregationGists() {
    return state.isUpdatedingAggregationGists;
  }


  watch(() => props.editedName, (newValue) => {
    state.name = newValue;
  });

  watch(() => props.editedGistType, (newValue) => {
    state.gistType = newValue;
  });

  watch(() => props.editedId, (newValue) => {
    state.id = newValue;
  });

  watch(() => props.editedAggregationFunction, (newValue) => {
    state.aggregationFunction = newValue;
  });

</script>

<template>
  <div class="w-full mt-3 pr-5">
    <div class="flex flex-col w-full pl-5">
      <label class="label mt-0 p-0">
        <span class="label-text text-magenta font-normal text-lg"
          >Manual spot input</span
        >
      </label>
      <div class="flex flex-col">
        <div class="flex items-start my-3 gap-5">
          <div class="w-full">
            <label class="flex flex-col gap-2">
              <VueDatePicker
                format="yyyy-MMM-dd HH:mm"
                :enable-time-picker="true"
                :time-picker-inline="true"
                :locale="locale"
                :month-change-on-scroll="false"
                :action-row="{}"
                :clearable="false"
                select-text="OK"
                :cancel-text="$t('message.no')"
                :teleport="true"
                model-type="yyyy-MM-dd HH:mm"
                :placeholder="$t('message.date')"
                v-model="date"
                minutes-grid-increment="15"
                minutes-increment="15"
                :start-time="startTime"
              />
            </label>
          </div>
        </div>
      </div>

      <div class="form-control w-full mt-0">
        <label class="label mt-0 pt-0">
          <span class="label-text">{{ $t('message.value') }}</span>
          <span class="label-text-alt"></span>
        </label>
        <input
          v-model="value"
          type="number"
          class="input input-bordered w-full mb-3"
        />
      </div>

      <div class="form-control w-full mt-0">
        <label class="label mt-0 pt-0">
          <span class="label-text">{{ $t('message.minValue') }}</span>
          <span class="label-text-alt"></span>
        </label>
        <input
          v-model="valueMin"
          type="number"
          class="input input-bordered w-full mb-3"
        />
      </div>

      <div class="form-control w-full mt-0">
        <label class="label mt-0 pt-0">
          <span class="label-text">{{ $t('message.maxValue') }}</span>
          <span class="label-text-alt"></span>
        </label>
        <input
          v-model="valueMax"
          type="number"
          class="input input-bordered w-full mb-3"
        />
      </div>

      <div class="form-control w-full mt-0">
        <label class="label mt-0 pt-0">
          <span class="label-text">{{ $t('message.batteryCharge') }}</span>
          <span class="label-text-alt"></span>
        </label>
        <input
          v-model="batteryCharge"
          type="number"
          class="input input-bordered w-full mb-3"
        />
      </div>

      <div class="w-full mt-3 flex flex-row justify-between">
        <button
          class="disabled:opacity-60 btn btn-sm bg-magenta text-white font-light text-xs border-none"
          @click="createSpotForGist"
          :disabled="!validation()"
        >
          {{ $t("message.save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Axios from "@/misc/axios.js";
import toast from "@/misc/toast.js";
import TenantConfigHandler from '@/misc/TenantConfigHandler';

export default {
  name: "ManualSpotInputContent",
  props: {
    gist: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      date: "",
      value: null,
      valueMin: null,
      valueMax: null,
      batteryCharge: null,
      startTime: {
        hours: '0',
        minutes: '0',
      },
    };
  },
  components: {
    VueDatePicker,
  },
  computed: {
    locale() {
      return this.$store.getters.getLanguage;
    },
  },
  methods: {
    async createSpotForGist() {
      console.log(this.gist.id);
      let uri =
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/gist/${this.gist.id}/spot`;
      const postBody = {
        batteryCharge: this.batteryCharge,
        tsDate: this.date,
        value: this.value,
        valueMax: this.valueMax,
        valueMin: this.valueMin,
      };

      try {
        await Axios.post(uri, postBody);
        this.clearInputs();
        toast.success(this.$t("message.successfulSave"));
      } catch (err) {
        console.log(err);
        toast.error(this.$t("message.saveError"));
      }
    },
    validation() {
      if (!this.date || !this.value) return false;
      return true;
    },
    clearInputs() {
      (this.batteryCharge = null),
        (this.value = null),
        (this.valueMax = null),
        (this.valueMin = null),
        (this.date = "");
    },
  },
};
</script>

<style></style>

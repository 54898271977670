<template>
  <div id="PropertyPage" class="h-screen">
    <BaseLayout>
      <template #mainContent>
        <div class="h-fit px-2 overflow-y-auto mx-30 k">
          <!--Header-->
          <header class="w-full cardBg">
            <div class="items-start px-6 pb-6 mx-auto md:flex-row">
              <div class="flex justify-between">
                <p class="flex mb-4 text-xl text-secondary md:mb-0 uppercase font-bold">
                  {{ $t("message.property") }} -
                  {{
                    state.property
                      ? state.property.primaryAddressPostalCode + ' ' + state.property.primaryAddressCity + ', ' +
                      state.property.primaryAddressPublicAreaName + ' ' + state.property.primaryAddressPublicAreaTypeValue +
                      ' '
                      + state.property.primaryAddressHouseNumber + '.'
                      : ""
                  }}
                </p>
                <RemUpButton remType="property" />
              </div>
            </div>
          </header>

          <div class="flex md:flex-row md:columns-2 flex-col w-full">
            <!--SIDEMENU-->
            <div
              class="flex flex-row md:flex-col im-sidemenu-contanier rounded-xl mb-4 md:mb-0 flex-wrap md:flex-nowrap w-full md:md:w-auto justify-center">
              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a @click="() => {
                    state.tab = 1;
                  }
                    " class="btn btn-md btn-secondary btn-circle normal-case text-sm"
                    :class="{ 'im-button-active': state.tab == 1 }">
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="building3_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">{{
                      $t("message.buildings")
                    }}</span>
                  </a>
                </label>
              </div>

              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a @click="() => {
                    state.tab = 2;
                  }
                    " class="btn btn-md btn-secondary btn-circle normal-case text-sm"
                    :class="{ 'im-button-active': state.tab == 2 }">
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="address_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">{{
                      $t("message.addresses")
                    }}</span>
                  </a>
                </label>
              </div>
              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a @click="() => {
                    state.tab = 3;
                  }
                    " class="btn btn-md btn-secondary btn-circle normal-case text-sm"
                    :class="{ 'im-button-active': state.tab == 3 }">
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="partner_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">{{
                      $t("message.partners")
                    }}</span>
                  </a>
                </label>
              </div>
              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a @click="() => {
                    state.tab = 4;
                  }
                    " class="btn btn-md btn-secondary btn-circle normal-case text-sm"
                    :class="{ 'im-button-active': state.tab == 4 }">
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="document_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">{{
                      $t("message.documents")
                    }}</span>
                  </a>
                </label>
              </div>
              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a @click="() => {
                    state.tab = 5;
                  }
                    " class="btn btn-md btn-secondary btn-circle normal-case text-sm"
                    :class="{ 'im-button-active': state.tab == 5 }">
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="data_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">{{
                      $t("message.baseDatas")
                    }}</span>
                  </a>
                </label>
              </div>
              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a @click="() => {
                    state.tab = 6;
                  }
                    " class="btn btn-md btn-secondary btn-circle normal-case text-sm"
                    :class="{ 'im-button-active': state.tab == 6 }">
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="legal_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">{{
                      $t("message.legalSituations")
                    }}</span>
                  </a>
                </label>
              </div>
              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a @click="() => {
                    state.tab = 7;
                  }
                    " class="btn btn-md btn-secondary btn-circle normal-case text-sm"
                    :class="{ 'im-button-active': state.tab == 7 }">
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="system_settings_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">{{
                      $t("message.utilizations")
                    }}</span>
                  </a>
                </label>
              </div>
              <div class="flex-row mb-4">
                <label class="tooltip tooltip-bottom">
                  <a @click="() => {
                    state.tab = 9;
                  }
                    " class="btn btn-md btn-secondary btn-circle normal-case text-sm"
                    :class="{ 'im-button-active': state.tab == 9 }">
                    <div class="im-btn-icon">
                      <SVGRenderer svgKey="obli_icon"></SVGRenderer>
                    </div>
                    <span class="im-btn-text">{{
                      $t("message.obligations")
                    }}</span>
                  </a>
                </label>
              </div>
            </div>

            <TransitionGroup name="fade">
              <!--BUILDINGS-->
              <div v-if="state.tab == 1" class="flex flex-col im-main-card rounded-xl im-main-card-starter">
                <div>
                  <h2 class="text-xl text-secondary w-full font-bold mb-8">
                    {{ $t("message.buildings") }}
                  </h2>
                  <button @click="
                    state.tab = 13;
                  state.buildingId = 0;
                  clearData(state.buildingBasicData);
                  clearData(state.buildingData);
                  " class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
                    {{ $t("message.addNew") }}
                  </button>
                  <hr class="mb-8" />
                  <div class="im-card-form">
                    <RobberVueGrid ref="buildingsGrid" :config="buildingsGridConfigComputed" />
                  </div>
                </div>
              </div>
              <!--ADD/EDIT BUILDING-->
              <div class="flex flex-col im-main-card rounded-xl im-main-card-starter" v-if="state.tab == 13">
                <div>
                  <button class="btn btn-secondary btn-sm btn-circle mb-8"
                    @click="setConfirmation('saveConfirmation', 1, saveBuilding)">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                    </svg>
                  </button>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    {{ state.siteDesignName }}
                  </h2>
                  <div v-for="item in state.buildingBasicData" v-bind:key="item.model" class="mb-8 mt-4">
                    <!-- eslint-disable -->
                    <ImFormInput v-model:inputValue="item.value" v-model:parent="item.parent"
                      :title="$t('message.' + item.title)" :type="item.type" :valueLabel="item.valueLabel"
                      :optionValue="item.optionValue" :endPoint="item.endPoint" :manualOptions="item.manualOptions"
                      :displayValue="item.displayValue" :searchable="item.searchable"
                      :onchangeMethod="item.onchangeMethod" :validationMethod="item.validationMethod"
                      :helperText="item.helperText ? $t('message.' + item.helperText) : null">
                    </ImFormInput>
                    <!-- eslint-enable -->

                  </div>
                  <div class="im-card-form">
                    <div v-for="item in state.buildingData" v-bind:key="item.model" class="mb-8">
                      <!-- eslint-disable -->
                      <ImFormInput v-model:inputValue="item.value" v-model:parent="item.parent"
                        :title="$t('message.' + item.title)" :type="item.type" :valueLabel="item.valueLabel"
                        :optionValue="item.optionValue" :endPoint="item.endPoint" :manualOptions="item.manualOptions"
                        :displayValue="item.displayValue" :searchable="item.searchable"
                        :onchangeMethod="item.onchangeMethod" :validationMethod="item.validationMethod"
                        optionSearchEndpoint="siteDesign">
                      </ImFormInput>
                      <!-- eslint-enable -->
                    </div>

                    <div class="flex flex-row justify-between">
                      <button @click="saveBuilding" class="btn btn-md btn-secondary normal-case text-sm im-round-btn">
                        {{ $t("message.save") }}
                      </button>
                      <button v-if="state.buildingEdit && User.hasPermission('VocabularyAdmin')"
                        @click="setConfirmation('buildingDeleteConfirm', 1, deleteBuilding)"
                        class="btn btn-md btn-error hover:bg-opacity-50 hover:border-none normal-case text-sm im-round-btn">
                        {{ $t("message.delete") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!--ADDRESSES-->
              <div v-if="state.tab == 2" class="flex flex-col im-main-card rounded-xl im-main-card-starter">
                <div>
                  <h2 class="text-xl text-secondary w-full font-bold mb-8">
                    {{ $t("message.addresses") }}
                  </h2>
                  <button @click="
                    state.tab = 12;
                  state.addressId = 0;
                  clearData(state.addressData);
                  " class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
                    {{ $t("message.addNew") }}
                  </button>
                  <hr class="mb-8" />
                  <div class="im-card-form">
                    <RobberVueGrid ref="addressesGrid" :config="addressesGridConfigComputed" />
                  </div>
                </div>
              </div>
              <!--ADD/EDIT ADDRESSES-->
              <div class="flex flex-col im-main-card rounded-xl im-main-card-starter" v-if="state.tab == 12">
                <div>
                  <button class="btn btn-secondary btn-sm btn-circle mb-8"
                    @click="setConfirmation('saveConfirmation', 2, saveSecondaryAddress)">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                    </svg>
                  </button>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    {{ $t("message.address") }}
                  </h2>
                  <div class="im-card-form">
                    <div v-for="item in state.addressData" v-bind:key="item.model" class="mb-8">
                      <!-- eslint-disable -->
                      <ImFormInput
                      v-model:inputValue="item.value"
                        v-model:parent="item.parent"
                        v-model:validated="item.validated"
                        :title="$t('message.' + item.title)"
                        :type="item.type"
                        :valueLabel="item.valueLabel"
                        :optionValue="item.optionValue"
                        :endPoint="item.endPoint"
                        :displayValue="item.displayValue"
                        :searchable="item.searchable"
                        :validationMethod="item.validationMethod"
                        :onchangeMethod="item.onchangeMethod"
                        :helperText="item.helperText ? $t('message.' + item.helperText) : null"
                        :mandatory="item.mandatory"
                      >
                      </ImFormInput>
                      <!-- eslint-enable -->

                    </div>

                    <div class="flex flex-row justify-between">
                      <button v-if="state.addressMainValidation==true" @click="saveSecondaryAddress"
                        class="btn btn-md btn-secondary normal-case text-sm im-round-btn">
                        {{ $t("message.save") }}
                      </button>
                      <button
                        v-if="state.addressEdit && User.hasPermission('VocabularyAdmin') && state.addressType != 'PRIMARY'"
                        @click="setConfirmation('deleteAddressConfirm', 2, deleteAddress)"
                        class="btn btn-md btn-error hover:bg-opacity-50 hover:border-none normal-case text-sm im-round-btn">
                        {{ $t("message.delete") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!--UTILIZATIONS-->
              <div v-if="state.tab == 7" class="flex flex-col im-main-card rounded-xl im-main-card-starter">
                <div>
                  <h2 class="text-xl text-secondary w-full font-bold mb-8">
                    {{ $t("message.utilizations") }}
                  </h2>
                  <button @click="
                    state.tab = 11;
                  state.utilizationId = 0;
                  clearData(state.utilizationData);
                  " class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
                    {{ $t("message.addNew") }}
                  </button>
                  <hr class="mb-8" />
                  <div class="im-card-form">
                    <RobberVueGrid ref="utilizationsGrid" :config="utilizationsGridConfigComputed" />
                  </div>
                </div>
              </div>
              <!--ADD/EDIT UTILIZATION-->
              <div class="flex flex-col im-main-card rounded-xl im-main-card-starter" v-if="state.tab == 11">
                <div>
                  <button class="btn btn-secondary btn-sm btn-circle mb-8"
                    @click="setConfirmation('saveConfirmation', 7, saveUtilization)">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                    </svg>
                  </button>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    {{ $t("message.utilization") }}
                  </h2>
                  <div class="im-card-form">
                    <div v-for="item in state.utilizationData" v-bind:key="item.model" class="mb-8">
                      <!-- eslint-disable -->
                      <ImFormInput v-model:inputValue="item.value" v-model:parent="item.parent"
                        :title="$t('message.' + item.title)" :type="item.type" :valueLabel="item.valueLabel"
                        :optionValue="item.optionValue" :endPoint="item.endPoint" :manualOptions="item.manualOptions"
                        :displayValue="item.displayValue" :searchable="item.searchable"
                        :onchangeMethod="item.onchangeMethod" :validationMethod="item.validationMethod">
                      </ImFormInput>
                      <!-- eslint-enable -->
                    </div>

                    <div class="flex flex-row justify-between">
                      <button @click="saveUtilization"
                        class="btn btn-md btn-secondary normal-case text-sm im-round-btn">
                        {{ $t("message.save") }}
                      </button>
                      <button v-if="state.utilizationEdit && User.hasPermission('VocabularyAdmin')"
                        @click="setConfirmation('deleteUtilizationConfirm', 7, deleteUtilization)"
                        class="btn btn-md btn-error hover:bg-opacity-50 hover:border-none normal-case text-sm im-round-btn">
                        {{ $t("message.delete") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!--LEGAL SITUATIONS-->
              <div v-if="state.tab == 6" class="flex flex-col im-main-card rounded-xl im-main-card-starter">
                <div>
                  <h2 class="text-xl text-secondary w-full font-bold mb-8">
                    {{ $t("message.legalSituations") }}
                  </h2>
                  <button @click="
                    state.tab = 8;
                  state.legalsitId = 0;
                  clearData(state.legalsitData);
                  " class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
                    {{ $t("message.addNew") }}
                  </button>
                  <hr class="mb-8" />
                  <div class="im-card-form">
                    <RobberVueGrid ref="legalSituationsGrid" :config="legalSituationsGridConfigComputed" />
                  </div>
                </div>
              </div>
              <!--ADD/EDIT LEGAL SITUATION-->
              <div class="flex flex-col im-main-card rounded-xl im-main-card-starter" v-if="state.tab == 8">
                <div>
                  <button class="btn btn-secondary btn-sm btn-circle mb-8"
                    @click="setConfirmation('saveConfirmation', 6, saveLegalsit)">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                    </svg>
                  </button>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    {{ $t("message.legalSituation") }}
                  </h2>
                  <div class="im-card-form">
                    <div v-for="item in state.legalsitData" v-bind:key="item.model" class="mb-8">
                      <!-- eslint-disable -->
                      <ImFormInput v-model:inputValue="item.value" v-model:parent="item.parent"
                        :title="$t('message.' + item.title)" :type="item.type" :valueLabel="item.valueLabel"
                        :optionValue="item.optionValue" :endPoint="item.endPoint" :manualOptions="item.manualOptions"
                        :displayValue="item.displayValue" :searchable="item.searchable"
                        :onchangeMethod="item.onchangeMethod" :validationMethod="item.validationMethod">
                      </ImFormInput>
                      <!-- eslint-enable -->
                    </div>

                    <div class="flex flex-row justify-between">
                      <button @click="saveLegalsit" class="btn btn-md btn-secondary normal-case text-sm im-round-btn">
                        {{ $t("message.save") }}
                      </button>
                      <button v-if="state.legalsitEdit && User.hasPermission('VocabularyAdmin')"
                        @click="setConfirmation('deleteLegalSituationConfirm', 6, deleteLegalSituation)"
                        class="btn btn-md btn-error hover:bg-opacity-50 hover:border-none normal-case text-sm im-round-btn">
                        {{ $t("message.delete") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!--BASEDATA-->
              <div v-if="state.tab == 5" class="flex flex-col im-main-card rounded-xl im-main-card-starter">
                <!-- <PropertyLists title="baseData" :property="state.property" /> -->
                <div class="im-card-form">
                  <div v-for="item in state.propertyBasicData" v-bind:key="item.model" class="mb-8">
                    <div v-if="state.basicDataEdit == false">
                      <ImFormLabel :item="item"></ImFormLabel>
                    </div>
                    <div v-if="state.basicDataEdit == true">
                      <!-- eslint-disable -->
                      <ImFormInput v-model:inputValue="item.value" v-model:parent="item.parent"
                        :title="$t('message.' + item.title)" :type="item.type" :valueLabel="item.valueLabel"
                        :optionValue="item.optionValue" :endPoint="item.endPoint" :manualOptions="item.manualOptions"
                        :displayValue="item.endPoint" :searchable="item.searchable"
                        :onchangeMethod="item.onchangeMethod" :validationMethod="item.validationMethod"
                        :helperText="item.helperText ? $t('message.' + item.helperText) : null">
                      </ImFormInput>
                      <!-- eslint-enable -->
                    </div>
                  </div>

                  <button v-if="state.basicDataEdit == true" @click="saveProperty"
                    class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                      $t("message.save") }}</button>
                  <button v-if="state.basicDataEdit == false" @click="state.basicDataEdit = true"
                    class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                      $t("message.edit") }}</button>

                </div>
              </div>
              <!--Dokumentumok-->
              <div v-if="state.tab == 4" class="flex flex-col im-main-card rounded-xl im-main-card-starter">
                <PropertyLists title="documents" :property="state.property" />
              </div>
              <!--PARTNERS-->
              <div v-if="state.tab == 3" class="flex flex-col im-main-card rounded-xl im-main-card-starter">
                <div>
                  <h2 class="text-xl text-secondary w-full font-bold mb-8">
                    {{ $t("message.partners") }}
                  </h2>
                  <div class="flex flex-row justify-between">
                    <button @click="
                      state.tab = 14;
                    state.partnerId = 0;
                    clearData(state.partnerData);
                    " class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
                      {{ $t("message.addNew") }}
                    </button>
                    <button @click="state.partnerRvgType = !state.partnerRvgType"
                      class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
                      <SVGRenderer svgKey="partner_mode_switch_icon" class="tooltip tooltip-left tooltip-hover"
                        :data-tip="$t('message.propertyPartnerModeSwitch')"></SVGRenderer>
                    </button>
                  </div>

                  <hr class="mb-8" />
                  <h2 class="text-xl text-secondary w-full font-bold">
                    <span v-if="!state.partnerRvgType">{{ $t("message.propertyPartners") }}</span>
                    <span v-if="state.partnerRvgType">{{ $t("message.allRelatedPartner") }}</span>
                  </h2>
                  <div class="im-card-form">
                    <RobberVueGrid v-if="!state.partnerRvgType" ref="partnersGrid"
                      :config="partnersGridConfigComputed" />
                    <RobberVueGrid v-if="state.partnerRvgType" ref="partnersGrid"
                      :config="allRelatedPartnersGridConfigComputed" />
                  </div>
                </div>
              </div>
              <!--ADD/EDIT PARTNER-->
              <div class="flex flex-col im-main-card rounded-xl im-main-card-starter" v-if="state.tab == 14">
                <div>
                  <button class="btn btn-secondary btn-sm btn-circle mb-8"
                    @click="setConfirmation('saveConfirmation', 3, savePartner)">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                    </svg>
                  </button>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    {{ state.partnerData[0].value }}
                  </h2>
                  <div class="im-card-form">
                    <div v-for="item in state.partnerData" v-bind:key="item.model" class="mb-8">
                      <div v-if="
                        state.partnerEdit == false && state.partnerId != 0
                      ">
                        <ImFormLabel v-if="
                          (item.model != 'taxId' &&
                            item.model != 'taxNumber') ||
                          (item.model == 'taxId' && state.isPerson == true) ||
                          (item.model == 'taxNumber' &&
                            state.isPerson == false)
                        " :item="item"></ImFormLabel>
                      </div>
                      <div v-if="state.partnerEdit == true || state.partnerId == 0">
                        <!-- eslint-disable -->
                        <ImFormInput v-if="(item.model != 'taxId' && item.model != 'taxNumber')
                          || ((item.model == 'taxId' && state.isPerson == true)
                            || ((item.model == 'taxNumber' && state.isPerson == false)))" v-model:inputValue="item.value"
                          v-model:parent="item.parent" :title="$t('message.' + item.title)" :type="item.type"
                          :valueLabel="item.valueLabel" :optionValue="item.optionValue" :endPoint="item.endPoint"
                          :manualOptions="item.manualOptions" :displayValue="item.endPoint"
                          :searchable="item.searchable" :onchangeMethod="item.onchangeMethod"
                          :validationMethod="item.validationMethod">
                        </ImFormInput>
                        <!-- eslint-enable -->

                      </div>
                    </div>

                    <div class="flex flex-row justify-between">
                      <button @click="savePartner" class="btn btn-md btn-secondary normal-case text-sm im-round-btn">
                        {{ $t("message.save") }}
                      </button>
                      <button v-if="state.partnerEdit && User.hasPermission('VocabularyAdmin')"
                        @click="setConfirmation('confirmRemovePartner', 3, deletePartner)"
                        class="btn btn-md btn-error hover:bg-opacity-50 hover:border-none normal-case text-sm im-round-btn">
                        {{ $t("message.delete") }}
                      </button>
                    </div>
                    <button v-if="state.partnerEdit == false && state.partnerId != 0" @click="state.partnerEdit = true"
                      class="btn btn-md btn-secondary normal-case text-sm im-round-btn">
                      {{ $t("message.edit") }}
                    </button>
                  </div>
                </div>
              </div>
              <!--OBLIGATIONS-->
              <div v-if="state.tab == 9" class="flex flex-col im-main-card rounded-xl im-main-card-starter">
                <div>
                  <h2 class="text-xl text-secondary w-full font-bold mb-8">
                    {{ $t("message.obligations") }}
                  </h2>
                  <button @click="
                    state.tab = 10;
                  state.obligationId = 0;
                  clearData(state.obligationData);
                  " class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
                    {{ $t("message.addNew") }}
                  </button>
                  <hr class="mb-8" />
                  <div class="im-card-form">
                    <RobberVueGrid ref="obligationsGrid" :config="obligationsGridConfigComputed" />
                  </div>
                </div>
              </div>
              <!--ADD/EDIT OBLIGATION-->
              <div class="flex flex-col im-main-card rounded-xl im-main-card-starter" v-if="state.tab == 10">
                <div>
                  <button class="btn btn-secondary btn-sm btn-circle mb-8"
                    @click="setConfirmation('saveConfirmation', 9, saveObligation)">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                    </svg>
                  </button>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    {{ $t("message.obligation") }}
                  </h2>
                  <div class="im-card-form">
                    <div v-for="item in state.obligationData" v-bind:key="item.model" class="mb-8">
                      <!-- eslint-disable -->
                      <ImFormInput v-model:inputValue="item.value" v-model:parent="item.parent"
                        :title="$t('message.' + item.title)" :type="item.type" :valueLabel="item.valueLabel"
                        :optionValue="item.optionValue" :endPoint="item.endPoint" :manualOptions="item.manualOptions"
                        :displayValue="item.displayValue" :searchable="item.searchable"
                        :onchangeMethod="item.onchangeMethod" :validationMethod="item.validationMethod">
                      </ImFormInput>
                      <!-- eslint-enable -->
                    </div>

                    <div class="flex flex-row justify-between">
                      <button @click="saveObligation" class="btn btn-md btn-secondary normal-case text-sm im-round-btn">
                        {{ $t("message.save") }}
                      </button>
                      <button v-if="state.obligationEdit && User.hasPermission('VocabularyAdmin')"
                        @click="setConfirmation('deleteObligationConfirm', 6, deleteObligation)"
                        class="btn btn-md btn-error hover:bg-opacity-50 hover:border-none normal-case text-sm im-round-btn">
                        {{ $t("message.delete") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </TransitionGroup>
            <!--CONFIRMATION MODAL-->
            <input type="checkbox" class="modal-toggle" v-model="state.confirmationModal.show" />
            <div class="modal">
              <div class="modal-box">
                <h3 class="text-lg text-left font-normal hover:text-gray-100 text-gray-100">
                  {{ $t('message.' + state.confirmationModal.message) }}
                </h3>
                <div class="modal-action">
                  <label class="btn btn-sm text-white font-light text-xs" @click="() => {
                    state.confirmationModal.show = false;
                    state.tab = state.confirmationModal.tab;
                  }
                    ">{{ $t("message.no") }}</label>
                  <label class="btn btn-sm bg-magenta text-white font-light text-xs" @click="() => {
                    state.confirmationModal.show = false;
                    state.confirmationModal.function();
                    state.tab = state.confirmationModal.tab;
                  }
                    ">{{ $t("message.yes") }}</label>
                </div>
              </div>
            </div>
          </div>
          <div style="height: 10rem"></div>
        </div>
      </template>
    </BaseLayout>
  </div>
</template>

<script setup>
import BaseLayout from "../../layouts/BaseLayout.vue";
import PropertyLists from "../../components/REM/RemLists.vue";
import { onMounted, ref, watch } from "vue";
import { computedAsync } from '@vueuse/core'
import RVGUtils from "../../components/RVG/RobberVueGridUtils.js";
import Utils from "../../misc/Utils";
import User from "../../misc/User";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import RemUpButton from "../../components/REM/RemUpButton.vue";
import SVGRenderer from "../../components/SVGRenderer.vue";
import formdata from "./FormData";
import legalSituationsGridConfig from "../../misc/gridConfigs/propertyPage/legalSituationsGridConfig";
import ImFormInput from "../../components/ImFormInput.vue";
import ImFormLabel from "../../components/ImFormLabel.vue";
import RobberVueGrid from "../../components/RVG/RobberVueGrid.vue";
import propertyUtils from "./propUtils";
import obligationsGridConfig from "../../misc/gridConfigs/propertyPage/propObligationsGridConfig";
import utilizationsGridConfig from "../../misc/gridConfigs/propertyPage/utilizationsGridConfig";
import addressesGridConfig from "../../misc/gridConfigs/propertyPage/addressesGridConfig";
import buildingsGridConfig from "../../misc/gridConfigs/propertyPage/buildingsGridConfig";
import partnersGridConfig from "../../misc/gridConfigs/propertyPage/partnersGridConfig";
import allRelatedPartnersGridConfig from "../../misc/gridConfigs/propertyPage/allRelatedPartnersGridConfig.js";

const partnersGrid = ref();
const buildingsGrid = ref();
const addressesGrid = ref();
const utilizationsGrid = ref();
const obligationsGrid = ref();
const legalSituationsGrid = ref();

const state = ref({
  tab: 1,
  property: null,
  legalsitData: formdata.legalsitData,
  legalsitId: 0,
  legalsitEdit: false,
  obligationData: formdata.obligationData,
  obligationId: 0,
  obligationEdit: false,
  utilizationData: formdata.utilizationData,
  utilizationId: 0,
  utilizationEdit: 0,
  addressMainValidation: false,
  addressData: formdata.addressData,
  addressId: 0,
  addressEdit: false,
  buildingData: formdata.buildingData,
  buildingBasicData: formdata.buildingBasicData,
  buildingId: 0,
  buildingEdit: false,
  partnerData: formdata.partnerData,
  partnerId: 0,
  isPerson: false,
  partnerEdit: false,
  partnerRvgType: false,
  siteDesignName: "",
  showConfirmation: false,
  showDeleteConfirmation: false,
  propertyBasicData: formdata.propertyBasicData,
  basicDataEdit: false,
  confirmationModal: {
    show: false,
    message: "",
    tab: "",
    function: "",
  },
  addressType: "",
});

const { t } = useI18n();
const router = useRoute();
const propertyId = router.params.propertyId;
propertyUtils.asyncGet(state, propertyId);

//BASIC DATA
const saveProperty = () => {
  propertyUtils.saveProperty(state, t, propertyId)
}

//PARTNERS

const partnersGridConfigComputed = computedAsync(async () => {
  const [
    partnerMainTypeMapping,
    partnerSubTypeMapping
  ] = await Promise.all([
    RVGUtils.getFormatterMappingForOptionValue("PARTNER_MAIN_TYPE"),
    RVGUtils.getFormatterMappingForOptionValue("PARTNER_SUB_TYPE"),
  ])
  return partnersGridConfig(state, t, propertyId, partnerMainTypeMapping, partnerSubTypeMapping);
}, partnersGridConfig(state, t, propertyId));


const allRelatedPartnersGridConfigComputed = computedAsync(async () => {
  const [
    partnerMainTypeMapping,
    partnerSubTypeMapping
  ] = await Promise.all([
    RVGUtils.getFormatterMappingForOptionValue("PARTNER_MAIN_TYPE"),
    RVGUtils.getFormatterMappingForOptionValue("PARTNER_SUB_TYPE"),
  ])
  return allRelatedPartnersGridConfig(state, t, propertyId, partnerMainTypeMapping, partnerSubTypeMapping);
}, allRelatedPartnersGridConfig(state, t, propertyId));


const savePartner = () => {
  propertyUtils.savePartner(state, t, propertyId);
  partnersGrid.value.refresh()
};

const deletePartner = () => {
  propertyUtils.deletePartner(state, state.value.partnerId, t);
  partnersGrid.value.refresh()
};

//BUILDINGS
const buildingsGridConfigComputed = computedAsync(async () => {
  const [
    basicDataMainFunctionMapping,
    basicDataStatusMapping,
    basicDataSapMapping,
    basicDataFoundationMapping,
    basicDataBearingMasonryMapping,
    basicDataSlabStructureInterMapping,
    basicDataSlabSubStructureInterMapping,
    basicDataSlabStructureClosingMapping,
    basicDataSlabSubStructureClosingMapping,
    basicDataFlatRoofMapping,
    basicDataRoofingMapping,
    basicDataRoofTypeMapping,
    basicDataInsulationMapping
  ] = await Promise.all([
    RVGUtils.getFormatterMappingForOptionValue("BUILDING_MAIN_FUNCTION"),
    RVGUtils.getFormatterMappingForOptionValue("BUILDING_STATUS"),
    RVGUtils.getFormatterMappingForOptionValue("SAP_BUILDING_IDENTIFIER"),
    RVGUtils.getFormatterMappingForOptionValue("FOUNDATION"),
    RVGUtils.getFormatterMappingForOptionValue("BEARING_MASONRY"),
    RVGUtils.getFormatterMappingForOptionValue("SLAB_STRUCTURE_INTERMEDIATE"),
    RVGUtils.getFormatterMappingForOptionValue("SLAB_SUB_STRUCTURE_INTERMEDIATE"),
    RVGUtils.getFormatterMappingForOptionValue("SLAB_STRUCTURE_CLOSING"),
    RVGUtils.getFormatterMappingForOptionValue("SLAB_SUB_STRUCTURE_CLOSING"),
    RVGUtils.getFormatterMappingForOptionValue("FLAT_ROOF"),
    RVGUtils.getFormatterMappingForOptionValue("ROOFING"),
    RVGUtils.getFormatterMappingForOptionValue("ROOF_TYPE"),
    RVGUtils.getFormatterMappingForOptionValue("INSULATION"),
  ])

  return buildingsGridConfig(
    state,
    t,
    propertyId,
    propertyUtils.toBuilding,
    basicDataMainFunctionMapping,
    basicDataStatusMapping,
    basicDataSapMapping,
    basicDataFoundationMapping,
    basicDataBearingMasonryMapping,
    basicDataSlabStructureInterMapping,
    basicDataSlabSubStructureInterMapping,
    basicDataSlabStructureClosingMapping,
    basicDataSlabSubStructureClosingMapping,
    basicDataFlatRoofMapping,
    basicDataRoofingMapping,
    basicDataRoofTypeMapping,
    basicDataInsulationMapping
  );
}, buildingsGridConfig(
  state,
  t,
  propertyId,
  propertyUtils.toBuilding));

const saveBuilding = () => {
  propertyUtils.saveBuilding(state, t, propertyId);
  buildingsGrid.value.refresh()
};

const deleteBuilding = () => {
  propertyUtils.deleteBuilding(state, t);
  buildingsGrid.value.refresh()
};

//ADDRESSES
const addressesGridConfigComputed = computedAsync(async () => {
  const [
    countryMapping,
    publicAreaTypeMapping,
  ] = await Promise.all([
    RVGUtils.getFormatterMappingForOptionValue("COUNTRY"),
    RVGUtils.getFormatterMappingForOptionValue("PUBLIC_AREA_TYPE"),
  ])
  return addressesGridConfig(state, t, propertyId, countryMapping, publicAreaTypeMapping);
}, addressesGridConfig(state, t, propertyId));

const saveSecondaryAddress = () => {
  propertyUtils.saveSecondaryAddress(state, propertyId, t);
  addressesGrid.value.refresh()
};

const deleteAddress = () => {
  propertyUtils.deleteAddress(state, state.value.addressId, t);
  addressesGrid.value.refresh()
};

//UTILIZATIONS
const utilizationsGridConfigComputed = computedAsync(async () => {
  const [
    legalRelationshipMapping,
    partnerMainTypeMapping,
    partnerSubTypeMapping
  ] = await Promise.all([
    RVGUtils.getFormatterMappingForOptionValue("LEGAL_RELATIONSHIP_TYPE"),
    RVGUtils.getFormatterMappingForOptionValue("PARTNER_MAIN_TYPE"),
    RVGUtils.getFormatterMappingForOptionValue("PARTNER_SUB_TYPE"),
  ])
  return utilizationsGridConfig(state, t, propertyId, legalRelationshipMapping, partnerMainTypeMapping, partnerSubTypeMapping);
}, utilizationsGridConfig(state, t, propertyId));

const saveUtilization = () => {
  propertyUtils.saveUtilization(state, propertyId, t);
  utilizationsGrid.value.refresh()
};

const deleteUtilization = () => {
  propertyUtils.deleteUtilization(state, state.value.utilizationId, t);
  utilizationsGrid.value.refresh()
};

//OBLIGATIONS
const obligationsGridConfigComputed = computedAsync(async () => {
  const [
    obligationTypeMapping,
    otherRightMapping,
  ] = await Promise.all([
    RVGUtils.getFormatterMappingForOptionValue("OBLIGATION_TYPE"),
    RVGUtils.getFormatterMappingForOptionValue("OTHER_RIGHTS"),
  ])
  return obligationsGridConfig(state, t, propertyId, obligationTypeMapping, otherRightMapping,);
}, obligationsGridConfig(state, t, propertyId));

const saveObligation = () => {
  propertyUtils.saveObligation(state, t, propertyId);
  obligationsGrid.value.refresh()
};

const deleteObligation = () => {
  propertyUtils.deleteObligation(state, state.value.obligationId, t);
  obligationsGrid.value.refresh()
};

//LEGAL SITUATIONS
const legalSituationsGridConfigComputed = computedAsync(async () => {
  const [
    legalRelationshipTypeMapping,
    legalSituationTypeMapping
  ] = await Promise.all([
    RVGUtils.getFormatterMappingForOptionValue("LEGAL_RELATIONSHIP_TYPE"),
    RVGUtils.getFormatterMappingForOptionValue("LEGAL_SITUATION"),
  ])
  return legalSituationsGridConfig(state, t, propertyId, legalRelationshipTypeMapping, legalSituationTypeMapping);
}, legalSituationsGridConfig(state, t, propertyId));

const saveLegalsit = () => {
  propertyUtils.saveLegalsit(state, t, propertyId);
  legalSituationsGrid.value.refresh()
};

const deleteLegalSituation = () => {
  propertyUtils.deleteLegalSituation(state, state.value.legalsitId, t);
  legalSituationsGrid.value.refresh()
};

const setConfirmation = (message, tab, func) => {

  state.value.confirmationModal.message = message
  state.value.confirmationModal.tab = tab
  state.value.confirmationModal.function = func
  state.value.confirmationModal.show = true;

};

const getProperty = async () => {
  const response = await Utils.fetchPropertyById(propertyId);
  state.value.property = response;
};

function clearData(vArray) {
  vArray.forEach((element) => {
    element.value = "";
  });
}

onMounted(() => {
  getProperty();
  //propertyUtils.setup(state)
});


// WATCHERS

watch(() => state.value.partnerData[1].value,
  () => {
    setTimeout(() => {
      state.value.partnerData[2].parent = state.value.partnerData[1].value;
    }, 700);

  });

watch(() => state.value.buildingData[8].value,
  () => {
    setTimeout(() => {
      state.value.buildingData[9].parent = state.value.buildingData[8].value;
    }, 700);

  });

watch(() => state.value.buildingData[10].value,
  () => {
    setTimeout(() => {
      state.value.buildingData[11].parent = state.value.buildingData[10].value;
    }, 700);

  });

watch(() => state.value.addressData[1].value,
  () => {

    setTimeout(() => {
          state.value.addressData[2].parent = state.value.addressData[1].value;
          state.value.addressData[3].parent =  state.value.addressData[1].value;
          state.value.addressData[4].parent =  state.value.addressData[1].value;
        }, 700);

  });

watch(() => state.value.partnerData[3].value,
  () => {
    setTimeout(() => {
      state.value.isPerson =
        state.value.partnerData[3].value == "true" ? true : false;
      if (state.value.isPerson == true) {
        state.value.partnerData[5].value = "";
      } else {
        state.value.partnerData[4].value = "";
      }
    }, 700);

  });

state.value.addressData.forEach((e, i) => {

  if ((e.validated == true || e.validated == undefined) && (e.mandatory == false || e.mandatory == undefined)) return;

  watch(() => state.value.addressData[i].validated,
    () => {
      let lever = true;
      state.value.addressData.forEach((d) => {
        if ((e.mandatory == false || e.mandatory == undefined)) return;
        if (d.validated === false) {
          lever = false
        }

      })


      state.value.addressMainValidation = lever
    });
})


</script>

<style scoped>
.cardBg {
  background-color: hsl(220, 17.647%, 20%);
}

.im-main-card {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
  padding: 3rem;
  width: 100%;
  max-width: 100%;
  height: fit-content;
  overflow-x: auto;
}

@media screen and (max-width: 600px) {
  .im-main-card {
    margin-bottom: 30px;
  }
}

.im-main-card-starter {
  animation: 0.5s ease-out 0s 1 scaleupY;

}

.im-main-card-starter div {
  animation: 1.5s ease-out 0s 1 fadein;
}

@keyframes scaleupY {
  0% {
    transform-origin: left top;
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.im-sidemenu-contanier {
  animation: 0.5s ease-out 0s 1 scaleupY;
  padding: 1rem;

  margin-right: 2rem;
  height: fit-content;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
}

.im-sidemenu-contanier div {
  animation: 1.5s ease-out 0s 1 fadein;
}

.im-card-form {
  padding-top: 2rem;
}

.fade-enter-active {
  transition: opacity 1s ease 1s, padding 1s ease 1s, width 1s ease 1s;
}

.fade-leave-active {
  transition: opacity 0.5s ease, padding 0.5s ease, width 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  padding: 0;
  width: 0;
  opacity: 0;
}

.im-round-btn {
  border-radius: 9999px;
}

/* button */

.btn-circle {
  transition: width 0.5s;
}

.im-sidemenu-contanier:hover .btn-circle {
  width: 15rem;
}

@keyframes fadeinbtn {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.im-btn-text {
  display: none;
}

.im-sidemenu-contanier:hover .im-btn-text {
  animation: 0.5s ease-out 0s 1 fadeinbtn;
  display: inline;
}

.im-btn-icon {
  display: inline;
}

.im-button-active {
  border-color: lighter hsl(var(--s));
}

.im-sidemenu-contanier:hover .im-btn-icon {
  display: none;
}
</style>

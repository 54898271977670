<template>
  <div>
    <a class="btn btn-sm btn-secondary btn-circle flex-0" @click="navigateUp"><svg xmlns="http://www.w3.org/2000/svg"
        fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round"
          d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" />
      </svg></a>
  </div>
</template>

<script setup>
import router from "@/misc/router";
import { useRoute } from "vue-router";

const route = useRoute();
const buildingId = route.params.buildingId;
const propertyId = route.params.propertyId;


const props = defineProps(["remType"]);

const navigateUp = () => {
  switch (props.remType) {
    case "premise":
      router.push({
        name: "buildingPage",
        params: { buildingId: buildingId },
      });
      break;
    case "building":
      router.push({
        name: "propertyPage",
        params: { propertyId: propertyId },
      });
      break;
    case "property":
      router.push({
        name: "propertiesPage",
      });
      break;
    case "energetics":
      router.push({
        name: "buildingPage",
        params: { buildingId: buildingId },
      });
      break;
  }
};
</script>

<style scoped></style>

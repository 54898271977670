<template>
  <div class="p-0 h-full overflow-y-scroll">
    <div class="p-5 pb-2 mr-2 flex flex-col grow border-b-0 border-base-300">
      <div class="flex flex-row items-center gap-2 justify-center">
        <button @click="() => { backButtonFunction(); }" class="text-white"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"></path></svg></button>
        <h2 class="text-base font-sans font-semibold uppercase text-secondary flex flex-row items-center gap-2 grow">
          <span class="text-white">{{ sidebarEntity.displayName }}</span>
          <button v-if="User.hasPermission('ViewGists')" @click="showGistEditorSidebar" class="text-magenta font-light">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5" />
            </svg>
          </button>
        </h2>
        <a href="javascript:void(null)" class="hover:text-secondary" @click="hideSidebar">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </a>
      </div>
    </div>

    <div class="px-5">
      <GistsBaseComponent :entity="sidebarEntity" type="zone-group" />
      <AggregationBaseComponent :entity="sidebarEntity" type="zone-group" />
    </div>
  </div>
</template>

<script setup>
  import User from '@/misc/User';
  import GistsBaseComponent from '@/components/Gists/GistsBaseComponent.vue';
  import AggregationBaseComponent from '@/components/Gists/AggregationBaseComponent.vue';
  import store from '@/misc/vuex-store';
  import { computed } from 'vue';

  function hideSidebar() {
    store.commit('hideSidebar');
  }

  function backButtonFunction() {
    var entity = store.getters.getSidebarEntity;
    if(entity.params && entity.params.back) {
      entity.params.back();
    }
  }

  function showGistEditorSidebar() {
    let that = this;
    var entity = store.getters.getSidebarEntity;
    let fn = entity.params.back.bind({})

    store.commit('showSidebar', { type: 'gistManager', entity: sidebarEntity, params: {
      entityType: 'zone-group',
      back: () => {
        that.$store.commit('showSidebar', { type: 'zoneGroup', entity: sidebarEntity, params: {
          back: fn
        } });
      }
    }});
  }

  const sidebarEntity = computed(() => {
    var entity = store.getters.getSidebarEntity;
    return entity.entity;
  });

</script>
<template>
  <div class="p-0 h-full overflow-y-scroll">
    <div class="p-5 pb-2 mr-2 flex flex-col grow border-b-0 border-base-300">
      <div class="flex flex-row items-center gap-2 justify-center">
        <button class="text-white" @click="() => { backButtonFunction(); }">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
          </svg>
        </button>
        <h2 class="text-base font-sans font-semibold uppercase text-secondary flex flex-row items-center gap-2 grow">
          <span class="text-white">{{ sidebarEntity.displayName ? sidebarEntity.displayName : sidebarEntity.name }}</span> -
          <span v-if="state.currentView == 'list'">{{ $t("message.listOfDevices") }}</span>
          <span v-if="state.currentView == 'add'">{{ $t("message.addDevice") }}</span>
          <span v-if="state.currentView == 'edit'">{{ $t("message.editDevice") }}</span>
          <span v-if="state.currentView == 'addAggregation'">{{ $t("message.addAggregation") }}</span>
          <span v-if="state.currentView == 'editAggregation'">{{ $t("message.editAggregation") }}</span>
        </h2>
        <a href="javascript:void(null)" class="hover:text-secondary" @click="hideSidebar">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </a>
      </div>
    </div>

    <div class="px-5 text-center">
      <AddNewOrEditGistComponent v-if="state.currentView == 'add' || state.currentView == 'edit'" :editedAdapterName="state.editedAdapterName" :editedDisplayName="state.editedDisplayName" :edited-config="state.editedConfig" :edited-gist-type="state.editedGistType" :edited-id="state.editedId" :editedParentId="state.parentId" :backToList="backToList" />
      <AddNewOrEditAggregationComponent v-if="state.currentView == 'addAggregation' || state.currentView == 'editAggregation'" :edited-gist-type="state.editedGistType" :edited-id="state.editedId" :editedName="state.editedName" :editedAggregationFunction="state.editedAggregationFunction" :backToList="backToList" />
      <ListGists v-if="state.currentView == 'list'" :clickOnEdit="clickOnGistEdit" />
      <ListAggregations v-if="state.currentView == 'list' && sidebarEntity.displayName !== 'system'" :clickOnEdit="clickOnAggregationEdit" />
      <button v-if="state.currentView == 'list' && User.hasPermission('AddNewGist')" class="btn btn-sm text-white font-light text-xs mt-3" @click="() => { state.currentView = 'add'; }">{{ $t("message.addNew") }}</button>
      <button v-if="state.currentView == 'list' && User.hasPermission('AddNewGist') && sidebarEntity.displayName !== 'system'" class="btn btn-sm text-white font-light text-xs mt-3 ml-2" @click="() => { state.currentView = 'addAggregation'; }">{{ $t("message.addNewAggregation") }}</button>
    </div>
  </div>
</template>

<script setup>
  import User from '@/misc/User';
  import AddNewOrEditGistComponent from './GistManagerSidebarContentComponents/AddNewOrEditGistComponent.vue';
  import AddNewOrEditAggregationComponent from './GistManagerSidebarContentComponents/AddNewOrEditAggregationComponent.vue';
  import ListGists from './GistManagerSidebarContentComponents/ListGists.vue';
  import ListAggregations from './GistManagerSidebarContentComponents/ListAggregations.vue';
  import { computed } from 'vue';
  import store from '@/misc/vuex-store';
  import { reactive } from 'vue';

  const state = reactive({
    editedName: '',
    editedAggregationFunction: '',
    editedAdapterName: '',
    editedGistType: '',
    editedDisplayName: '',
    editedConfig: '',
    editedId: null,
    currentView: 'list',
    parentId: null
  });

  const hideSidebar = () => {
    store.commit('hideSidebar');
  }

  const resetEditData = () => {
    state.editedAdapterName = '';
    state.editedGistType = '';
    state.editedDisplayName = '';
    state.editedConfig = '';
    state.editedId = null;
    state.parentId = null;
  }

  const backToList = () => {
    resetEditData();
    state.currentView = 'list';
  }

  const backButtonFunction = () => {
    if(state.currentView == 'add' || state.currentView == 'edit') {
      backToList();
      return;
    }

    var entity = store.getters.getSidebarEntity;
    if(entity.params && entity.params.back) {
      entity.params.back();
    }
  }

  const clickOnGistEdit = (gistData) => {
    state.editedDisplayName = gistData.displayName;
    state.editedGistType = gistData.gistType;
    state.editedAdapterName = gistData.adapterName;
    state.editedConfig = gistData.config;
    state.editedId = gistData.id;
    state.parentId = gistData.parent_id;
    state.currentView = 'edit';
  }
  const clickOnAggregationEdit = (aggregationData) => {
    state.editedName = aggregationData.name;
    state.editedAggregationFunction = aggregationData.aggregationFunction;
    state.editedGistType = aggregationData.gistType;
    state.editedId = aggregationData.id;
    state.currentView = 'editAggregation';
  }

  const sidebarEntity = computed(() => {
    var entity = store.getters.getSidebarEntity;
   
    return entity.entity;
  });

  if(sidebarEntity.value.searchObject){

    state.currentView = "edit"
    clickOnGistEdit({
      displayName: sidebarEntity.value.searchObject.displayName,
      gistType: sidebarEntity.value.searchObject.gistType,
      adapterName: sidebarEntity.value.searchObject.adapterName,
      config: sidebarEntity.value.searchObject.config,
      id: sidebarEntity.value.searchObject.id,
      parent_id: sidebarEntity.value.searchObject.parentId,
    })
  }
</script>
import axios from 'axios';
import store from '../misc/vuex-store.js'
import moment from 'moment';
import WeatherIcons from './WeatherIcons.js';
import TenantConfigHandler from '@/misc/TenantConfigHandler';

export default {
  async fetchWeatherData(date = null, callback = null) {
    try {
      if(!date) {
        date = moment().format('YYYY-MM-DD')
      }

      const response = await axios.get(TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/weather/' + date)
      store.commit("setWeatherData", response.data, date);

      if(callback) {
        callback();
      }
    }
    catch(err) {
      console.log(err)
    }
  },

  async fetchSDWeatherData(siteDesignId, date, callback) {
    try {
      if(!date) {
        date = moment().format('YYYY-MM-DD')
      }

      const response = await axios.get(TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/site-design/' + siteDesignId + '/weather/' + date)
      var cData = response.data;
      var cIcon = WeatherIcons[cData.weatherIcon];
      if(cIcon) {
        if(!(cData.weatherIcon > 699 && cData.weatherIcon < 800) && !(cData.weatherIcon > 899 && cData.weatherIcon < 1000)) {
          cData.iconText = 'day-' + cIcon.icon;
        }
        else {
          cData.iconText = cIcon.icon;
        }
      }

      if(callback) {
        callback(response.data);
      }
    }
    catch(err) {
      if(callback) {
        callback(null);
      }
      console.log(err)
    }
  }
}
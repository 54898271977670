export default {
  data: [
    {
      title: "type",
      value: "",
      model: "type",
      type: "text",
    },
    {
      title: "propellant",
      value: "",
      model: "propellant",
      type: "text",
    },
    {
      title: "performance",
      value: "",
      model: "performance",
      type: "text",
    },
    {
      title: "useOfGeneratedHeat",
      value: "",
      model: "useOfGeneratedHeat",
      type: "text",
    },
    {
      title: "installDate",
      value: "",
      model: "installDate",
      type: "date",
    },
    {
      title: "guarantee",
      value: "",
      model: "guarantee",
      type: "text",
    },
  ]
}
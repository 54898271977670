import axios from "@/misc/axios.js";
import store from "../misc/vuex-store.js";
//import TenantConfigHandler from "@/misc/TenantConfigHandler";
import toast from "@/misc/toast.js";

export default {
  /**
   *
   * @param {*} postendpoint - endpoint for post request
   * @param {*} patchendpoint - endpoint for patch request
   * @param {*} hermes - payload
   * @param {*} schema - formdata name
   *    schemaData : formdata field list
   *    schemaId : record id
   *    schemaEdit : readonly view Toggle
   * @param {*} tab - form tab to route back after succesful save
   * @param {*} state - state object of the form
   * @param {*} t - localization
   */

  patchPost: async (
    postendpoint,
    patchendpoint,
    hermes,
    schema,
    tab,
    state,
    t
  ) => {
    if (state.value[`${schema}Id`] != 0) {
      try {
        await axios
          .patch(patchendpoint, hermes, {
            onTokenRefreshed: () => {
              this.patchPost(
                postendpoint,
                patchendpoint,
                hermes,
                schema,
                tab,
                state,
                t
              );
            },
          })
          .then((resp) => {
            if (resp && resp.status == 200) {
              toast.success(t("message.successfulSave"));
              state.value[`${schema}Data`].forEach((e) => {
                e.value = "";
              });

              state.value.tab = tab;
              store.commit("setRemTab", tab);
              state.value[`${schema}Id`] = 0;
              state.value[`${schema}Edit`] = false;
            } else {
              toast.error(t("message.notSuccessfulSave"));
            }
          });
      } catch (e) {
        toast.error(t("message.notSuccessfulSave"));
      }
    } else {
      try {
        await axios
          .post(postendpoint, hermes, {
            onTokenRefreshed: () => {
              this.patchPost(
                postendpoint,
                patchendpoint,
                hermes,
                schema,
                tab,
                state,
                t
              );
            },
          })
          .then((resp) => {
            if (resp && resp.status == 201) {
              toast.success(t("message.successfulSave"));
              state.value[`${schema}Data`].forEach((e) => {
                e.value = "";
              });

              state.value.tab = tab;
              store.commit("setRemTab", tab);
              state.value[`${schema}Id`] = 0;
              state.value[`${schema}Edit`] = false;
            } else {
              toast.error(t("message.notSuccessfulSave"));
            }
          });
      } catch (e) {
        toast.error(t("message.notSuccessfulSave"));
      }
    }
  },

  /**
   *
   * @param {*} postendpoint - endpoint for post request
   * @param {*} putendpoint - endpoint for patch request
   * @param {*} hermes - payload
   * @param {*} schema - formdata name
   *    schemaData : formdata field list
   *    schemaId : record id
   *    schemaEdit : readonly view Toggle
   * @param {*} tab - form tab to route back after succesful save
   * @param {*} state - state object of the form
   * @param {*} t - localization
   */

  putPost: async (postendpoint, putendpoint, hermes, schema, tab, state, t) => {
    if (state.value[`${schema}Id`] != 0) {
      try {
        await axios
          .put(putendpoint, hermes, {
            onTokenRefreshed: () => {
              this.putPost(
                postendpoint,
                putendpoint,
                hermes,
                schema,
                tab,
                state,
                t
              );
            },
          })
          .then((resp) => {
            if (resp && resp.status == 200) {
              toast.success(t("message.successfulSave"));
              state.value[`${schema}Data`].forEach((e) => {
                e.value = "";
              });

              state.value.tab = tab;
              store.commit("setRemTab", tab);
              state.value[`${schema}Id`] = 0;
              state.value[`${schema}Edit`] = false;
            } else {
              toast.error(t("message.notSuccessfulSave"));
            }
          });
      } catch (e) {
        toast.error(t("message.notSuccessfulSave"));
      }
    } else {
      try {
        await axios
          .post(postendpoint, hermes, {
            onTokenRefreshed: () => {
              this.putPost(
                postendpoint,
                putendpoint,
                hermes,
                schema,
                tab,
                state,
                t
              );
            },
          })
          .then((resp) => {
            if (resp && resp.status == 201) {
              toast.success(t("message.successfulSave"));
              state.value[`${schema}Data`].forEach((e) => {
                e.value = "";
              });

              state.value.tab = tab;
              store.commit("setRemTab", tab);
              state.value[`${schema}Id`] = 0;
              state.value[`${schema}Edit`] = false;
            } else {
              toast.error(t("message.notSuccessfulSave"));
            }
          });
      } catch (e) {
        toast.error(t("message.notSuccessfulSave"));
      }
    }
  },
  async remDelete(state, endpoint, formdata, tab, t) {
    try {
      await axios
        .delete(endpoint, {
          onTokenRefreshed: () => {
            this.deleteUniversal(state, endpoint, formdata, tab, t);
          },
        })
        .then((resp) => {
          if (resp && resp.status == 204) {
            toast.info(t("message.deleteSuccessful"));
            state.value.tab = tab;
            store.commit("setRemTab", tab);
            state.value[`${formdata}Id`] = 0;
            store.commit("setRemFormEdit", false);
          } else {
            toast.info(t("message.deleteNotSuccessful"));
          }
        });
    } catch (e) {
      toast.info(t("message.deleteNotSuccessful"));
    }
  },
  remGetOptions: (source, target, isThereWeirdFormattingForOption) => {
    target.forEach((e) => {
      if (e.type == "label") {
        return;
      }

      if (e.type == "option" && isThereWeirdFormattingForOption == true) {
        if (source[e.model + "Id"]) {
          e.value = source[e.model + "Id"];
          e.valueLabel = source[e.model + "Value"];
        }
      } else if (e.type == "checkbox" && source[e.model] == null) {
        e.value = "";
      } else {
        e.value = source[e.model];
      }
    });
  },

  clearData (vArray) {
    vArray.forEach((element) => {
      element.value = "";
    });
  },

  setTab (state, tab) {
    if (
      state.value.tabsToConfirm[store.getters.getRemTab] &&
      store.getters.getRemFormEdit == true
    ) {
      state.value.confirmation.confirmationTextToken = "saveConfirmation";

      state.value.confirmation.confirmationTrueMethod = () => {
        state.value.confirmation.showConfirmation = false;
        state.value.tabsToConfirm[store.getters.getRemTab].saveMethod();
        setTimeout(() => {
          store.commit("setRemTab", tab);
        }, 500);
      };

      state.value.confirmation.confirmationFalseMethod = () => {
        store.commit("setRemTab", tab);
        state.value.confirmation.showConfirmation = false;
      };

      state.value.confirmation.showConfirmation = true;
    } else {
      store.commit("setRemTab", tab);
    }
  },
  addNewTab(name, tab, state) {
    //console.log(this);

    this.setTab(state, tab);
    state.value[`${name}Id`] = 0;
    store.commit("setRemFormEdit", true);
    //state.value[`${name}Edit`] = true;
    this.clearData(state.value[`${name}Data`])
    
  },

  deleteConfirmation: (state, endpoint, formData, tab, t) => {
    state.value.confirmation.confirmationTextToken = "deleteConfirm";
    state.value.confirmation.confirmationTrueMethod = async () => {
      try {
        await axios
          .delete(endpoint, {
            onTokenRefreshed: () => {
              this.deleteUniversal(state, endpoint, formData, tab, t);
            },
          })
          .then((resp) => {
            if (resp && resp.status == 204) {
              toast.info(t("message.deleteSuccessful"));
              state.value.tab = tab;
              store.commit("setRemTab", tab);
              state.value[`${formData}Id`] = 0;
              store.commit("setRemFormEdit", false);
            } else {
              toast.info(t("message.deleteNotSuccessful"));
            }
          });
      } catch (e) {
        toast.info(t("message.deleteNotSuccessful"));
      }
      state.value.confirmation.showConfirmation = false;
    };
    state.value.confirmation.confirmationFalseMethod = () => {
      state.value.confirmation.showConfirmation = false;
    };
    state.value.confirmation.showConfirmation = true;
  },
};

import TenantConfigHandler from '@/misc/TenantConfigHandler';

export default {
  data: [
    {
      title: "floorSpace",
      value: "",
      model: "floorSpace",
      type: "number",
    },
    {
      title: "legalRelationshipUtilization",
      value: "",
      model: "legalRelationship",
      type: "option",
      optionValue: "UTILIZATION_LEGAL_RELATIONSHIP",
    },
    {
      title: "legalRelationshipStart",
      value: "",
      model: "legalRelationshipStart",
      type: "date",
    },
    {
      title: "legalRelationshipEnd",
      value: "",
      model: "legalRelationshipEnd",
      type: "date",
    },
    {
      title: "partner",
      value: "",
      model: "partnerId",
      type: "optionapi",
      endPoint: TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/partner",
      displayValue: "name",
    },
  ]
}
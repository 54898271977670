
import { PublicClientApplication } from '@azure/msal-browser';
import TenantConfigHandler from './TenantConfigHandler';
//import User from './helper/User';

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: TenantConfigHandler.getVariable('AZURE_CLIENT_ID'),
    authority: TenantConfigHandler.getVariable('MSAL_AUTHORITY'),
    redirectUri: TenantConfigHandler.getVariable('AZURE_REDIRECT_URI'),
    postLogoutRedirectUri: TenantConfigHandler.getVariable('AZURE_REDIRECT_URI')
  }
});

export default msalInstance;
export default {
  data: [
    {
      title: "floors",
      value: "",
      model: "floor",
      type: "number",
    },
    {
      title: "notes",
      value: "",
      model: "entryNote",
      type: "text",
      validationMethod: (
        options,
        optionsRepo,
        value,
        newValue,
        oldValue,
        inputValue,
        inputError
      ) => {
        if (newValue.length > 2000) {
          inputError.value = "message.entryNoteWarning";
        } else {
          inputError.value = "";
        }
      },
    },
    {
      title: "szepaClass",
      value: "",
      model: "szepa",
      type: "option",
      optionValue: "SZEPA_CLASSIFICATION"
    },
    {
      title: "floorCovering",
      value: "",
      model: "floorCovering",
      type: "option",
      optionValue: "FLOOR_COVERING",
    },
    {
      title: "isFalseFloor",
      value: "",
      model: "falseFloor",
      type: "checkbox",
    },
    {
      title: "isFalseCeiling",
      value: "",
      model: "falseCeiling",
      type: "checkbox",
    },
    {
      title: "personnel",
      type: "label",
    },
    {
      title: "numberDedicated",
      value: "",
      model: "numberDedicated",
      type: "number",
      helperText: "numberDedicatedHelperText"
    },
    {
      title: "numberCanBeSeated",
      value: "",
      model: "numberCanBeSeated",
      type: "number",
      helperText: "numberCanBeSeatedHelperText"
    },
    {
      title: "numberSeated",
      value: "",
      model: "numberSeated",
      type: "number",
      helperText: "numberSeatedHelperText"
    },
    {
      title: "fireProtection",
      type: "label",
    },
    {
      title: "fireProtectionClass",
      value: "",
      model: "fireProtectionClass",
      type: "option",
      optionValue: "FIRE_PROTECTION_CLASSIFICATION",
    },
    {
      title: "builtInFireProtection",
      value: "",
      model: "builtInFireProtection",
      type: "option",
      optionValue: "BUILT_IN_FIRE_PROTECTION",
    },

    {
      title: "fenestration",
      type: "label",
    },
    {
      title: "doorType",
      value: "",
      model: "doorType",
      type: "option",
      optionValue: "DOOR_TYPE",
    },
    {
      title: "windowNumber",
      value: "",
      model: "numberOfWindows",
      type: "number",
    },
    {
      title: "openableWindow",
      value: "",
      model: "openableWindow",
      type: "checkbox",
    },
    {
      title: "windowMaterial",
      value: "",
      model: "windowMaterial",
      type: "option",
      optionValue: "WINDOW_MATERIAL",
    },
    {
      title: "windowWidth",
      value: "",
      model: "windowWidth",
      type: "text",
    },
    {
      title: "windowHeight",
      value: "",
      model: "windowHeight",
      type: "number",
    },
    {
      title: "windowDepth",
      value: "",
      model: "windowDepth",
      type: "number",
    },
    {
      title: "technicalData",
      type: "label",
    },
    {
      title: "minHeight",
      value: "",
      model: "minHeight",
      type: "text",
    },
    {
      title: "maxHeight",
      value: "",
      model: "maxHeight",
      type: "text",
    },
    {
      title: "sharedUse",
      value: "",
      model: "sharedUse",
      type: "checkbox",
    },
    {
      title: "dedicatedOrgUnit",
      value: "",
      model: "dedicatedOrgUnit",
      type: "checkbox",
    },
    {
      title: "canBeCooled",
      value: "",
      model: "canBeCooled",
      type: "checkbox",
    },
    {
      title: "canBeHeated",
      value: "",
      model: "canBeHeated",
      type: "checkbox",
    },
  ]
}
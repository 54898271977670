<template>
  <div id="BuildingPage" class="h-screen">
    <BaseLayout>
      <template #mainContent>
        <div class="h-fit px-2 overflow-y-auto mx-30 k">
          <!--Header-->
          <header class="w-full">
            <div class="items-start pb-6 mx-auto md:flex-row mb-4">
              <div class="flex justify-between mb-4">
                <div class="flex flex-row">
                  <h1 class="flex mb text-xl text-secondary md:mb-0 uppercase font-bold">
                    {{ $t("message.building") }} -
                    {{
                    state.building?.siteDesignName
                    ? state.building.siteDesignName
                    : "N/A"
                    }}
                    ({{
                    state.property
                    ? state.property.primaryAddressPostalCode + ' ' + state.property.primaryAddressCity + ', ' +
                    state.property.primaryAddressPublicAreaName + ' ' + state.property.primaryAddressPublicAreaTypeValue
                    + ' '
                    + state.property.primaryAddressHouseNumber + '.'
                    : ""
                    }})
                  </h1>
                  <span @click="toLevelSelector">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke-width="1.5"
                      stroke="currentColor"
                      class="w-8 h-8 pl-2 pb-1 text-magenta hover:text-gray-200 hover:cursor-pointer">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
                    </svg>
                  </span>
                </div>

                <RemUpButton remType="building" />
              </div>
              <hr class="mb-8 mt-2" />
              <div class="flex">
                <button @click="router.push({ name: 'energeticsPage' })"
                  class="flex btn btn-md btn-secondary normal-case text-sm im-round-btn">
                  {{ $t("message.energetics") }}
                </button>
              </div>
            </div>
          </header>

          <div class="flex md:flex-row md:columns-2 flex-col w-full">
            <!--SIDEMENU-->
            <div
              class="flex flex-row md:flex-col im-sidemenu-contanier rounded-xl mb-4 md:mb-0 flex-wrap md:flex-nowrap w-full md:md:w-auto justify-center">
              <div class="flex-row mb-4">
                <SideMenuButton :onClick="() => { state.tab = 1; }" :activeCheck="state.tab == 1" :icon="'premise_icon'"
                  :buttonLabel="'premises'"></SideMenuButton>
              </div>

              <div class="flex-row mb-4">
                <SideMenuButton :onClick="() => { state.tab = 2; }" :activeCheck="state.tab == 2" :icon="'legal_icon'"
                  :buttonLabel="'legalSituations'"></SideMenuButton>
              </div>

              <div class="flex-row mb-4">
                <SideMenuButton :onClick="() => { state.tab = 3; }" :activeCheck="state.tab == 3"
                  :icon="'system_settings_icon'" :buttonLabel="'utilizations'"></SideMenuButton>
              </div>

              <div class="flex-row mb-4">
                <SideMenuButton :onClick="() => { state.tab = 4; }" :activeCheck="state.tab == 4" :icon="'meter_icon'"
                  :buttonLabel="'meters'"></SideMenuButton>
              </div>

              <div class="flex-row mb-4">
                <SideMenuButton :onClick="() => { state.tab = 5; }" :activeCheck="state.tab == 5" :icon="'data_icon'"
                  :buttonLabel="'dataAndMtResp'"></SideMenuButton>
              </div>
              <div class="flex-row mb-4">
                <SideMenuButton :onClick="() => { state.tab = 7; }" :activeCheck="state.tab == 7"
                  :icon="'document_icon'" :buttonLabel="'documents'"></SideMenuButton>
              </div>
              <div class="flex-row mb-4">
                <SideMenuButton :onClick="() => { state.tab = 8; }" :activeCheck="state.tab == 8"
                  :icon="'struct_types_icon'" :buttonLabel="'structureTypes'"></SideMenuButton>
              </div>
              <div class="flex-row mb-4">
                <SideMenuButton :onClick="() => { state.tab = 13; }" :activeCheck="state.tab == 13"
                  :icon="'partner_icon'" :buttonLabel="'partners'"></SideMenuButton>
              </div>
            </div>
            <TransitionGroup name="fade">
              <!--LISTING PREMISES-->
              <div v-if="state.tab == 1" class="flex flex-col im-main-card rounded-xl im-main-card-starter">
                <div>
                  <h2 class="text-xl text-secondary w-full font-bold mb-8">
                    {{ $t("message.premises") }}
                  </h2>
                  <button @click="
                    state.tab = 10;
                  state.premiseId = 0;
                  clearData(state.premiseData);
                  " class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
                    {{ $t("message.addNew") }}
                  </button>
                  <hr class="mb-8" />
                  <div class="im-card-form">
                    <RobberVueGrid ref="premiseGrid" :config="premiseGridConfigComputed" />
                  </div>
                </div>
              </div>
              <!--Alapadatok és MT felelősök-->
              <div v-if="state.tab == 5" class="flex flex-col im-main-card rounded-xl im-main-card-starter">
                <div class="im-card-form">
                  <div v-for="item in state.basicData" v-bind:key="item.model" class="mb-8">
                    <div v-if="state.basicDataEdit == false">
                      <ImFormLabel :item="item"></ImFormLabel>
                    </div>
                    <div v-if="state.basicDataEdit == true">
                      <!-- eslint-disable -->
                      <ImFormInput v-model:inputValue="item.value" v-model:parent="item.parent"
                        :title="$t('message.' + item.title)" :type="item.type" :valueLabel="item.valueLabel"
                        :optionValue="item.optionValue" :endPoint="item.endPoint" :manualOptions="item.manualOptions"
                        :displayValue="item.endPoint" :searchable="item.searchable"
                        :onchangeMethod="item.onchangeMethod" :validationMethod="item.validationMethod"
                        :helperText="item.helperText ? $t('message.' + item.helperText) : null">
                      </ImFormInput>
                      <!-- eslint-enable -->
                    </div>
                  </div>

                  <button v-if="state.basicDataEdit == true" @click="saveBuilding"
                    class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                    $t("message.save") }}</button>
                  <button v-if="state.basicDataEdit == false" @click="state.basicDataEdit = true"
                    class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                    $t("message.edit") }}</button>

                </div>
              </div>
              <!--Dokumentumok-->
              <div v-if="state.tab == 7" class="flex flex-col im-main-card rounded-xl im-main-card-starter">
                <PropertyLists title="documents" :building="state.building" />
              </div>
              <!--STRUCTURE TYPES-->
              <div v-if="state.tab == 8" class="flex flex-col im-main-card rounded-xl im-main-card-starter">
                <div class="im-card-form">
                  <div v-for="item in state.structureTypesData" v-bind:key="item.model" class="mb-8">
                    <div v-if="state.structEdit == false">
                      <ImFormLabel :item="item"></ImFormLabel>
                    </div>
                    <div v-if="state.structEdit == true">
                      <!-- eslint-disable -->
                      <ImFormInput v-model:inputValue="item.value" v-model:parent="item.parent"
                        :title="$t('message.' + item.title)" :type="item.type" :valueLabel="item.valueLabel"
                        :optionValue="item.optionValue" :endPoint="item.endPoint" :manualOptions="item.manualOptions"
                        :displayValue="item.endPoint" :searchable="item.searchable"
                        :onchangeMethod="item.onchangeMethod" :validationMethod="item.validationMethod"
                        :helperText="item.helperText ? $t('message.' + item.helperText) : null">
                      </ImFormInput>
                      <!-- eslint-enable -->
                    </div>
                  </div>

                  <button v-if="state.structEdit == true" @click="saveBuilding"
                    class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                    $t("message.save") }}</button>
                  <button v-if="state.structEdit == false" @click="state.structEdit = true"
                    class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                    $t("message.edit") }}</button>

                </div>

              </div>
              <!--Jogi helyzet-->
              <div class="flex flex-col im-main-card rounded-xl im-main-card-starter" v-if="state.tab == 2">
                <div>
                  <h2 class="text-xl text-secondary w-full font-bold mb-8">
                    {{ $t("message.legalSituations") }}
                  </h2>
                  <button @click="
                    state.tab = 9;
                  state.legalsitId = 0;
                  state.legalsitEdit = true;
                  clearData(state.legalsitData);
                  " class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
                    {{ $t("message.addNew") }}
                  </button>
                  <hr class="mb-8" />
                  <div class="im-card-form">
                    <RobberVueGrid ref="legalSituationsGrid" :config="legalSituationsGridConfigComputed" />
                  </div>
                </div>
              </div>

              <!--ADD/EDIT PREMISE-->
              <div class="flex flex-col im-main-card rounded-xl im-main-card-starter" v-if="state.tab == 10">
                <div>
                  <button class="btn btn-secondary btn-sm btn-circle mb-8"
                    @click="setConfirmation('saveConfirmation', 1, savePremise)">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                    </svg>
                  </button>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    {{ state.premiseId ? `${$t("message.editPremise")}` : `${$t("message.new")}
                    ${$t("message.premise")}` }}
                  </h2>
                  <div class="im-card-form">
                    <div v-for="item in state.premiseData" v-bind:key="item.model" class="mb-8">
                      <!-- eslint-disable -->

                      <ImFormInput v-model:inputValue="item.value" v-model:parent="item.parent"
                        :title="$t('message.' + item.title)" :type="item.type" :valueLabel="item.valueLabel"
                        :optionValue="item.optionValue" :endPoint="item.endPoint" :displayValue="item.displayValue"
                        :searchable="item.searchable" :validationMethod="item.validationMethod"
                        :onchangeMethod="item.onchangeMethod"
                        :helperText="item.helperText ? $t('message.' + item.helperText) : null">
                      </ImFormInput>
                      <!-- eslint-enable -->
                    </div>


                    <div v-if="state.isStaire">
                      <h2 class="text-xl text-secondary w-full font-bold">
                        {{ $t("message.stairway") }}
                      </h2>
                      <hr class="mb-8" />
                      <div v-for="item in state.staircaseData" v-bind:key="item.model" class="mb-8">
                        <!-- eslint-disable -->

                        <ImFormInput v-model:inputValue="item.value" v-model:parent="item.parent"
                          :title="$t('message.' + item.title)" :type="item.type" :valueLabel="item.valueLabel"
                          :optionValue="item.optionValue" :endPoint="item.endPoint" :displayValue="item.displayValue"
                          :searchable="item.searchable" :validationMethod="item.validationMethod"
                          :onchangeMethod="item.onchangeMethod"
                          :helperText="item.helperText ? $t('message.' + item.helperText) : null">
                        </ImFormInput>
                        <!-- eslint-enable -->
                      </div>
                    </div>

                    <div v-if="state.isElevator">
                      <h2 class="text-xl text-secondary w-full font-bold">
                        {{ $t("message.elevator") }}
                      </h2>
                      <hr class="mb-8" />
                      <div v-for="item in state.elevatorData" v-bind:key="item.model" class="mb-8">
                        <!-- eslint-disable -->

                        <ImFormInput v-model:inputValue="item.value" v-model:parent="item.parent"
                          :title="$t('message.' + item.title)" :type="item.type" :valueLabel="item.valueLabel"
                          :optionValue="item.optionValue" :endPoint="item.endPoint" :displayValue="item.displayValue"
                          :searchable="item.searchable" :validationMethod="item.validationMethod"
                          :onchangeMethod="item.onchangeMethod"
                          :helperText="item.helperText ? $t('message.' + item.helperText) : null">
                        </ImFormInput>
                        <!-- eslint-enable -->
                      </div>
                    </div>

                    <div class="flex flex-row justify-between">
                      <button @click="savePremise" class="btn btn-md btn-secondary normal-case text-sm im-round-btn">
                        {{ $t("message.save") }}
                      </button>
                      <button v-if="state.premiseEdit && User.hasPermission('VocabularyAdmin')"
                        @click="setConfirmation('confirmRemovePremise', 1, deletePremise)"
                        class="btn btn-md btn-error hover:bg-opacity-50 hover:border-none normal-case text-sm im-round-btn">
                        {{ $t("message.delete") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!--ADD/EDIT LEGAL SITUATION-->
              <div class="flex flex-col im-main-card rounded-xl im-main-card-starter" v-if="state.tab == 9">
                <div>
                  <button class="btn btn-secondary btn-sm btn-circle mb-8"
                    @click="setConfirmation('saveConfirmation', 2, saveLegalsit)">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                    </svg>
                  </button>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    {{ $t("message.legalSituation") }}
                  </h2>
                  <div class="im-card-form">
                    <div v-for="item in state.legalsitData" v-bind:key="item.model" class="mb-8">
                      <div v-if="state.legalsitEdit == false">
                        <ImFormLabel :item="item"></ImFormLabel>
                      </div>
                      <div v-if="state.legalsitEdit == true">
                        <!-- eslint-disable -->
                        <ImFormInput v-model:inputValue="item.value" v-model:parent="item.parent"
                          :title="$t('message.' + item.title)" :type="item.type" :valueLabel="item.valueLabel"
                          :optionValue="item.optionValue" :endPoint="item.endPoint" :manualOptions="item.manualOptions"
                          :displayValue="item.endPoint" :searchable="item.searchable"
                          :onchangeMethod="item.onchangeMethod" :validationMethod="item.validationMethod"
                          :helperText="item.helperText ? $t('message.' + item.helperText) : null">
                        </ImFormInput>
                        <!-- eslint-enable -->
                      </div>
                    </div>

                    <div v-if="state.legalsitEdit" class="flex flex-row justify-between">
                      <button @click="saveLegalsit" class="btn btn-md btn-secondary normal-case text-sm im-round-btn">
                        {{ $t("message.save") }}
                      </button>
                      <button v-if="state.legalsitEdit && User.hasPermission('VocabularyAdmin')"
                        @click="setConfirmation('deleteLegalSituationConfirm', 2, deleteLegalsit)"
                        class="btn btn-md btn-error hover:bg-opacity-50 hover:border-none normal-case text-sm im-round-btn">
                        {{ $t("message.delete") }}
                      </button>
                    </div>
                    <button v-if="state.legalsitEdit == false" @click="state.legalsitEdit = true"
                      class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                      $t("message.edit") }}</button>

                  </div>
                </div>
              </div>

              <!--UTILIZATIONS-->
              <div v-if="state.tab == 3" class="flex flex-col im-main-card rounded-xl im-main-card-starter">
                <div>
                  <h2 class="text-xl text-secondary w-full font-bold mb-8">
                    {{ $t("message.utilizations") }}
                  </h2>
                  <button @click="
                    state.tab = 11;
                  state.utilizationId = 0;
                  state.utilizationEdit = true;
                  clearData(state.utilizationData);
                  " class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
                    {{ $t("message.addNew") }}
                  </button>
                  <hr class="mb-8" />
                  <div class="im-card-form">
                    <RobberVueGrid ref="utilizationGrid" :config="utilizationGridConfigComputed" />
                  </div>
                </div>
              </div>

              <!--ADD/EDIT UTILIZATION-->
              <div class="flex flex-col im-main-card rounded-xl im-main-card-starter" v-if="state.tab == 11">
                <div>
                  <button class="btn btn-secondary btn-sm btn-circle mb-8"
                    @click="setConfirmation('saveConfirmation', 3, saveUtilization)">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                    </svg>
                  </button>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    {{ $t("message.editUtilization") }}
                  </h2>
                  <div class="im-card-form">
                    <div v-for="item in state.utilizationData" v-bind:key="item.model" class="mb-8">
                      <div v-if="state.utilizationEdit == false">
                        <ImFormLabel :item="item"></ImFormLabel>
                      </div>
                      <div v-if="state.utilizationEdit == true">
                        <!-- eslint-disable -->
                        <ImFormInput v-model:inputValue="item.value" v-model:parent="item.parent"
                          :title="$t('message.' + item.title)" :type="item.type" :valueLabel="item.valueLabel"
                          :optionValue="item.optionValue" :endPoint="item.endPoint" :manualOptions="item.manualOptions"
                          :displayValue="item.displayValue" :searchable="item.searchable"
                          :onchangeMethod="item.onchangeMethod" :validationMethod="item.validationMethod"
                          :helperText="item.helperText ? $t('message.' + item.helperText) : null"
                          :mandatory="item.mandatory" :validated="item.validated"
                          :optionSearchEndpoint="item.optionSearchEndpoint">
                        </ImFormInput>
                        <!-- eslint-enable -->
                      </div>
                    </div>

                    <div v-if="state.utilizationEdit" class="flex flex-row justify-between">
                      <button @click="saveUtilization"
                        class="btn btn-md btn-secondary normal-case text-sm im-round-btn">
                        {{ $t("message.save") }}
                      </button>
                      <button v-if="state.utilizationEdit && User.hasPermission('VocabularyAdmin')"
                        @click="setConfirmation('utilizationDeleteConfirm', 3, deleteUtilization)"
                        class="btn btn-md btn-error hover:bg-opacity-50 hover:border-none normal-case text-sm im-round-btn">
                        {{ $t("message.delete") }}
                      </button>
                    </div>
                    <button v-if="state.utilizationEdit == false" @click="state.utilizationEdit = true"
                      class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                      $t("message.edit") }}</button>
                  </div>
                </div>
              </div>

              <!--METERS-->
              <div v-if="state.tab == 4" class="flex flex-col im-main-card rounded-xl im-main-card-starter">
                <div>
                  <h2 class="text-xl text-secondary w-full font-bold mb-8">
                    {{ $t("message.meters") }}
                  </h2>
                  <button @click="
                    state.tab = 12;
                  state.meterId = 0;
                  state.meterEdit = true;
                  clearData(state.meterData);
                  " class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
                    {{ $t("message.addNew") }}
                  </button>
                  <hr class="mb-8" />
                  <div class="im-card-form">
                    <RobberVueGrid ref="meterGrid" :config="meterGridConfigComputed" />
                  </div>
                </div>
              </div>

              <!--ADD/EDIT METER-->
              <div class="flex flex-col im-main-card rounded-xl im-main-card-starter" v-if="state.tab == 12">
                <div>
                  <button class="btn btn-secondary btn-sm btn-circle mb-8"
                    @click="setConfirmation('saveConfirmation', 4, saveMeter)">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                    </svg>
                  </button>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    {{ $t("message.editMeter") }}
                  </h2>
                  <div class="im-card-form">
                    <div v-for="item in state.meterData" v-bind:key="item.model" class="mb-8">
                      <div v-if="state.meterEdit == false">
                        <ImFormLabel :item="item"></ImFormLabel>
                      </div>
                      <div v-if="state.meterEdit == true">
                        <!-- eslint-disable -->
                        <ImFormInput v-model:inputValue="item.value" v-model:parent="item.parent"
                          :title="$t('message.' + item.title)" :type="item.type" :valueLabel="item.valueLabel"
                          :optionValue="item.optionValue" :endPoint="item.endPoint" :manualOptions="item.manualOptions"
                          :displayValue="item.displayValue" :searchable="item.searchable"
                          :onchangeMethod="item.onchangeMethod" :validationMethod="item.validationMethod"
                          :helperText="item.helperText ? $t('message.' + item.helperText) : null"
                          :mandatory="item.mandatory" :validated="item.validated"
                          :optionSearchEndpoint="item.optionSearchEndpoint">
                        </ImFormInput>
                        <!-- eslint-enable -->
                      </div>
                    </div>

                    <div v-if="state.meterEdit" class="flex flex-row justify-between">
                      <button @click="saveMeter" class="btn btn-md btn-secondary normal-case text-sm im-round-btn">
                        {{ $t("message.save") }}
                      </button>
                      <button v-if="state.meterEdit && User.hasPermission('VocabularyAdmin')"
                        @click="setConfirmation('deleteMeterConfirm', 4, deleteMeter)"
                        class="btn btn-md btn-error hover:bg-opacity-50 hover:border-none normal-case text-sm im-round-btn">
                        {{ $t("message.delete") }}
                      </button>
                    </div>
                    <button v-if="state.meterEdit == false" @click="state.meterEdit = true"
                      class="btn btn-md btn-secondary  normal-case text-sm im-round-btn">{{
                      $t("message.edit") }}</button>
                  </div>
                </div>
              </div>

              <!--PARTNERS-->
              <div v-if="state.tab == 13" class="flex flex-col im-main-card rounded-xl im-main-card-starter">
                <div>
                  <h2 class="text-xl text-secondary w-full font-bold mb-8">
                    {{ $t("message.partners") }}
                  </h2>
                  <div class="flex flex-row justify-between">
                    <button @click="
                    state.tab = 14;
                    state.partnerId = 0;
                    clearData(state.partnerData);
                    " class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
                      {{ $t("message.addNew") }}
                    </button>
                    <button @click="state.partnerRvgType = !state.partnerRvgType"
                      class="btn btn-md btn-secondary normal-case text-sm im-round-btn mb-8">
                      <SVGRenderer svgKey="partner_mode_switch_icon" class="tooltip tooltip-left tooltip-hover"
                        :data-tip="$t('message.buildingPartnerModeSwitch')"></SVGRenderer>
                    </button>
                  </div>

                  <hr class="mb-8" />
                  <h2 class="text-xl text-secondary w-full font-bold">
                    <span v-if="!state.partnerRvgType">{{ $t("message.buildingPartners") }}</span>
                    <span v-if="state.partnerRvgType">{{ $t("message.allRelatedPartner") }}</span>
                  </h2>
                  <div class="im-card-form">
                    <RobberVueGrid v-if="!state.partnerRvgType" ref="partnersGrid"
                      :config="partnersGridConfigComputed" />
                    <RobberVueGrid v-if="state.partnerRvgType" ref="partnersGrid"
                      :config="allRelatedPartnersGridConfigComputed" />
                  </div>
                </div>
              </div>
              <!--ADD/EDIT PARTNER-->
              <div class="flex flex-col im-main-card rounded-xl im-main-card-starter" v-if="state.tab == 14">
                <div>
                  <button class="btn btn-secondary btn-sm btn-circle mb-8"
                    @click="setConfirmation('saveConfirmation', 13, savePartner)">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                      stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                    </svg>
                  </button>
                  <h2 class="text-xl text-secondary w-full font-bold">
                    {{ state.partnerData[0].value }}
                  </h2>
                  <div class="im-card-form">
                    <div v-for="item in state.partnerData" v-bind:key="item.model" class="mb-8">
                      <div v-if="
                        state.partnerEdit == false && state.partnerId != 0
                      ">
                        <ImFormLabel v-if="
                          (item.model != 'taxId' &&
                            item.model != 'taxNumber') ||
                          (item.model == 'taxId' && state.isPerson == true) ||
                          (item.model == 'taxNumber' &&
                            state.isPerson == false)
                        " :item="item"></ImFormLabel>
                      </div>
                      <div v-if="state.partnerEdit == true || state.partnerId == 0">
                        <!-- eslint-disable -->
                        <ImFormInput v-if="
                          (item.model != 'taxId' &&
                            item.model != 'taxNumber') ||
                          (item.model == 'taxId' && state.isPerson == true) ||
                          (item.model == 'taxNumber' &&
                            state.isPerson == false)" v-model:inputValue="item.value" v-model:parent="item.parent"
                          :title="$t('message.' + item.title)" :type="item.type" :valueLabel="item.valueLabel"
                          :optionValue="item.optionValue" :endPoint="item.endPoint" :displayValue="item.displayValue"
                          :searchable="item.searchable" :onchangeMethod="item.onchangeMethod"
                          :validationMethod="item.validationMethod">
                        </ImFormInput>
                        <!-- eslint-enable -->

                      </div>
                    </div>

                    <div class="flex flex-row justify-between">
                      <button @click="savePartner" class="btn btn-md btn-secondary normal-case text-sm im-round-btn">
                        {{ $t("message.save") }}
                      </button>
                      <button v-if="state.partnerEdit && User.hasPermission('VocabularyAdmin')"
                        @click="setConfirmation('confirmRemovePartner', 13, deletePartner)"
                        class="btn btn-md btn-error hover:bg-opacity-50 hover:border-none normal-case text-sm im-round-btn">
                        {{ $t("message.delete") }}
                      </button>
                    </div>
                    <button v-if="state.partnerEdit == false && state.partnerId != 0" @click="state.partnerEdit = true"
                      class="btn btn-md btn-secondary normal-case text-sm im-round-btn">
                      {{ $t("message.edit") }}
                    </button>
                  </div>
                </div>
              </div>
            </TransitionGroup>
            <!--CONFIRMATION MODAL-->
            <input type="checkbox" class="modal-toggle" v-model="state.confirmationModal.show" />
            <div class="modal">
              <div class="modal-box">
                <h3 class="text-lg text-left font-normal hover:text-gray-100 text-gray-100">
                  {{ $t('message.' + state.confirmationModal.message) }}
                </h3>
                <div class="modal-action">
                  <label class="btn btn-sm text-white font-light text-xs" @click="() => {
                    state.confirmationModal.show = false;
                    state.tab = state.confirmationModal.tab;
                    }
                    ">{{ $t("message.no") }}</label>
                  <label class="btn btn-sm bg-magenta text-white font-light text-xs" @click="() => {
                    state.confirmationModal.show = false;
                    state.confirmationModal.function();
                    state.tab = state.confirmationModal.tab;
                    }
                    ">{{ $t("message.yes") }}</label>
                </div>
              </div>
            </div>
          </div>
          <div style="height: 10rem"></div>
        </div>
      </template>
    </BaseLayout>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { computedAsync } from '@vueuse/core'
import RVGUtils from "../../components/RVG/RobberVueGridUtils.js";
import BaseLayout from "../../layouts/BaseLayout.vue";
import PropertyLists from "../../components/REM/RemLists.vue";
import SideMenuButton from "../../components/REM/SideMenuButton.vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import router from "@/misc/router.js";
import Utils from "../../misc/Utils";
import User from "../../misc/User.js";
import store from "@/misc/vuex-store.js";
import RemUpButton from "../../components/REM/RemUpButton.vue";
import SVGRenderer from "../../components/SVGRenderer.vue";
import formdata from "./FormData.js";
import legalSituationsGridConfig from "@/misc/gridConfigs/buildingPage/legalSituationsGridConfig.js";
import premiseGridConfig from "@/misc/gridConfigs/buildingPage/premiseGridConfig.js";
import RobberVueGrid from "../../components/RVG/RobberVueGrid.vue";
import ImFormLabel from "../../components/ImFormLabel.vue";
import ImFormInput from "../../components/ImFormInput.vue";
import buildingUtils from "./buildingUtils.js";
import utilizationsGridConfig from "../../misc/gridConfigs/buildingPage/utilizationsGridConfig.js";
import meterGridConfig from "@/misc/gridConfigs/buildingPage/metersGridConfig.js";
import partnersGridConfig from "../../misc/gridConfigs/buildingPage/partnersGridConfig.js";
import allRelatedPartnersGridConfig from "../../misc/gridConfigs/buildingPage/allRelatedPartnersGridConfig.js";


const state = ref({
  isElevator: false,
  isStaire: false,
  staircaseData: formdata.staircaseData,
  elevatorData: formdata.elevatorData,
  tab: 1,
  building: null,
  property: null,
  legalsitData: formdata.legalsitData,
  legalsitId: 0,
  legalsitEdit: false,
  showConfirmation: false,
  premiseData: formdata.premiseData,
  premiseId: 0,
  premiseEdit: false,
  utilizationData: formdata.utilizationData,
  utilizationId: 0,
  utilizationEdit: false,
  utilizationMainValidation: false,
  meterData: formdata.meterData,
  meterId: 0,
  meterEdit: false,
  structureTypesData: formdata.buildingData,
  basicData: formdata.buildingBasicData,
  structEdit: false,
  basicDataEdit: false,
  partnerData: formdata.partnerData,
  partnerId: 0,
  isPerson: false,
  partnerEdit: false,
  partnerRvgType: false,
  confirmationModal: {
    show: false,
    message: "",
    tab: "",
    function: "",
  }
});

// const language = computed(() => store.getters.getLanguage);
const premiseGrid = ref()
const meterGrid = ref()
const utilizationGrid = ref()
const legalSituationsGrid = ref()
const partnersGrid = ref()

const { t } = useI18n();
const route = useRoute();
const buildingId = route.params.buildingId;
const propertyId = route.params.propertyId;

buildingUtils.asyncGet(state, buildingId, formdata);

//PARTNERS
const partnersGridConfigComputed = computedAsync(async() => {
  const [
    partnerMainTypeMapping, 
    partnerSubTypeMapping
    ] = await Promise.all([
    RVGUtils.getFormatterMappingForOptionValue("PARTNER_MAIN_TYPE"),
    RVGUtils.getFormatterMappingForOptionValue("PARTNER_SUB_TYPE"),
  ])
  return partnersGridConfig(state, t, buildingId, partnerMainTypeMapping, partnerSubTypeMapping);
}, partnersGridConfig(state, t, buildingId));

const allRelatedPartnersGridConfigComputed = computedAsync(async () => {
  const [
    partnerMainTypeMapping,
    partnerSubTypeMapping
  ] = await Promise.all([
    RVGUtils.getFormatterMappingForOptionValue("PARTNER_MAIN_TYPE"),
    RVGUtils.getFormatterMappingForOptionValue("PARTNER_SUB_TYPE"),
  ])
  return allRelatedPartnersGridConfig(state, t,buildingId, partnerMainTypeMapping, partnerSubTypeMapping);
}, allRelatedPartnersGridConfig(state, t, buildingId));

const savePartner = () => {
  buildingUtils.savePartner(state, t, buildingId);
  partnersGrid?.value.refresh()
};

const deletePartner = () => {
  buildingUtils.deletePartner(state, state.value.partnerId, t);
  partnersGrid?.value.refresh()
};

const meterGridConfigComputed = computedAsync(async() => {
  const [
    meterTypeMapping,
    utilityTypeMapping, 
    partnerMainTypeMapping,
    partnerSubTypeMapping,
    readingFrequencyMapping,
    commTypeMapping
    ] = await Promise.all([
    RVGUtils.getFormatterMappingForOptionValue("METER_TYPE"),
    RVGUtils.getFormatterMappingForOptionValue("METER_UTILITY_TYPE"),
    RVGUtils.getFormatterMappingForOptionValue("PARTNER_MAIN_TYPE"),
    RVGUtils.getFormatterMappingForOptionValue("PARTNER_SUB_TYPE"),
    RVGUtils.getFormatterMappingForOptionValue("METER_READING_FREQUENCY"),
    RVGUtils.getFormatterMappingForOptionValue("METER_COMM_TYPE"),
  ])

  return meterGridConfig(
    t,
    state,
    buildingId,
    meterTypeMapping,
    utilityTypeMapping, 
    partnerMainTypeMapping,
    partnerSubTypeMapping,
    readingFrequencyMapping,
    commTypeMapping
  );
}, meterGridConfig(
    t,
    state,
    buildingId));

const utilizationGridConfigComputed = computedAsync(async() => {
  const [
    legalRelationshipMapping,
    partnerMainTypeMapping, 
    partnerSubTypeMapping
    ] = await Promise.all([
    RVGUtils.getFormatterMappingForOptionValue("UTILIZATION_LEGAL_RELATIONSHIP"),
    RVGUtils.getFormatterMappingForOptionValue("PARTNER_MAIN_TYPE"),
    RVGUtils.getFormatterMappingForOptionValue("PARTNER_SUB_TYPE"),
  ])
  return utilizationsGridConfig(t, state, buildingId, legalRelationshipMapping, partnerMainTypeMapping, partnerSubTypeMapping);
}, utilizationsGridConfig(t, state, buildingId));

const legalSituationsGridConfigComputed = computedAsync(async() => {
  const [
    legalRelationshipTypeMapping, 
    legalSituationTypeMapping
    ] = await Promise.all([
    RVGUtils.getFormatterMappingForOptionValue("LEGAL_RELATIONSHIP_TYPE"),
    RVGUtils.getFormatterMappingForOptionValue("LEGAL_SITUATION"),
  ])
  return legalSituationsGridConfig(state, t, buildingId, legalRelationshipTypeMapping, legalSituationTypeMapping);
}, legalSituationsGridConfig(state, t, buildingId));

const premiseGridConfigComputed = computedAsync(async() => {
  const [
    basicDataPremiseFunctionMapping,
    basicDataCremClassMapping, 
    basicDataStairwayTypeMapping,
    basicDataElevatorTypeMapping,
    ] = await Promise.all([
    RVGUtils.getFormatterMappingForOptionValue("PREMISE_FUNCTION"),
    RVGUtils.getFormatterMappingForOptionValue("CREM_CLASSIFICATION"),
    RVGUtils.getFormatterMappingForOptionValue("STAIRWAY_TYPE"),
    RVGUtils.getFormatterMappingForOptionValue("ELEVATOR_TYPE"),
  ])

  return premiseGridConfig(
    state,
    t,
    buildingId,
    buildingUtils.toPremise,
    basicDataPremiseFunctionMapping,
    basicDataCremClassMapping, 
    basicDataStairwayTypeMapping,
    basicDataElevatorTypeMapping,
  );
}, premiseGridConfig(
    state,
    t,
    buildingId,
    buildingUtils.toPremise));

const setConfirmation = (message, tab, func) => {

state.value.confirmationModal.message = message
state.value.confirmationModal.tab = tab
state.value.confirmationModal.function = func
state.value.confirmationModal.show = true;

};

const saveBuilding = () => {
  buildingUtils.saveBuilding(state, t, propertyId, buildingId, formdata)
}

const saveMeter = () => {
  buildingUtils.saveMeter(state, buildingId, t)
  meterGrid?.value.refresh()
}

const saveUtilization = () => {
  buildingUtils.saveUtilization(state, buildingId, t);
  utilizationGrid?.value.refresh()
};

const saveLegalsit = () => {
  buildingUtils.saveLegalsit(state, t, buildingId);
  legalSituationsGrid?.value.refresh()
};

const savePremise = () => {
  buildingUtils.savePremise(state, t, buildingId);
  premiseGrid?.value.refresh()
};

const getBuilding = async () => {
  const response = await Utils.fetchBuildingById(buildingId);
  state.value.building = response;
};

const toLevelSelector = () => {
  const siteDesign = Utils.getSiteDesignById(state.value.building.siteDesignId)

  store.commit("setSelectedSiteDesign", siteDesign);
  store.commit("setSiteDesignSidebarCurrentTab", "levels");
  store.commit("setIsFromRem", true);

  router.push({
    name: "siteDesignSelector",
    params: { siteDesignId: state.value.building.siteDesignId },
  });
};

const deletePremise = () => {
  buildingUtils.deletePremise(state, state.value.premiseId, t);
  premiseGrid?.value.refresh()
};

const deleteUtilization = () => {
  buildingUtils.deleteUtilization(state, state.value.utilizationId, t);
  utilizationGrid?.value.refresh()
};

const deleteMeter = () => {
  buildingUtils.deleteMeter(state, state.value.meterId, t);
  meterGrid?.value.refresh()
};

const deleteLegalsit = () => {
  buildingUtils.deleteLegalsit(state, state.value.legalsitId, t);
  legalSituationsGrid?.value.refresh()
};

function clearData(vArray) {
  vArray.forEach((element) => {
    element.value = "";
  });
}

const getProperty = async () => {
  const response = await Utils.fetchPropertyById(propertyId);
  state.value.property = response;
};

onMounted(() => {
  getProperty();
  getBuilding();
});


// WATCHERS

watch(() => state.value.partnerData[1].value,
  () => {
    setTimeout(() => {
      state.value.partnerData[2].parent = state.value.partnerData[1].value;
    }, 700);

  });

  watch(() => state.value.partnerData[3].value,
  () => {
    setTimeout(() => {
      state.value.isPerson =
        state.value.partnerData[3].value == "true" ? true : false;
      if (state.value.isPerson == true) {
        state.value.partnerData[5].value = "";
      } else {
        state.value.partnerData[4].value = "";
      }
    }, 700);

  });

watch(
  () => state,
  () => {
    buildingUtils.asyncGet(state, buildingId);
  }
);

watch(() => state.value.premiseData[0].value,
  () => {
    setTimeout(() => {
      state.value.isStaire = state.value.premiseData[0].value == 216;
      state.value.isElevator = state.value.premiseData[0].value == 217;
    }, 700);
  });

state.value.utilizationData.forEach((e, i) => {

  watch(() => state.value.utilizationData[i].validated,
    () => {
      let lever = true;
      state.value.utilizationData.forEach((d) => {
        if (d.validated == false) {
          lever = false
        }
      })
      state.value.utilizationMainValidation = lever
    });
})

watch(() => state.value.structureTypesData[7].value,
  () => {
    setTimeout(() => {
      state.value.structureTypesData[8].parent = state.value.structureTypesData[7].value;
    }, 700);

  });

watch(() => state.value.structureTypesData[9].value,
  () => {
    setTimeout(() => {
      state.value.structureTypesData[10].parent = state.value.structureTypesData[9].value;
    }, 700);

  });
</script>

<style scoped>
.cardBg {
  background-color: hsl(220, 17.647%, 20%);
}

.im-main-card {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
  padding: 3rem;
  max-width: 100%;
  width: 100%;
  height: fit-content;
  overflow-x: auto;
}

@media screen and (max-width: 600px) {
  .im-main-card {
    margin-bottom: 30px;
  }
}

.im-main-card-starter {
  animation: 0.5s ease-out 0s 1 scaleupY;

}

.im-main-card-starter div {
  animation: 1.5s ease-out 0s 1 fadein;
}

@keyframes scaleupY {
  0% {
    transform-origin: left top;
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.im-sidemenu-contanier {
  animation: 0.5s ease-out 0s 1 scaleupY;
  padding: 1rem;

  margin-right: 2rem;
  height: fit-content;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
}

.im-sidemenu-contanier div {
  animation: 1.5s ease-out 0s 1 fadein;
}

.im-card-form {
  padding-top: 2rem;
}

.fade-enter-active {
  transition: opacity 1s ease 1s, padding 1s ease 1s, width 1s ease 1s;
}

.fade-leave-active {
  transition: opacity 0.5s ease, padding 0.5s ease, width 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  padding: 0;
  width: 0;
  opacity: 0;
}

.im-round-btn {
  border-radius: 9999px;
}

/* button */

.btn-circle {
  transition: width 0.5s;
}

.im-sidemenu-contanier:hover .btn-circle {
  width: 15rem;
}

@keyframes fadeinbtn {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.im-btn-text {
  display: none;
}

.im-sidemenu-contanier:hover .im-btn-text {
  animation: 0.5s ease-out 0s 1 fadeinbtn;
  display: inline;
}

.im-btn-icon {
  display: inline;
}

.im-button-active {
  border-color: lighter hsl(var(--s));
}

.im-sidemenu-contanier:hover .im-btn-icon {
  display: none;
}
</style>

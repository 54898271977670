<template>
  <div class="flip-card" :class="{ flippedMargin: this.flipped }">
    <div class="flip-card-inner" :class="{ flipped: this.flipped }">
      <div class="flip-card-front">
        <slot name="defaultDisplayer"></slot>
        <CardFlipperButton @click="toggleFlipped" class="margFront" />
      </div>
      <div class="flip-card-back">
        <slot name="manualSpotInput"></slot>
        <CardFlipperButton @click="toggleFlipped" class="margBack mr-5" />
      </div>
    </div>
  </div>
</template>

<script>
import CardFlipperButton from "./CardFlipperButton.vue";

export default {
  name: "FlipCard",
  components: {
    CardFlipperButton,
  },
  data() {
    return {
      flipped: false,
    };
  },
  methods: {
    toggleFlipped() {
      this.flipped = !this.flipped;
      console.log(this.flipped);
    },
  },
};
</script>

<style scoped>
.flip-card {
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  margin-bottom: 24rem;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flipped {
  transform: rotateY(180deg);
}

.flippedMargin {
  transform-style: preserve-3d;
  transition: transform 0.8s;
  margin-bottom: 32rem;
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
}

.margFront {
  margin-top: -23.4rem;
  margin-left: 24rem;
}

.margBack {
  margin-top: -30.6rem;
  margin-left: 23.2rem;
}

.margFront,
.margBack {
  width: 5%;
}
</style>

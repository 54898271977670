import Axios from "@/misc/axios";
import toast from "@/misc/toast.js";
//import { watchEffect /*onMounted, reactive,*/ } from "vue";
import TenantConfigHandler from "@/misc/TenantConfigHandler";
//import Utils from "../../misc/Utils";
import remFormUtils from "../../misc/remFormUtils";
import store from "@/misc/vuex-store";

export default {
  // setup scripts

  asyncGet: async function (state, premiseId) {

    await Axios.get(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/premise/" +
        premiseId +
        "/special-data",
      {
        onTokenRefreshed: () => {
          this.asyncGet();
        },
      }
    ).then((resp) => {
      if (resp && resp.data) {
        if (resp.data == "") {
          state.value.coolingHeatingDeviceData.forEach((e) => {
            if (e.type == "label") {
              return;
            }
            e.value = "";
          });
        } else {
          remFormUtils.remGetOptions(
            resp.data,
            state.value.specialData,
            true
          );
        }
      }
    });

    await Axios.get(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/premise/" +
        premiseId +
        "/cooling-heating-device",
      {
        onTokenRefreshed: () => {
          this.asyncGet();
        },
      }
    ).then((resp) => {
      state.value.coolingHeatingDeviceData.forEach((e) => {
        if (e.type == "label") {
          return;
        }
        e.value = "";
        if(e.valueLabel)e.valueLabel = "";
      });
      if (resp && resp.data) {
        
        remFormUtils.remGetOptions(
            resp.data,
            state.value.coolingHeatingDeviceData,
            true
          );
        }
      
    });
  },
  

  //savers
  
  asyncSavePremiseSpecialData: async function (state, premiseId, t) {
    console.log('12');
    const hermes = {};
    state.value.specialData.forEach((e) => {
      if (e.type == "label") {
        return;
      } else {
        hermes[e.model] = e.value;
      }
    });

    //const hermes = state.value.orig.specialData;

    Axios.put(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/premise/" +
        premiseId +
        "/special-data",
      hermes,
      {
        onTokenRefreshed: () => {
          this.asyncSavePremiseSpecialData(state, premiseId, t);
        },
      }
    ).then(function (resp) {
      if (resp && resp.status == 200) {
        console.log('12');
        toast.success(t("message.successfulSave"))
        store.commit('setRemFormEdit', false)
      } else {
        toast.error(t("message.notSuccessfulSave"));
      }
    });
  },
  uploadFile: async function (state, documentFile, premiseId, t) {
    const formData = new FormData();

    formData.append("mainType", state.value.documentSetup.documentType);
    formData.append("subType", state.value.documentSetup.documentSubType);
    formData.append("document", documentFile.value.files[0]);
    formData.append("name", state.value.documentSetup.documentName);
    formData.append(
      "dateOfOrigin",
      state.value.documentSetup.documentDateOfOrigin
    );

    await Axios.post(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        `/rem/premise/${premiseId}/document`,
      formData
    ).then((resp) => {
      if (resp) {
        if (resp.status == 200) {
          toast.success(t("message.successfulSave"));
          documentFile.value = "";
          state.value.documentSetup = {
            documentName: "",
            documentDateOfOrigin: "",
            documentType: "",
            documentSubType: "",
            documentTypeLabel: "",
            documentSubTypeLabel: "",
          };
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      } else {
        toast.error(t("message.notSuccessfulSave"));
      }
    });
  },
  deleteDocument: async function (id, t) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/document/${id}`;

    try {
      await Axios.delete(uri, {
        onTokenRefreshed: () => {
          this.deleteDocument(id, t);
        },
      });
      toast.success(t("message.deleteSuccessful"));
    } catch (error) {
      toast.error(t("message.deleteNotSuccessful"));
    }
  },
  saveLegalsit: async function (state, premiseId, t) {
    const hermes = {
      legalSituationType: "",
      legalRelationshipType: "",
      ownership: "",
      legalRelationshipStart: "",
      legalRelationshipEnd: "",
      squareMeter: "",
    };

    state.value.legalsitData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    hermes.premiseId = premiseId;

    remFormUtils.putPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/legal-situation",
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/legal-situation/" +
        state.value.legalsitId,
      hermes,
      "legalsit",
      2,
      state,
      t
    );
  },
  saveMeter: async function (state, premiseId, t) {
    const hermes = {};

    state.value.meterData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    hermes.premiseId = premiseId;

    remFormUtils.putPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/meter",
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/meter/" +
        state.value.meterId,
      hermes,
      "meter",
      11,
      state,
      t
    );
  },
  saveUtilization: async function (state, premiseId, t) {
    const hermes = {
      premiseId: premiseId,
      floorSpace: "",
      legalRelationship: "",
      legalRelationshipStart: "",
      legalRelationshipEnd: "",
      partnerId: "",
    };

    state.value.utilizationData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    if (state.value.utilizationId != 0) {
      hermes.id = state.value.utilizationId;
    }

    remFormUtils.putPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/utilization",
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/utilization/" +
        state.value.utilizationId,
      hermes,
      "utilization",
      3,
      state,
      t
    );
  },
  saveCoolingHeatingDevice: async function (state, premiseId, t) {
    const hermes = {};

    state.value.coolingHeatingDeviceData.forEach((e) => {
      if (e.type == "label") {
        return;
      } else if (e.type == "checkbox" && e.value == null) {
        e.value == "";
        hermes[e.model] = "";
      } else {
        hermes[e.model] = e.value;
      }
    });

    //console.log(hermes)
    try {
      await Axios.post(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
          `/rem/premise/${premiseId}/cooling-heating-device`,
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveCoolingHeatingDevice(state, premiseId, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 200) {
            toast.success(t("message.successfulSave"));
            store.commit('setRemFormEdit', false)

            remFormUtils.remGetOptions(
              resp.data,
              state.value.coolingHeatingDeviceData,

              true
            );
          } else {
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    } catch (e) {
      toast.error(t("message.notSuccessfulSave"));
    }
  },
  savePartner: async function (state, t, id) {
    const hermes = {
      partnerMainType: 0,
      partnerSubType: 0,
      name: "",
      taxId: "",
      taxNumber: "",
    };

    state.value.partnerData.forEach((e) => {
      if(e.model != ""){
        hermes[e.model] = e.value;
      }
    });

    if (state.value.partnerId != 0) {
      hermes.id = state.value.partnerId;
    }

    remFormUtils.putPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/premise/${id}/partner`,
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/partner/" +
        state.value.partnerId,
      hermes,
      "partner",
      14,
      state,
      t
    );
  },
  deleteUniversal: async function(state, endpoint, formdata, tab, t){
    try {
      await Axios.delete(
        endpoint,
        {
          onTokenRefreshed: () => {
            this.deleteUniversal(state, endpoint, formdata, tab, t);
          },
        }
      ).then((resp) => {
        if (resp && resp.status== 204) {
          toast.info(t("message.deleteSuccessful"))
          state.value.tab = tab
          state.value[`${formdata}Id`] = 0;
        state.value[`${formdata}Edit`] = false
        } else {
          toast.info(t("message.deleteNotSuccessful"))
        }
      });
    } catch (e) {
      toast.info(t("message.deleteNotSuccessful"))
    }
  }
};

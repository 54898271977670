import Axios from "@/misc/axios";
import TenantConfigHandler from '@/misc/TenantConfigHandler';

let uniqueField = "documentsGrid";

export default (premiseId, state, t, editDocumentFn, typeMapping, subTypeMapping) => {
  return {
    endpoint:
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/premise/${premiseId}/documents`,
    idkey: "id",
    defaultOrderKey: "id",
    defaultOrderDirection: "desc",
    orderLocal: false,
    rootkey: "content",
    pagination: {
      gridUniqueId: uniqueField,
      active: true,
      page: 0,
      size: 10,
      // possiblePageSizes: [10, 20, 50, 100]
    },
    filtering: {
      active: true,
      local: false,
      //simple: true,
      //simpleHelpText: l('rvg-simple-filter-help-text'),
    },
    bulkOperation: {
      active: false,
    },
    columnSelector: {
      active: true,
      gridUniqueId: uniqueField,
      mode: "server", // server or local/null
      serverGridKey: "worksGridColumns",
    },
    xlsxExport: {
      active: true,
    },
    refreshable: {
      manualActive: true,
      autoActive: true,
      autoCanBeDisabled: true,
      gridUniqueId: uniqueField,
      autoValues: [
        {
          key: "30sec",
          refreshInterval: 30,
          title: t("message.rvg_automatic_list_refresh_30_sec"),
          default: true,
        },
        {
          key: "1min",
          refreshInterval: 60,
          title: t("message.rvg_automatic_list_refresh_1_min"),
        },
        {
          key: "5min",
          refreshInterval: 300,
          title: t("message.rvg_automatic_list_refresh_5_min"),
        },
      ],
    },
    mapping: {
      $operations: {
        title: "",
        visible: true,
        filterable: false,
        selectable: false,
        orderable: false,
        width: "50px",
        formatter: {
          type: "Operations",
          config: {
            buttons: (vm, callMeToRefreshTheGrid, rowid, record) => {
              var buttons = [];

              buttons.push({
                title: "",
                tooltip: t("message.edit"),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M12 3L2 12H5V20H10V14H14V15.11L19.43 9.68L12 3M21.04 11.14C20.9 11.14 20.76 11.2 20.65 11.3L19.65 12.3L21.7 14.35L22.7 13.35C22.91 13.14 22.91 12.79 22.7 12.58L21.42 11.3C21.32 11.2 21.18 11.14 21.04 11.14M19.06 12.88L13 18.94V21H15.06L21.11 14.93L19.06 12.88Z" /></svg>',
                classList: "btn btn-secondary btn-sm hover:!opacity-80 btn-circle",
                testValueAttribute: "documentEditButton",
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  editDocumentFn(record.id)
                },
              });

              buttons.push({
                title: "",
                tooltip: t("message.download"),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4"><path stroke-linecap="round" stroke-linejoin="round" d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" /></svg>',
                classList:
                  "btn btn-secondary btn-sm hover:!opacity-80 btn-circle",
                testValueAttribute: "documentDownloadButton",
                dropdowned: false,
                //disabled: record.endDate ? false : true,
                onClick: () => {
                  //console.log(record.id);
                  Axios.get(
                    TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/document/${record.id}/download`,
                    {
                      responseType: "blob",
                    }
                  )
                  .then((response) => {
                    var url = window.URL.createObjectURL(
                      new Blob([response.data], {
                        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      })
                    );
                    var link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", record.name);
                    document.body.appendChild(link);
                    link.click();
                  });
                }
                  
              });

              if(record.previewable){
              buttons.push({
                title: "",
                tooltip: t("message.preview"),
                icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16"><path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/><path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/><svg>',
                classList:
                  "btn btn-secondary btn-circle btn-sm hover:!opacity-80",
                testValueAttribute: "propertyEditButton",
                dropdowned: false,
                onClick: () => {             
                  Axios.get(
                    TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/document/${record.id}/preview`,
                    {
                      responseType: "blob",
                    }
                  )
                  .then((response) => {
                    var url = window.URL.createObjectURL(
                      new Blob([response.data], {type: response.data.type})
                    );
                    state.value.documentPreview.title = record.name
                    state.value.documentPreview.url = url
                    state.value.documentPreview.open = true;
                  });
                }
              });
            }
              

              return buttons;
            },
          },
        },
      },
      id: {
        title: "id",
        visible: false,
        selectable: false, // non-optional, must be selected
        filterable: {
          active: false,
          type: "Number",
        },
        exportable: true,
        orderable: true,
      },
      "name": {
        title: t("message.name"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record.name ? record.name : "N/A";
        },
      },
      "fileType": {
        title: t("message.fileType"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Text'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record.fileType ? record.fileType : 'N/A'
        }
      },
      "typeValue": {
        title: t("message.mainType"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record.typeValue ? record.typeValue : 'N/A'
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: typeMapping,
        },             
      },
      "subTypeValue": {
        title: t("message.subType"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: 'Status'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record.subTypeValue ? record.subTypeValue : 'N/A'
        },
        formatter: {
          type: "Status",
          dataType: "text",
          mapping: subTypeMapping,
        },             
      },
      "uploaderName": {
        title: t("message.uploader"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record.uploaderName ? record.uploaderName : "N/A";
        },
      },
      "uploadAt": {
        title: t("message.uploadDate"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: 'Date'
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {    
          return record.uploadAt ? new Date(record.uploadAt).toLocaleString() : 'N/A'
        },             
      },
      "dateOfOrigin": {
        title: t("message.dateOfOrigin"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: "Date",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record.dateOfOrigin
            ? new Date(record.dateOfOrigin).toLocaleString()
            : "N/A";
        },
      },
      "kng": {
        title: t("message.kng"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.kng
            ? record.kng
            : "N/A";
        },
      },
      "floc": {
        title: t("message.floc"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: true,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.floc
            ? record.floc
            : "N/A";
        },
      },
      "fullPrimaryPropertyAddress": {
        title: t("message.address"),
        visible: true,
        selectable: true, // non-optional, must be selected
        filterable: {
          active: false,
          type: "Text",
        },
        exportable: true,
        orderable: true,
        middleware: (value, record) => {
          return record?.fullPrimaryPropertyAddress
            ? record.fullPrimaryPropertyAddress
            : "N/A";
        },
      },
    },
  };
};

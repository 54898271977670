<template>
  <div class="w-full border border-gray-700 rounded-lg">
    <v-select v-if="props.type == 'uploader'"
      :options="state.suggestions"
      label="name"
      v-model="state.selected"
      @search="getOptions"
      :filterable="false"
      :placeholder="placeholder"
    >
      <!-- eslint-disable -->
      <template v-slot:no-options>
      {{$t("message.noMatchingOptions")}}
      </template>
      <!-- eslint-enable -->
    </v-select>

    <v-select v-if="props.type == 'siteDesign'"
      :options="state.suggestions"
      label="name"
      v-model="state.selected"
      @search="getOptions"
      :filterable="false"
      :placeholder="placeholder"
    >
      <!-- eslint-disable -->
      <template v-slot:no-options>
      {{$t("message.noMatchingOptions")}}
      </template>
      <!-- eslint-enable -->
    </v-select>

    <!-- <v-select v-if="props.type == 'gist'"
      :options="state.suggestions"
      label="displayName"
      v-model="state.selected"
      @search="getOptions"
      :filterable="false"
      :placeholder="placeholder"
    ></v-select> -->

    <v-select v-if="props.type == 'address'"
      :options="state.suggestions"
      label="completeAddress"
      v-model="state.selected"
      @search="getOptions"
      :filterable="false"
      :placeholder="placeholder"
    >
      <!-- eslint-disable -->
      <template v-slot:no-options>
      {{$t("message.noMatchingOptions")}}
      </template>
      <!-- eslint-enable -->
    </v-select>
  </div>
</template>

<script setup>
import "vue-select/dist/vue-select.css";
import axios from "@/misc/axios.js";
import { reactive, watch, computed } from "vue";
import { useI18n } from "vue-i18n";
import TenantConfigHandler from '@/misc/TenantConfigHandler';

const { t } = useI18n();

const props = defineProps(["modelValue", "type"]);
const emits = defineEmits(["update:modelValue"]);


const state = reactive({
  selected: props.modelValue || null,
  suggestions: [],
});

const getOptions = async (search) => {
  let uri = "";

  if (props.type == "siteDesign") {
    uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/search/siteDesignForRem?searchParam=${search}`;
  }

  if (props.type == "uploader") {
    uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/user/search-by-name?searchParam=${search}`;
  }

  // if (props.type == "gist") {
  //   uri =
  //     TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
  //     `/search/gistForRem?searchParam=${search}`;
  // }

  if(props.type == "address"){
    uri = TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/property/address/filter-options?searchParam=${search}`
  }

  const response = await axios.get(uri, {
    onTokenRefreshed: () => {
      getOptions(search);
    },
  });

  try {
    //console.log(response.data);
    if (Array.isArray(response.data)) {
      state.suggestions = response.data;
      //console.log(state.suggestions);
      
    } else {
      state.suggestions = [];
      console.error("Expected an array from the API, but got:", response.data);
    }

    if (props.modelValue) {
      state.selected =
        state.suggestions.find((option) => option.id === props.modelValue) ||
        null;
    }
  } catch (error) {
    console.log(error);
  }
};

const placeholder = computed(() => {
  let ph = "";

  if (props.type == "siteDesign") {
    ph = t("message.siteDesignPlaceholder");
  }

  if (props.type == "uploader") {
    ph = t("message.search");
  }

  // if(props.type == 'gist'){
  //   ph = t("message.typeGistName");
  // }

  if (props.type == "address") {
    ph = t("message.search");
  }

  return ph;
});

watch(
  () => props.modelValue,
  (newValue) => {
    state.selected =
      state.suggestions.find((option) => option.id === newValue) || null;
  }
);

watch(
  () => state.selected,
  (newValue) => {
    if (newValue) {
      emits("update:modelValue", newValue.id);
    }else{
      emits("update:modelValue", null)
    }
  }
);
</script>

<style>
.v-select .vs__dropdown-toggle {
  background-color: hsl(220, 17.647%, 20%);
}

.v-select .vs__dropdown-menu {
  @apply border border-gray-700 rounded-md text-sm;
  background-color: hsl(220, 17.647%, 20%);
}

.v-select .vs__selected {
  @apply text-gray-400 text-sm;
}

.v-select .vs__open-indicator {
  @apply text-gray-500;
}
</style>

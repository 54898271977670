<template>
    <div>
        <input type="checkbox" class="modal-toggle" v-model="showConfirmation" />
        <div class="modal">
            <div class="modal-box">
                <h3 class="text-lg text-left font-normal hover:text-gray-100 text-gray-100">
                    {{ $t(`message.${confirmationTextToken}`) }}
                </h3>
                <div class="modal-action">
                    <label class="btn btn-sm text-white font-light text-xs" @click="confirmationFalseMethod">{{ $t("message.no") }}</label>
                    <label class="btn btn-sm bg-magenta text-white font-light text-xs" @click="confirmationTrueMethod">{{ $t("message.yes") }}</label>
                    <!--<label class="btn btn-sm text-white font-light text-xs" @click="showConfirmation = false">{{ $t("message.cancel") }}</label>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineModel } from 'vue';

const showConfirmation = defineModel('showConfirmation');
const confirmationTextToken = defineModel('confirmationTextToken');
const confirmationTrueMethod = defineModel('confirmationTrueMethod');
const confirmationFalseMethod = defineModel('confirmationFalseMethod');




</script>



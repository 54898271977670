<template>
    <div id="BuildingPage" class="h-screen">
        <BaseLayout>
            <template #leftButtons>
                <BackToMapButton />
            </template>
            <template #mainContent>
                <RemFormLayout>
                    <template #remformheader>
                        <div class="items-start  pb-6 mx-auto md:flex-row mb-4">
                            <div class="flex justify-between mb-4">
                                <h1 class="flex mb text-xl text-secondary md:mb-0 uppercase font-bold">
                                    {{ $t("message.partners") }}
                                </h1>
                            </div>
                        </div>

                    </template>

                    <template #remformsidemenu>

                        <div class="flex-row mb-4" v-if="store.getters.getRemTab == 1">
                            <SideMenuButton :onClick="() => { }" :activeCheck="store.getters.getRemTab == 1"
                                :icon="'partner_icon'" :buttonLabel="'partners'"></SideMenuButton>

                        </div>

                        <div class="flex-row mb-4" v-if="store.getters.getRemTab != 1">
                            <SideMenuButton :onClick="() => { setTab(1) }" :activeCheck="store.getters.getRemTab == 1"
                                :icon="'partner_icon'" :buttonLabel="'partners'">
                                <template v-slot:customIcon>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                                    </svg>
                                </template>
                            </SideMenuButton>
                        </div>

                        <div class="flex-row mb-4" v-if="store.getters.getRemTab != 1 && state.partnerId != 0">
                            <SideMenuButton :onClick="() => { setTab(3); }" :activeCheck="store.getters.getRemTab == 3"
                                :icon="'building2_icon'" :buttonLabel="'addresses'"></SideMenuButton>
                        </div>

                        <div class="flex-row mb-4" v-if="store.getters.getRemTab != 1 && state.partnerId != 0">
                            <SideMenuButton :onClick="() => { setTab(5) }" :activeCheck="store.getters.getRemTab == 5"
                                :icon="'partner_icon'" :buttonLabel="'contacts'"></SideMenuButton>
                        </div>

                    </template>
                    <template #remformmain>
                        <!-------------------------Partner ------------>

                        <RemFormCard v-if="store.getters.getRemTab == 1">
                            <template #remcardheader>
                                <h2 class="text-xl text-secondary w-full font-bold mb-8">
                                    {{ $t("message.partners") }}
                                </h2>
                                <RemPrimaryButton :method="() => { addNewTab('partner', 2) }"
                                    :label="$t('message.addNew')" class="mb-8"></RemPrimaryButton>

                            </template>
                            <template #remcardmain>
                                <RobberVueGrid ref="partnerGrid" :config="partnerGridConfigComputed" />
                            </template>
                        </RemFormCard>




                        <!------------------ Partner - szerkesztés ---------------->
                        <RemFormCard v-if="store.getters.getRemTab == 2">
                            <template #remcardheader>
                                <RemFormBackbutton :method="() => { setTab(1) }"></RemFormBackbutton>
                                <h2 class="text-xl text-secondary w-full font-bold">
                                    {{ state.partnerData[0].value }}
                                </h2>

                            </template>
                            <template #remcardmain>
                                <div v-for="item in state.partnerData" v-bind:key="item.model" class="mb-8">

                                    <div v-if="store.getters.getRemFormEdit == false">
                                        <ImFormLabel v-if="(item.model != 'taxId' && item.model != 'taxNumber')
                                            || ((item.model == 'taxId' && state.isPerson == true)
                                                || ((item.model == 'taxNumber' && state.isPerson == false)))"
                                            :item="item"></ImFormLabel>
                                    </div>
                                    <div v-if="store.getters.getRemFormEdit == true">
                                        <!-- eslint-disable -->
                                        <ImFormInput v-if="(item.model != 'taxId' && item.model != 'taxNumber')
                                            || ((item.model == 'taxId' && state.isPerson == true)
                                                || ((item.model == 'taxNumber' && state.isPerson == false)))"
                                            v-model:inputValue="item.value" v-model:parent="item.parent"
                                            :title="$t('message.' + item.title)" :type="item.type"
                                            :valueLabel="item.valueLabel" :optionValue="item.optionValue"
                                            :endPoint="item.endPoint" :manualOptions="item.manualOptions"
                                            :displayValue="item.endPoint" :searchable="item.searchable"
                                            :onchangeMethod="item.onchangeMethod"
                                            :validationMethod="item.validationMethod">
                                        </ImFormInput>
                                        <!-- eslint-enable -->

                                    </div>
                                </div>

                                <!-- eslint-disable -->
                                <RemFormButtonRowStored :saveMethod="savePartner" :deleteMethod="deletePartner">
                                </RemFormButtonRowStored>
                                <!-- eslint-enable -->
                            </template>
                        </RemFormCard>



                        <!-------------------------Cím ------------>

                        <!-- eslint-disable -->
                        <RemRVGForm v-if="(store.getters.getRemTab == 3 || store.getters.getRemTab == 4) && state.partnerId != 0" :title='state.partnerData[0].value + " : " + $t("message.address") '
                            rvgTab="3" formTab="4" :addNewMethod="() => { addNewTab('address', 4) }"
                            v-model:formData="state.addressData" :rvgConfig="partnerAddressGridConfigComputed"
                            :saveMethod="saveAddress" :deleteMethod="deleteAddress" :backMethod="() => { setTab(3) }" v-model:saveValidation="state.addressMainValidation">
                        </RemRVGForm>
                        <!-- eslint-enable -->

                        <!-------------------------contact  ------------>

                        <!-- eslint-disable -->
                        <RemRVGForm v-if="(store.getters.getRemTab == 5 || store.getters.getRemTab == 6) && state.partnerId != 0" :title='state.partnerData[0].value + " : " + $t("message.contacts")'
                            rvgTab="5" formTab="6" :addNewMethod="() => { addNewTab('contact', 6) }"
                            v-model:formData="state.contactData" :rvgConfig="partnerContactGridConfigComputed"
                            :saveMethod="saveContact" :deleteMethod="deleteContact" :backMethod="() => { setTab(5) }">
                        </RemRVGForm>
                        <!-- eslint-enable -->

                        


                    </template>
                </RemFormLayout>
                <!-- eslint-disable -->
                <RemFormConfirmationModal v-model:showConfirmation="state.confirmation.showConfirmation"
                    v-model:confirmationTextToken="state.confirmation.confirmationTextToken"
                    v-model:confirmationTrueMethod="state.confirmation.confirmationTrueMethod"
                    v-model:confirmationFalseMethod="state.confirmation.confirmationFalseMethod">
                </RemFormConfirmationModal>
                <!-- eslint-enable -->
            </template>
        </BaseLayout>
    </div>
</template>

<script setup>

// Components
import BaseLayout from "../layouts/BaseLayout.vue";
import BackToMapButton from "../components/BackToMapButton.vue";
//import RemFormButtonRow from "../components/REM/RemFormButtonRow.vue";
import ImFormInput from "../components/ImFormInput.vue";
import ImFormLabel from "../components/ImFormLabel.vue";
import RobberVueGrid from "@/components/RVG/RobberVueGrid.vue";
import SideMenuButton from "../components/REM/SideMenuButton.vue";
import RemFormLayout from "../components/REM/RemFormLayout.vue";
import RemFormButtonRowStored from "../components/REM/RemFormButtonRowStored.vue";
import RemFormCard from "../components/REM/RemFormCard.vue";
import RemPrimaryButton from "../components/REM/RemPrimaryButton.vue";
import RemFormConfirmationModal from "../components/REM/RemFormConfirmationModal.vue";
import RemFormBackbutton from "../components/REM/RemFormBackbutton.vue";
import RemRVGForm from "../components/REM/RemRVGForm.vue";


//Utils
import { onMounted, ref, watch, /*onMounted, reactive,*/ computed } from "vue";
import store from "@/misc/vuex-store";
import { useI18n } from "vue-i18n";
import partnerUtils from "./PartnerPageComponents/partnerUtils.js";
import toast from "@/misc/toast.js";
import TenantConfigHandler from '@/misc/TenantConfigHandler';
import { computedAsync } from '@vueuse/core'
import RVGUtils from "../components/RVG/RobberVueGridUtils.js";
import remFormUtils from "../misc/remFormUtils.js";

// FormData
import partnerData from "../misc/remFormdata/partner/partnerData.js";
import addressData from "../misc/remFormdata/partner/addressData.js";
import contactData from "../misc/remFormdata/partner/contactData.js";


// RVGs

import partnerGridConfig from "../misc/gridConfigs/partnerPage/partnerGridConfig.js";
import partnerAddressGridConfig from "../misc/gridConfigs/partnerPage/partnerAddressGridConfig.js";
import partnerContactGridConfig from "../misc/gridConfigs/partnerPage/partnerContactGridConfig.js";

/**
 * Setup
 */
const partnerGrid = ref()
const partnerAddressGrid = ref()
const partnerContactsGrid = ref()

const { t } = useI18n();



store.commit("setRemTab", 1)

const state = ref({
    tab: 1,
    partnerId: 0,
    partnerEdit: false,
    partnerData: partnerData.data,
    addressId: 0,
    addressEdit: false,
    addressData: addressData.data,
    addressMainValidation: false,
    contactId: 0,
    contactEdit: false,
    contactData: contactData.data,
    isPerson: false
});

state.value.tabsToConfirm = {
    2: {
        saveMethod: () => { savePartner() },
        name: 'partner'
    }, 4: {
        saveMethod: () => { saveAddress() },
        name: 'address'
    }, 6: {
        saveMethod: () => { saveContact() },
        name: 'contact'
    }
},



state.value.confirmation = {
    showConfirmation: false,
    confirmationTextToken: "saveConfirmation",
    confirmationTrueMethod: () => { },
    confirmationFalseMethod: () => { },
}


onMounted(() => {

    partnerUtils.setup(state)

});

/**
 *  RVGs
 */

const partnerGridConfigComputed = computedAsync(async () => {
    const [
        partnerMainTypeMapping,
        partnerSubTypeMapping
    ] = await Promise.all([
        RVGUtils.getFormatterMappingForOptionValue("PARTNER_MAIN_TYPE"),
        RVGUtils.getFormatterMappingForOptionValue("PARTNER_SUB_TYPE"),
    ])
    return partnerGridConfig(state, t, partnerMainTypeMapping, partnerSubTypeMapping);
}, partnerGridConfig(state, t));

let partnerAddressGridConfigComputed = computedAsync(async () => {
    const [
        basicDataTypeMapping,
    ] = await Promise.all([
        RVGUtils.getFormatterMappingForOptionValue("COUNTRY"),
    ])
    return partnerAddressGridConfig(state, t, basicDataTypeMapping);
}, partnerAddressGridConfig(state, t));

let partnerContactGridConfigComputed = computed(() => {
    return partnerContactGridConfig(state, t);
});

/**
 *  Watchers
 */


watch(() => state.value.partnerData[1].value,
    () => {
        if (store.getters.getRemTab == 2 && store.getters.getRemFormEdit == true) {
 

            setTimeout(() => {
                state.value.partnerData[2].parent = state.value.partnerData[1].value;
            }, 700);
        }

    });

watch(() => state.value.partnerData[3].value,
    () => {
        if (store.getters.getRemTab == 2 && store.getters.getRemFormEdit == true) {
            setTimeout(() => {
                state.value.isPerson =
                    state.value.partnerData[3].value == "true" ? true : false;
                if (state.value.isPerson == true) {
                    state.value.partnerData[5].value = "";
                } else {
                    state.value.partnerData[4].value = "";
                }
            }, 700);
        }

    });

watch(() => state.value.addressData[1].value,
    () => {

        if (store.getters.getRemTab == 4 && store.getters.getRemFormEdit == true) {

            setTimeout(() => {
                state.value.addressData[3].parent = state.value.addressData[1].value;
                state.value.addressData[4].parent = state.value.addressData[1].value;
                state.value.addressData[5].parent = state.value.addressData[1].value;

            }, 700);
        }
    });




state.value.addressData.forEach((e, i) => {

    if((e.validated == true || e.validated == undefined) && (e.mandatory == false || e.mandatory == undefined)) return;

    watch(() => state.value.addressData[i].validated,
        () => {
            let lever = true;
            state.value.addressData.forEach((d) => {
                if((e.mandatory == false || e.mandatory == undefined)) return;
                if (d.validated === false) {
                    lever = false
                }
                
            })
          
            
            state.value.addressMainValidation = lever
        });
})



watch(() => state.value.partnerId,
    () => {
        partnerContactGridConfigComputed = computed(() => {
            return partnerContactGridConfig(state, t);
        });

        partnerAddressGridConfigComputed = computedAsync(async () => {
            const [
                basicDataTypeMapping,
            ] = await Promise.all([
                RVGUtils.getFormatterMappingForOptionValue("COUNTRY"),
            ])
            return partnerAddressGridConfig(state, t, basicDataTypeMapping);
        }, partnerAddressGridConfig(state, t));

    });


/**
 * Savers
 */

function savePartner() {
    partnerUtils.savePartner(state, t)
    partnerGrid.value.refresh()
}

function saveAddress() {
    let lever = true;
    state.value.addressData.forEach((e) => {
        if (e.validated == false) {
            lever = false
        }
    })

    if (lever == true) {
        partnerUtils.saveAddress(state, t)
        partnerAddressGrid.value.refresh()
    } else {
        toast.error(t("message.missingFormFields"));
    }

}

function saveContact() {
    partnerUtils.saveContact(state, t)
    partnerContactsGrid.value.refresh()
}


function deletePartner() {
    deleteConfirmation(TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/partner/" +
        state.value.partnerId, 'partner', 1)
}

function deleteAddress() {
    deleteConfirmation(TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/partner/partner-address/" +
        state.value.addressId, 'address', 3)

}


function deleteContact() {
    deleteConfirmation(TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/partner/contact/" +
        state.value.contactId, 'contact', 5)

}

function deleteConfirmation(endpoint, formData, tab) {
    remFormUtils.deleteConfirmation(state, endpoint, formData, tab, t)
}

function addNewTab(name, tab) {
    remFormUtils.addNewTab(name, tab, state)
}

function setTab(tab) {
    remFormUtils.setTab(state, tab)
}


</script>

<template>
    <tr>
        <th class="text-white text-base">{{ item.translatedValue }}</th>
        <td class="text-white text-base">{{ item.type }}</td>
        <!--<td>
            <div class="badge badge-secondary"> <span v-for="(mType, i) in item.modificationTypes" v-bind:key="mType">
                    {{ mType }}<span v-if="i + 1 < item.modificationTypes.length">, </span></span></div>

        </td>-->
        <td>{{ item.parent== null ? "N.A" : item.parent}}</td>
        <td>
            <label :for="item.type" class="btn btn-square btn-secondary btn-md" @click="router.push({
        name: 'optionValueEditor',
        params: { optionType: item.type },
      });">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil"
                    viewBox="0 0 16 16">
                    <path
                        d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                </svg>
            </label>
  

        </td>
    </tr>

</template>

<script setup>
//import { Container, Draggable } from "vue3-smooth-dnd";
import { /*reactive, watch, onMounted, watch, computed,*/ ref } from 'vue';
import router from "@/misc/router";
//import { useRoute } from "vue-router";


const props = defineProps(['item']);

const item = ref(props.item)

</script>

<style scoped>
.modal-box {
    max-width: 75rem;

}
</style>
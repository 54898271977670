<template>




    <label for="mobile-search-modal" class="modal cursor-pointer items-start !z-[11000] bg-opacity-75">

        <label class="modal-box relative overflow-visible p-1 bg-transparent pt-32 shadow-none" for="">
            <div>

                <button v-for="item in state.multiSelectParams" @click="state.tabindex = item.index;"
                    v-bind:key="item.index"
                    :class="state.tabindex == item.index ? 'tab-button tab-button-focus' : 'tab-button '">{{
                        $t(item.name) }}</button>


                <div v-for="item in state.multiSelectParams" v-bind:key="item.index">
                    <div v-if="item.index == state.tabindex">
                        <h2 class="my-8"><strong>{{ $t(item.name) }}</strong></h2>

                        <multiselect class="multiselect-search-input input input-sm flex flex-row-reverse p-0 pt-1 pb-1"
                            :label="item.label" track-by="id" :loading="state.isLoading" v-model="state.selectedAsset"
                            ref="multiselectObject" :options="item.options" :placeholder="$t('message.search')"
                            @search-change="asyncFind" :group-values="item.groupValues" :group-label="item.groupLabel"
                            :group-select="false" selectLabel="" deselectLabel="" selectedLabel="">
                            <template #caret>
                                <div class="flex-0 h-full" style="padding: 8px 10px 0px 8px;">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke-width="1.5" stroke="#e53010" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M15.75 15.75l-2.489-2.489m0 0a3.375 3.375 0 10-4.773-4.773 3.375 3.375 0 004.774 4.774zM21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                            </template>
                            <template #noResult>
                                {{ $t("message.noResults") }}
                            </template>
                            <template #tag>

                            </template>
                            <template #noOptions>
                                {{ $t("message.atleastOneCharacter") }}
                            </template>
                            <template #option="oProps">
                                <div v-if="oProps.option.$isLabel">{{ oProps.option.$groupLabel }}</div>
                                <table style="width: 100%;" v-if="!oProps.option.$isLabel">
                                    <tr>
                                        <td v-for="col in item.columns" v-bind:key="col">{{ oProps.option[col] }}</td>
                                    </tr>
                                </table>
                            </template>
                        </multiselect>
                    </div>
                </div>

            </div>

        </label>

    </label>

</template>

<script setup>
import Utils from '../../misc/Utils'
import Multiselect from 'vue-multiselect';
import { reactive, watch,/*onMounted, watch, computed,*/ ref } from 'vue';
import Axios from '@/misc/axios';
import store from '@/misc/vuex-store';
//import SearchTable from './SearchSidebarConcentComponents/SearchTable.vue';
import { useRoute } from 'vue-router'; const route = useRoute();
import "vue-multiselect/dist/vue-multiselect.ssr.css"
import router from '@/misc/router'

import TenantConfigHandler from '@/misc/TenantConfigHandler';



//const emit = defineEmits(['closeSearchBar'])

const multiselectObject = ref(null)

const state = reactive({
    multiSelectParams: {
        1: {
            index: 1,
            name: "message.searchGeneric",
            label: "displayName",
            query: TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + '/search',
            options: [],
            groupValues: "groupItems",
            groupLabel: "groupLabel",
            columns: ['id', 'displayName', 'siteDesignId'],
            router: function () {
                if (state.selectedAsset.searchClass == "GIST") {
                    store.commit("showSidebar", {
                        type: "gistManager",
                        entity: { displayName: "system", id: state.selectedAsset.id, searchObject: state.selectedAsset },
                        params: {
                            entityType: "system",
                            back: () => {
                                store.commit("hideSidebar");
                            },
                        },
                    });
                } else if (state.selectedAsset.searchClass == "SITE_DESIGN") {
                    if (state.selectedAsset.type == 'building') {
                        store.commit('showSidebar', { type: 'siteDesign', entity: state.selectedAsset });
                        return;
                    }
                    store.commit('setSelectedSiteDesign', state.selectedAsset);
                    router.push({ name: "map", params: { siteDesignId: state.selectedAsset.id } })
                } else if (state.selectedAsset.searchClass == "ASSET") {
                    store.commit('setSelectedAsset', state.selectedAsset.id);
                    if (route.name != "devices" || route.params.siteDesignId != state.selectedAsset.siteDesignId) {
                        router.push({ name: 'devices', params: { siteDesignId: state.selectedAsset.siteDesignId } })
                    }
                } else if (state.selectedAsset.searchClass == "ZONE") {
                    store.commit('setSelectedSiteDesign', Utils.getSiteDesignById(state.selectedAsset.siteDesignId));

                    /*store.commit('showSidebar', { type: 'zone', entity: state.selectedAsset, params: {
              zoneGroups: state.selectedAsset.zoneGroupIds.filter(zg => state.selectedAsset.zoneGroupIds.includes(zg.id))
            }});*/

                    router.push({ name: "map", params: { siteDesignId: state.selectedAsset.siteDesignId, zone: state.selectedAsset.id } });
                }
            },

        },
        2: {
            index: 2,
            name: "message.searchSiteDesign",
            label: "name",
            query: TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + '/search/siteDesignForRem',
            options: [],
            columns: ['id', 'name', 'siteNameHierarchy'],
            router: function () {
                if (state.selectedAsset.type == 'building') {
                    store.commit('showSidebar', { type: 'siteDesign', entity: state.selectedAsset });
                    return;
                }
                store.commit('setSelectedSiteDesign', state.selectedAsset);
                router.push({ name: "map", params: { siteDesignId: state.selectedAsset.id } })

            },

        },
        3: {
            index: 3,
            name: "message.searchZone",
            query: TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + '/search/gistForRem',
            label: "displayName",
            options: [],
            columns: ['id', 'displayName', 'gistType'],
            router: function () {
                store.commit("showSidebar", {
                    type: "gistManager",
                    entity: { displayName: "system", id: state.selectedAsset.id, searchObject: state.selectedAsset },
                    params: {
                        entityType: "system",
                        back: () => {
                            store.commit("hideSidebar");
                        },
                    },
                });

            },

        }
    },
    //isSearchModalOpen: false,
    isLoading: false,
    selectedAsset: '',
    tabindex: 1,
})


watch(() => state.selectedAsset, () => {

    state.multiSelectParams[state.tabindex].router();
    /*if (state.tabindex == 2) {
      
        if (state.selectedAsset.type == 'building') {
            store.commit('showSidebar', { type: 'siteDesign', entity: state.selectedAsset });
            return;
        }
        store.commit('setSelectedSiteDesign', state.selectedAsset);
        router.push({ name: "map", params: { siteDesignId: state.selectedAsset.id } })
        state.isSearchModalOpen = false;
    }
    else if (val.id) {
        store.commit('setSelectedAsset', val.id);
        if (route.name != "devices" || route.params.siteDesignId != val.siteDesignId) {
            router.push({ name: 'devices', params: { siteDesignId: val.siteDesignId } })
        }

        state.isSearchModalOpen = false;
    }*/
});

/*watch(() => state.isSearchModalOpen, () => {
    if(state.isSearchModalOpen == false){
        emit('closeSearchBar', state.isSearchModalOpen);
    }
})*/




function groupValues(data) {


    data.forEach((group) => {
        group.groupItems.forEach((e) => {
            e.searchClass = group.groupName
        })
    })



    return data;
}

async function asyncFind(query) {
    if (query.length > 0) {
        state.isLoading = true

        await Axios.get(state.multiSelectParams[state.tabindex].query + '?searchParam=' + query, {
            onTokenRefreshed: () => {
                asyncFind(query);
            }
        }).then((response) => {
            if (response && response.data) {
                console.log(response.data);
                state.multiSelectParams[state.tabindex].options = response.data;
                if (state.tabindex == 1) {
                    state.multiSelectParams[state.tabindex].options = groupValues(response.data);


                } else {
                    state.multiSelectParams[state.tabindex].options = response.data;
                }


                // console.log(state.multiSelectParams[state.tabindex].options)


            }
        })

        state.isLoading = false
    }
}

</script>

<style scoped>
.modal-box {
    max-width: 75rem;
    width: 75rem;
}


.multiselect-search-input>>>.multiselect__content-wrapper {
    z-index: 9999 !important;
    top: 55px;
    left: 0px;
    background: #2a303d8c;
    border: 1px solid #2a303d;
    color: white;
    border-radius: 6px !important;
}

.multiselect-search-input>>>.multiselect__spinner {
    height: 90%;
    background: #2a303c;
}

.multiselect-search-input>>>.multiselect__spinner::before,
.multiselect-search-input>>>.multiselect__spinner::after {
    border-top-color: #e53010 !important;
}

.multiselect-search-input>>>.multiselect__option--group {
    background: #2a303d !important;
}

.multiselect-search-input>>>.multiselect__tags {
    background: transparent;
    border: none;
    line-height: 20px;
    padding-right: 0px;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.multiselect-search-input>>>.multiselect__tags input {
    background-color: transparent;
    color: white !important;
    font-size: 13px;
}

.multiselect-search-input>>>.multiselect__tags input::placeholder {
    color: #a6adba;
    font-size: 13px;
}

.multiselect-search-input>>>.multiselect__option--highlight {
    background-color: #e53010;
}

.multiselect-search-input>>>.multiselect__single {
    background-color: transparent;
    font-size: 13px;
    color: white;
}

.tab-button {
    padding: 10px;
    border-bottom: 1px solid #f07d00;
}

.tab-button-focus {
    padding: 10px;
    border-bottom: 3px solid #f07d00;
}
</style>
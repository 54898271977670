
export default {
  possibleOperations() {
    return {
      'eq': "megegyezik ezzel:"
    };
  },
  getTextual(operation, currentValue, formatter) {
    var possibleOperations = this.possibleOperations();
    
    var translatedValues = [];
    for(var i in currentValue) {
      if(formatter && formatter.mapping && formatter.mapping[currentValue[i]]) {
        translatedValues.push(formatter.mapping[currentValue[i]].title);
      }
    }
    let value = "";
    if (formatter) {
      value = formatter.mapping[''+currentValue].title;
    }
    
    return '%%fieldname%% ' + possibleOperations[operation] + ' ' + value
  },
  isMatch(recordValue, operation, matchingValue) {
    switch(operation) {
      case 'eq':
        return recordValue == matchingValue;
    }
  }
}
import Axios from "@/misc/axios";
import toast from "@/misc/toast.js";
//import { watchEffect } from "vue";
import router from "@/misc/router.js";
import TenantConfigHandler from "@/misc/TenantConfigHandler";
import Utils from "../../misc/Utils";

export default {
  asyncGet: async function (state, propertyId /*, formData*/) {
    const response = await Utils.fetchPropertyById(propertyId);

    if (response) {
      state.value.propertyBasicData.forEach((e) => {
        let model =
          (e.model + "").charAt(0).toUpperCase() + (e.model + "").slice(1);
        if (e.type == "option") {
          e.value = response[`basicData${model}Id`];
          e.valueLabel = response[`basicData${model}Value`];
        } else {
          e.value = response[`basicData${model}`];
        }
      });
    }
  },
  saveProperty: async function (state, t, propertyId) {
    const hermes = {};
    hermes.basicData = {};

    state.value.propertyBasicData.forEach((e) => {
      hermes.basicData[e.model] = e.value;
    });
    hermes.property = propertyId;

    if (propertyId != 0) {
      await Axios.put(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
          "/rem/property/" +
          propertyId,
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveProperty(state, t, propertyId);
          },
        }
      ).then(async (resp) => {
        if (resp) {
          if (resp.status == 200) {
            state.value.confirmationModal.show = false;
            toast.success(t("message.successfulSave"));
            await this.asyncGet(state, propertyId);
            state.value.basicDataEdit = false;
          } else {
            state.value.confirmationModal.show = false;
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          state.value.confirmationModal.show = false;
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    }
  },
  savePartner: async function (state, t, id) {
    const hermes = {
      partnerMainType: 0,
      partnerSubType: 0,
      name: "",
      taxId: "",
      taxNumber: "",
    };

    state.value.partnerData.forEach((e) => {
      if(e.model != ""){
        hermes[e.model] = e.value;
      }
    });

    Utils.putPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/property/${id}/partner`,
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        `/rem/partner/` +
        state.value.partnerId,
      hermes,
      "partner",
      3,
      state,
      t
    );

    state.value.confirmationModal.show = false;
  },
  deletePartner: async function (state, id, t) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/partner/${id}`;

    try {
      await Axios.delete(uri, {
        onTokenRefreshed: () => {
          this.deletePartner(id, t);
        },
      });
      state.value.confirmationModal.show = false;
      state.value.tab = 3;
      toast.success(t("message.deleteSuccessful"));
    } catch (error) {
      console.log(error);
      state.value.confirmationModal.show = false;
      toast.error(t("message.deleteNotSuccessful"));
    }
  },
  saveBuilding: async function (state, t, propertyId) {
    const hermes = {
      siteDesignId: "",
      property: propertyId,
      basicData: {
        floorArea: "",
        mtArea: "",
        mainFunction: "",
        premiseNumber: "",
        isAccessible: "",
        numberDedicated: "",
        numberCanBeSeated: "",
        numberSeated: "",
        maxHeight: "",
        status: "",
        floorsBelowSurface: "",
        floorsAboveSurface: "",
        attic: "",
        atticCanBeUsed: "",
        sap: "",
        tcafm: "",
        parcelNumber: "",
        operation: "",
        cleaner: "",
        supplies: "",
        tui: "",
        ownerOfAssets: "",
        assetManager: "",
      },
      paymentObligation: "",
      numberOfStairways: "",
      numberOfElevators: "",
      foundation: "",
      bearingMasonry: "",
      slabStructureInter: "",
      slabSubStructureInter: "",
      slabStructureClosing: "",
      slabSubStructureClosing: "",
      flatRoof: "",
      roofType: "",
      roofing: "",
      insulation: "",
    };

    state.value.buildingData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    state.value.buildingBasicData.forEach((e) => {
      hermes.basicData[e.model] = e.value;
    });

    if (state.value.buildingId != 0) {
      await Axios.put(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
          "/rem/building/" +
          state.value.buildingId,
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveBuilding(state, t);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 200) {
            state.value.confirmationModal.show = false;
            toast.success(t("message.successfulSave"));
            state.value.buildingData.forEach((e) => {
              e.value = "";
            });
            state.value.buildingBasicData.forEach((e) => {
              e.value = "";
            });
            state.value.tab = 1;
            state.value.buildingId = 0;
          } else {
            state.value.confirmationModal.show = false;
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          state.value.confirmationModal.show = false;
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    } else {
      await Axios.post(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/building",
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveBuilding(state, t, propertyId);
          },
        }
      ).then((resp) => {
        if (resp) {
          if (resp.status == 201) {
            state.value.confirmationModal.show = false;
            toast.success(t("message.successfulSave"));
            state.value.buildingData.forEach((e) => {
              e.value = "";
            });
            state.value.buildingBasicData.forEach((e) => {
              e.value = "";
            });
            state.value.tab = 1;
            state.value.buildingId = 0;
          } else {
            state.value.confirmationModal.show = false;
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          state.value.confirmationModal.show = false;
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    }
  },
  deleteBuilding: async function (state, t) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/building/${state.value.buildingId}`;

    try {
      await Axios.delete(uri, {
        onTokenRefreshed: () => {
          this.deleteBuilding(state, t);
        },
      });
      state.value.confirmationModal.show = false;
      toast.success(t("message.deleteSuccessful"));
      state.value.tab = 1;
    } catch (error) {
      console.log(error);
      state.value.confirmationModal.show = false;
      toast.error(t("message.deleteNotSuccessful"));
    }
  },
  deleteAddress: async function (state, id, t) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/property/address/${id}`;

    try {
      await Axios.delete(uri, {
        onTokenRefreshed: () => {
          this.deleteAddress();
        },
      });
      state.value.confirmationModal.show = false;
      state.value.tab = 2;
      toast.success(t("message.deleteSuccessful"));
    } catch (error) {
      console.log(error);
      state.value.confirmationModal.show = false;
      toast.error(t("message.deleteNotSuccessful"));
    }
  },
  saveSecondaryAddress: async function (state, propertyId, t) {
    const hermes = {
      propertyId: propertyId,
      country: "",
      postalCode: "",
      county: "",
      city: "",
      publicAreaName: "",
      publicAreaType: "",
      houseNumber: "",
      stairWay: "",
      floor: "",
      door: "",
      parcelNumber: "",
      gpsCoordinates: "",
      notes: "",
    };

    state.value.addressData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    Utils.putPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/property/address",
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/property/address/" +
        state.value.addressId,
      hermes,
      "address",
      2,
      state,
      t
    );
    state.value.confirmationModal.show = false;
  },
  deleteUtilization: async function (state, id, t) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/utilization/${id}`;

    try {
      await Axios.delete(uri, {
        onTokenRefreshed: () => {
          this.deleteUtilization();
        },
      });
      state.value.confirmationModal.show = false;
      state.value.tab = 7;
      toast.success(t("message.deleteSuccessful"));
    } catch (error) {
      console.log(error);
      state.value.confirmationModal.show = false;
      toast.error(t("message.deleteNotSuccessful"));
    }
  },
  saveUtilization: async function (state, propertyId, t) {
    const hermes = {
      propertyId: propertyId,
      floorSpace: "",
      legalRelationship: "",
      legalRelationshipStart: "",
      legalRelationshipEnd: "",
      partnerId: "",
    };

    state.value.utilizationData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    Utils.putPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/utilization",
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/utilization/" +
        state.value.utilizationId,
      hermes,
      "utilization",
      7,
      state,
      t
    );
    state.value.confirmationModal.show = false;
  },
  deleteObligation: async function (state, id, t) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/property/obligation/${id}`;

    try {
      await Axios.delete(uri, {
        onTokenRefreshed: () => {
          this.deleteObligation();
        },
      });
      state.value.confirmationModal.show = false;
      state.value.tab = 8;
      toast.success(t("message.deleteSuccessful"));
    } catch (error) {
      console.log(error);
      state.value.confirmationModal.show = false;
      toast.error(t("message.deleteNotSuccessful"));
    }
  },
  saveObligation: async function (state, t, propertyId) {
    const hermes = {
      propertyId: propertyId,
      obligationType: "",
      otherRight: "",
      permission: "",
      obliged: "",
    };

    state.value.obligationData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    Utils.putPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/property/obligation",
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/property/obligation/" +
        state.value.obligationId,
      hermes,
      "obligation",
      9,
      state,
      t
    );
    state.value.confirmationModal.show = false;
  },
  uploadFile: async function (state, docsInput, t, propertyId, propertyDocumentsGrid) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/property/${propertyId}/document`;

    const formData = new FormData();
    formData.append("mainType", state.value.selectedMainType);
    formData.append("subType", state.value.selectedSubType);
    formData.append("document", docsInput.value.files[0]);
    formData.append("name", state.value.docuName);
    formData.append("dateOfOrigin", state.value.docuOriginDate);

    try {
      await Axios.post(uri, formData);
      state.value.docuName = "";
      state.value.docuOriginDate = "";
      state.value.selectedMainType = "";
      state.value.selectedSubType = "";
      document.getElementById("file_input").value = "";
      toast.success(t("message.successfulSave"));
    } catch (error) {
      console.log(error);
      toast.error(t("message.saveError"));
    }

    propertyDocumentsGrid.value.refresh()
  },
  saveLegalsit: async function (state, t, propertyId) {
    const hermes = {
      propertyId: propertyId,
      legalSituationType: "",
      legalRelationshipType: "",
      ownership: "",
      legalRelationshipStart: "",
      legalRelationshipEnd: "",
      squareMeter: "",
    };

    state.value.legalsitData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    Utils.putPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/legal-situation",
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/legal-situation/" +
        state.value.legalsitId,
      hermes,
      "legalsit",
      6,
      state,
      t
    );
    state.value.confirmationModal.show = false;
  },
  deleteLegalSituation: async function (state, id, t) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/legal-situation/${id}`;

    try {
      await Axios.delete(uri, {
        onTokenRefreshed: () => {
          this.deleteLegalSituation();
        },
      });
      state.value.confirmationModal.show = false;
      state.value.tab = 6;
      toast.success(t("message.deleteSuccessful"));
    } catch (error) {
      console.log(error);
      state.value.confirmationModal.show = false;
      toast.error(t("message.deleteNotSuccessful"));
    }
  },
  toBuilding: function (id) {
    router.push({
      name: "buildingPage",
      params: { buildingId: id },
    });
  },
};

<template>
  <div id="DevicesView" class="h-full">
    <LeafletMap class="lMap" v-if="(selectedSiteDesign != null && selectedSiteDesign.map != null)" ref="map" :mapName="selectedSiteDesign.map" :geometry="selectedSiteDesign.geometry" :onMapReady="onMapReady">
      <template #mapContent v-if="state.mapReady">
        <div v-if="state.currentMarkers.length > 0">
          <LMarkerClusterGroup :options="{ spiderfyOnMaxZoom: false, disableClusteringAtZoom: 1, showCoverageOnHover: false }">
            <span v-for="(marker) in state.currentMarkers" :key="marker.index">
              <MapMarker 
                v-if="!selectedAsset || selectedAsset != marker.id" 
                :marker="marker" 
                :currentFloorGeometry="selectedSiteDesign.geometry" 
                :mapDimensionsX="state.mapDimensionsX" 
                :mapDimensionsY="state.mapDimensionsY" 
                :isSelected="false" 
                :selectMarker="(marker) => { selectAsset(marker.id) }" 
              />
            </span>
          </LMarkerClusterGroup>
          <MapMarker 
            v-if="selectedAssetObject" 
            :marker="selectedAssetObject" 
            :currentFloorGeometry="selectedSiteDesign.geometry" 
            :mapDimensionsX="state.mapDimensionsX" 
            :mapDimensionsY="state.mapDimensionsY" 
            :isSelected="true" 
            :deselectMarker="(assetId) => { clearSelectedAsset(assetId) }"
          />
        </div>
        <LPolygon v-for="(polygon, key) in polygonsExtended" :key="key" :lat-lngs="mapServerPointsToPolygonData(polygon.points)" :fillOpacity="polygon.fillOpacity.fillOpacity" :fillColor="polygon.fillOpacity.color" :color="polygon.fillOpacity.lineColor" :opacity="0.8" :weight="2"></LPolygon>
      </template>
    </LeafletMap>
  </div>
</template>

<script setup>
  import LeafletMap from '../../components/LeafletMap2.vue'
  import { LPolygon } from '@vue-leaflet/vue-leaflet';
  import MapMarker from './MapMarker.vue'
  import Utils from '../../misc/Utils';
  import { computed, onMounted, watch, reactive, nextTick } from 'vue';
  import store from '../../misc/vuex-store';
  import Axios from '@/misc/axios.js'
  import { LMarkerClusterGroup } from 'vue-leaflet-markercluster'
  import TenantConfigHandler from '@/misc/TenantConfigHandler';

  const state = reactive({
    loading: false,
    mapReady: false,
    mapDimensionsX: null,
    mapDimensionsY: null,
    currentMarkers: [],
    polygons: []
  });

  const polygonsExtended = computed(() => {
    var result = [];

    if(!selectedSiteDesign.value.geometry) {
      return [];
    }

    for(var i in state.polygons) {
      var cPoly = state.polygons[i];
      cPoly.fillOpacity = {
        fillOpacity: 0.1,
        color: process.env.VUE_APP_POLYGON_LINE_COLOR,
        lineColor: process.env.VUE_APP_POLYGON_FILL_COLOR
      };
      result.push(cPoly)
    }

    return result;
  });

  const selectedAsset = computed(() => {
    return store.getters.getSelectedAsset;
  });

  const selectedAssetObject = computed(() => {
    if(!selectedAsset.value || !state.currentMarkers) {
      return null;
    }

    for(var i in state.currentMarkers) {
      if(state.currentMarkers[i].id == selectedAsset.value) {
        return state.currentMarkers[i];
      }
    }

    return null;
  });

  const selectedSiteDesign = computed(() => {
    return store.getters.getSelectedSiteDesign;
  });

  
  onMounted(async () => {
    if(selectedSiteDesign.value) {
      initMap();
    }
  })

  watch(() => selectedSiteDesign.value, () => {
    if(selectedSiteDesign.value) {
      initMap();
    }
  })

  async function fetchPolygons() {
    state.loading = true;
    const response = await Utils.fetchZones(selectedSiteDesign.value.id);
    state.polygons = response.zones;
    state.loading = false;
  }

  function clearSelectedAsset(assetId) {
    store.commit('clearSelectedAsset', assetId);
  }

  function selectAsset(assetId) {
    store.commit('setSelectedAsset', assetId);
  }

  async function initMap() {
    nextTick(() => {
      getMarkersImmediately()
      pollMarkers()
      fetchPolygons()
    })
  }

  function onMapReady(mapDimensionsX, mapDimensionsY) {
    state.mapDimensionsX = mapDimensionsX
    state.mapDimensionsY = mapDimensionsY
    state.mapReady = true
  }

  async function getMarkersImmediately() {
    try { 
      const response = await Axios.get(TenantConfigHandler.getVariable('ADMIN_API_URL') + '/site-design/' + selectedSiteDesign.value.id + '/assets', {
        onTokenRefreshed: () => {
          getMarkersImmediately()
        }
      });
      state.currentMarkers = response.data;
    }
    catch(e) {
      console.log("NETWORK ERROR WHILE LOADING ASSETS!", e)
    }
  }

  function mapServerPointsToPolygonData(points) {
    var result = [];
    
    for(var i in points) {
      result.push(transformPointsToMap(points[i].x, points[i].y))
    }

    return result;
  }

  function transformPointsToMap(y, x) {
    if(!selectedSiteDesign.value.geometry) {
      return;
    }

    return Utils.transformPointsToMap(y, x, state.mapDimensionsX, state.mapDimensionsY, selectedSiteDesign.value.geometry)
  }

  function pollMarkers(preventRecursive) {
    setTimeout(async () => {
      try { 
        const response = await Axios.get(TenantConfigHandler.getVariable('ADMIN_API_URL') + '/site-design/' + selectedSiteDesign.value.id + '/assets', {
          onTokenRefreshed: () => {
            // no need to call again, we are already polling
          }
        });
        state.currentMarkers = response.data;
      }
      catch(e) {
        console.log("NETWORK ERROR WHILE LOADING ASSETS!")
      }

      if(!preventRecursive) {
        pollMarkers();
      }
    }, process.env.VUE_APP_POLLINTERVAL)
  }

</script>

<style scoped>
  @import "~leaflet.markercluster/dist/MarkerCluster.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

  .lMap >>> .leaflet-tooltip {
    background: transparent !important;
    border: none !important;
    border-radius: 6px;
    box-shadow: none;
    color: white;
    font-size: 18px;
    min-width: 170px;
  }
  .lMap >>> .leaflet-tooltip-pane:hover, .lMap >>> .leaflet-tooltip:hover {
    z-index: 9999;
  }

</style>

<template>
  <div class="form-control w-full" >
    <label class="label">
      <span class="label-text">
        {{ props.title }}
      </span>
      <span class="label-text-alt">
        {{ props.subtitle }}
      </span>
    </label>
    <div class="relative">
      <div  class="absolute right-5 top-2.5 z-10">
        <slot name="icon"></slot>
      </div>
      <div class="w-full">
        <VueTagsInput
          v-model="state.searchText"
          :select="true"
          :autocomplete-items="state.selectableGists"
          :autocomplete-min-length="0"
          :tags="state.selectedGists"
          :autocomplete-filter-duplicates="true"
          :max-tags="props.maxSelectableItems"
          :add-only-from-autocomplete="true"
          @tags-changed="tagsChanged"
          placeholder=""
          class="rounded-lg !border-none !shadow-none !outline-none !bg-[#2a303c]">
        </VueTagsInput>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { VueTagsInput } from '@vojtechlanka/vue-tags-input';
  import Axios from '@/misc/axios.js';
  import { watch, reactive, onMounted } from 'vue';
  import TenantConfigHandler from '@/misc/TenantConfigHandler';

  const props = defineProps({
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    },
    defaultValue: {
      type: Object,
      required: false,
      default: null
    },
    onValueChange: {
      type: Function,
      required: false,
      default: () => {}
    },
    maxSelectableItems: {
      type: Number,
      required: false,
      default: 2
    },
    adapterName: {
      type: String,
      required: true
    },
    gistType: {
      type: String,
      required: true
    }
  })

  const state = reactive({
    searchText: '',
    timeoutRef: null,
    selectedGists: [],
    selectableGists: []
  })

  function fetchPossibleGists() {
    Axios.get(TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/gists?gistType=' + props.gistType + '&adapterName=' + props.adapterName, {
      onTokenRefreshed: () => {
        fetchPossibleGists()
      }
    }).then((res) => {
      state.selectableGists = res.data.data.map((gist) => {
        return {
          id: gist.id,
          text: gist.displayName
        }
      });
    })
    .catch((err) => {
      console.log(err)
    })
  }

  function tagsChanged(newTags) {
    if(props.onValueChange) {
      props.onValueChange(newTags);
    }
  }

  watch(() => state.searchText, () => {
    if(state.timeoutRef) {
      window.clearTimeout(state.timeoutRef);
    }

    state.selectableGists = [];
    fetchPossibleGists();
  })

  onMounted(() => {
    fetchPossibleGists();
    if(props.defaultValue) {
      state.selectedGists = props.defaultValue;
    }
  })

</script>

<style scoped>
  .vue-tags-input >>> .ti-input {
    border-radius: 8px;
    border: 1px solid #434955;
  }

  .vue-tags-input >>> .ti-input input {
    background-color: transparent !important;
  }

  .vue-tags-input >>> .ti-tag {
    display: flex;
    flex-direction: row;
    background: #e53010;
  }

  .vue-tags-input >>> .ti-tag .ti-content, .vue-tags-input >>> .ti-tag .ti-actions {
    padding: 2px;
  }
</style>
<template>
  <div class="text-white flex flex-col gap-1" v-if="gistTypes && gistTypes.length > 0">
    <GistComponent v-for="gistType in gistTypes" :key="gistType.id" :gistType="gistType" :clickOnEdit="() => { clickOnEdit(gistType) }" :clickOnRemove="() => { clickOnRemove(gistType) }"></GistComponent>
  </div>
  <div class="text-xs alert" v-else>
    {{ $t("message.noDevice") }}
  </div>
</template>

<script>
import Utils from '../../../misc/Utils';
import GistComponent from './GistComponent.vue';
import Axios from '@/misc/axios.js'
import TenantConfigHandler from '@/misc/TenantConfigHandler';

export default {
  name: 'ListGistComponent',
  props: {
    clickOnEdit: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      gistTypes: null
    }
  },
  components: {
    GistComponent
  },
  methods: {
    getGistTypes() {
      if(this.gistTypes) return;
      Utils.getGistsByTypeAndId(this.sidebarData.params.entityType, this.sidebarData.entity.id, (response) => {
        this.gistTypes = response.data;
      });
    },
    async clickOnRemove(gistType) {
      let that = this;
      await Axios.delete(TenantConfigHandler.getVariable('ADMINV2_API_URL') + '/system/' + gistType.id + '/remove-gist').then(() => {
        that.gistTypes = null;
        that.getGistTypes();
      });
    }
  },
  computed: {
    sidebarData() {
      var entity = this.$store.getters.getSidebarEntity;
      return entity;
    },
    sidebarEntity() {
      return this.sidebarData.entity;
    }
  },
  mounted() {
    this.getGistTypes();
  }
}
</script>
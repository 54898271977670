import User from "./User";
import store from "./vuex-store";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import TenantConfigHandler from "./TenantConfigHandler";

export default {
  fetchMainToken(msalInstance, onReady) {
    const mainAccount = msalInstance.getAllAccounts()[0];
    if(mainAccount) {
      // Fetch main token silently
      let request = {
        scopes: [TenantConfigHandler.getVariable('AZURE_API_TOKEN_REQUEST')],
        account: mainAccount
        //forceRefresh: true,
        //cacheLookupPolicy: 1
      };

      msalInstance.acquireTokenSilent(request).then(response => {
        if(User.getAccessToken() != response.accessToken) {
          console.log('msalInstance.js:', 'New main token acuired, refreshing cookies.');
          User.refreshToken(response.accessToken, response.idToken)
        }

        if(onReady) {
          onReady();
        }
      }).catch(error => {
        if(error instanceof InteractionRequiredAuthError) {
          msalInstance.acquireTokenRedirect(request);
        }
      })
    }
    else {
      // @TODO: Edge case handling
      store.commit('clearAccessToken')
      store.commit('clearIdToken')
      store.commit('clearGraphAccessToken')
      store.commit('clearGraphIdToken')
      store.commit('clearRoles')

      //var targetPath = process.env.VUE_APP_ROUTE_AFTER_TOKEN_EXPIRED ? process.env.VUE_APP_ROUTE_AFTER_TOKEN_EXPIRED : '/token-expired';
      parent.location.href = '/';
    }
  },
  fetchGraphToken(msalInstance, onReady) {
    const mainAccount = msalInstance.getAllAccounts()[0];
    if(mainAccount) {
      // Fetch graph token silently
      msalInstance.acquireTokenSilent({
        scopes: ['https://graph.microsoft.com/User.Read'],
        account: msalInstance.getAllAccounts()[0]
      }).then(response => {
        if(User.getGraphAccessToken() != response.accessToken) {
          console.log('msalInstance.js:', 'New graph token acuired, refreshing cookies.');
          User.setGraphToken(response.accessToken, response.idToken)
        }

        if(onReady) {
          onReady()
        }
      }).catch(error => {
        console.log(error);
      })
    }
    else {
      store.commit('clearAccessToken')
      store.commit('clearIdToken')
      store.commit('clearGraphAccessToken')
      store.commit('clearGraphIdToken')
      store.commit('clearRoles')
      var targetPath = process.env.VUE_APP_ROUTE_AFTER_TOKEN_EXPIRED ? process.env.VUE_APP_ROUTE_AFTER_TOKEN_EXPIRED : '/token-expired';
      parent.location.href = targetPath;
    }
  },
  logout(msalInstance) {
    msalInstance.logoutRedirect();
  }
}
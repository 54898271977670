import Axios from "@/misc/axios.js";
import toast from "@/misc/toast.js";
import router from "@/misc/router.js";
import TenantConfigHandler from "@/misc/TenantConfigHandler";
import Utils from "../../misc/Utils";

export default {
  asyncGet: async function (state, buildingId /*, formData*/) {
    const response = await Utils.fetchBuildingById(buildingId);

    if (response) {
      state.value.basicData.forEach((e) => {
        let model = 
          (e.model + "").charAt(0).toUpperCase() + (e.model + "").slice(1);
          if (e.type == "option") {
            e.value = response[`basicData${model}Id`];
            e.valueLabel = response[`basicData${model}Value`];
          } else {
            e.value = response[`basicData${model}`];
          }
      });

      state.value.structureTypesData.forEach((e) => {
        if (e.type == "label") return;
        if (e.type == "option") {
          e.value = response[e.model + "Id"];
          e.valueLabel = response[e.model + "Value"];
        } else {
          e.value = response[e.model];
        }      
      });
    }
  },
  saveBuilding: async function (state, t, propertyId, buildingId, formData) {
    const hermes = {};

    state.value.structureTypesData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    hermes.basicData = {};
    state.value.basicData.forEach((e) => {
      hermes.basicData[e.model] = e.value;
    });
    hermes.property = propertyId;

    if (buildingId != 0) {
      await Axios.put(
        TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
          "/rem/building/" +
          buildingId,
        hermes,
        {
          onTokenRefreshed: () => {
            this.saveBuilding(state, t, propertyId, buildingId);
          },
        }
      ).then(async (resp) => {
        if (resp) {
          if (resp.status == 200) {
            state.value.confirmationModal.show = false;
            toast.success(t("message.successfulSave"));
            await this.asyncGet(state, buildingId, formData);
            state.value.structEdit = false;
            state.value.basicDataEdit = false;
          } else {
            state.value.confirmationModal.show = false;
            toast.error(t("message.notSuccessfulSave"));
          }
        } else {
          state.value.confirmationModal.show = false;
          toast.error(t("message.notSuccessfulSave"));
        }
      });
    }
  },
  uploadFile: async function (state, docsInput, t, buildingId, buildingDocumentsGrid) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/building/${buildingId}/document`;

    const formData = new FormData();
    formData.append("mainType", state.value.selectedMainType);
    formData.append("subType", state.value.selectedSubType);
    formData.append("document", docsInput.value.files[0]);
    formData.append("name", state.value.docuName);
    formData.append("dateOfOrigin", state.value.docuOriginDate);

    try {
      await Axios.post(uri, formData);
      state.value.docuName = "";
      state.value.docuOriginDate = "";
      state.value.selectedMainType = "";
      state.value.selectedSubType = "";
      document.getElementById("file_input").value = "";
      toast.success(t("message.successfulSave"));
    } catch (error) {
      console.log(error);
      toast.error(t("message.saveError"));
    }

    buildingDocumentsGrid.value.refresh()
  },
  deleteMeter: async function (state, id, t) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/meter/${id}`;

    try {
      await Axios.delete(uri, {
        onTokenRefreshed: () => {
          this.deleteMeter(state, id, t);
        },
      });
      state.value.confirmationModal.show = false;
      state.value.tab = 4;
      toast.success(t("message.deleteSuccessful"));
    } catch (error) {
      console.log(error);
      state.value.confirmationModal.show = false;
      toast.error(t("message.deleteNotSuccessful"));
    }
  },
  saveMeter: async function (state, buildingId, t) {
    const hermes = {
      buildingId: buildingId,
      gistId: "",
      type: "",
      utilityType: "",
      identifier: "",
      placement: "",
      readings: "",
      readingFrequency: "",
      commType: "",
      expirationDate: "",
      partnerId: "",
    };

    state.value.meterData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    Utils.putPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/meter",
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/meter/" +
        state.value.meterId,
      hermes,
      "meter",
      4,
      state,
      t
    );

    state.value.confirmationModal.show = false;
  },
  deleteUtilization: async function (state, id, t) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/utilization/${id}`;

    try {
      await Axios.delete(uri, {
        onTokenRefreshed: () => {
          this.deleteUtilization();
        },
      });
      state.value.confirmationModal.show = false;
      state.value.tab = 3;
      toast.success(t("message.deleteSuccessful"));
    } catch (error) {
      console.log(error);
      state.value.confirmationModal.show = false;
      toast.error(t("message.deleteNotSuccessful"));
    }
  },
  saveUtilization: async function (state, buildingId, t) {
    const hermes = {
      buildingId: buildingId,
      floorSpace: "",
      legalRelationship: "",
      legalRelationshipStart: "",
      legalRelationshipEnd: "",
      partnerId: "",
    };

    state.value.utilizationData.forEach((e) => {
      hermes[e.model] = e.value;
    });

    Utils.putPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/utilization",
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/utilization/" +
        state.value.utilizationId,
      hermes,
      "utilization",
      3,
      state,
      t
    );

    state.value.confirmationModal.show = false;
  },
  deletePremise: async function (state, id, t) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/premise/${id}`;
    try {
      await Axios.delete(uri, {
        onTokenRefreshed: () => {
          this.deletePremise(state, id);
        },
      });
      state.value.confirmationModal.show = false;
      state.value.tab = 1;
      toast.success(t("message.deleteSuccessful"));
    } catch (error) {
      console.log(error);
      state.value.confirmationModal.show = false;
      toast.error(t("message.deleteNotSuccessful"));
    }
  },
  savePremise: async function (state, t, buildingId) {
    const hermes = {
      buildingId: buildingId,
      partnerIds: [],
      basicData: {},
    };

    state.value.premiseData.forEach((e) => {
      if (e.type == "label") return;
      let model = e.model.replace("basicData", "");
      model = model.charAt(0).toLowerCase() + model.slice(1);
      hermes.basicData[model] = e.value;
    });

    state.value.staircaseData.forEach((e) => {
      if (e.type == "label") return;
      let model = e.model.replace("basicData", "");
      model = model.charAt(0).toLowerCase() + model.slice(1);
      hermes.basicData[model] = e.value;
    });
    state.value.elevatorData.forEach((e) => {
      if (e.type == "label") return;
      let model = e.model.replace("basicData", "");
      model = model.charAt(0).toLowerCase() + model.slice(1);
      hermes.basicData[model] = e.value;
    });

    Utils.putPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/premise",
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/premise/" +
        state.value.premiseId,
      hermes,
      "premise",
      1,
      state,
      t
    );

    state.value.confirmationModal.show = false;
  },
  saveLegalsit: async function (state, t, buildingId) {
    const hermes = {
      /*
      buildingId: buildingId,
      legalSituationType: "",
      legalRelationshipType: "",
      ownership: "",
      legalRelationshipStart: "",
      legalRelationshipEnd: "",
      squareMeter: "",*/
    };

    state.value.legalsitData.forEach((e) => {
      hermes[e.model] = e.value;
    });
    hermes.buildingId = buildingId;

    console.log(hermes);

    Utils.putPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/legal-situation",
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        "/rem/legal-situation/" +
        state.value.legalsitId,
      hermes,
      "legalsit",
      2,
      state,
      t
    );

    state.value.confirmationModal.show = false;
  },
  deleteLegalsit: async function (state, id, t) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/legal-situation/${id}`;

    try {
      await Axios.delete(uri, {
        onTokenRefreshed: () => {
          this.deleteLegalsit(state ,id, t);
        },
      });
      state.value.confirmationModal.show = false;
      state.value.tab = 2;
      toast.success(t("message.deleteSuccessful"));
    } catch (error) {
      console.log(error);
      state.value.confirmationModal.show = false;
      toast.error(t("message.deleteNotSuccessful"));
    }
  },
  toPremise: function (id) {
    router.push({
      name: "premisePage",
      params: { premiseId: id },
    });
  },
  savePartner: async function (state, t, id) {
    const hermes = {
      partnerMainType: 0,
      partnerSubType: 0,
      name: "",
      taxId: "",
      taxNumber: "",
    };

    state.value.partnerData.forEach((e) => {
      if(e.model != ""){
        hermes[e.model] = e.value;
      }
    });

    Utils.putPost(
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + `/rem/building/${id}/partner`,
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
        `/rem/partner/` +
        state.value.partnerId,
      hermes,
      "partner",
      13,
      state,
      t
    );

    state.value.confirmationModal.show = false;
  },
  deletePartner: async function (state, id, t) {
    const uri =
      TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
      `/rem/partner/${id}`;

    try {
      await Axios.delete(uri, {
        onTokenRefreshed: () => {
          this.deletePartner(state, id, t);
        },
      });
      state.value.confirmationModal.show = false;
      state.value.tab = 13;
      toast.success(t("message.deleteSuccessful"));
    } catch (error) {
      console.log(error);
      state.value.confirmationModal.show = false;
      toast.error(t("message.deleteNotSuccessful"));
    }
  },
};

<template>
  <div class="text-white flex flex-col gap-1 mt-2" v-if="aggregationRules && aggregationRules.length > 0">
    <h2 class="text-base font-sans font-semibold uppercase text-secondary flex flex-row items-center gap-2 grow pl-3">
      <span class="text-white">{{ $t('message.aggregations') }}</span>
    </h2>
    <AggregationComponent v-for="aggregationRule in aggregationRules" :key="aggregationRule.id" :aggregationRule="aggregationRule" :clickOnEdit="() => { clickOnEdit(aggregationRule) }" :clickOnRemove="() => { clickOnRemove(aggregationRule) }"></AggregationComponent>
  </div>
</template>

<script>
import Utils from '../../../misc/Utils';
import AggregationComponent from './AggregationComponent.vue';
import Axios from '@/misc/axios.js'
import TenantConfigHandler from '@/misc/TenantConfigHandler';

export default {
  name: 'ListAggregationsComponent',
  props: {
    clickOnEdit: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      aggregationRules: null
    }
  },
  components: {
    AggregationComponent
  },
  methods: {
    getAggregationRules() {
      if(this.aggregationRules) return;
      Utils.getAggregationsByTypeAndId(this.sidebarData.params.entityType, this.sidebarData.entity.id, (response) => {
        this.aggregationRules = response.data;
      });
    },
    async clickOnRemove(aggregationRule) {
      let that = this;
      await Axios.delete(TenantConfigHandler.getVariable('ADMINV2_API_URL') + '/system/' + aggregationRule.id + '/remove-aggregation').then(() => {
        that.aggregationRules = null;
        that.getAggregationRules();
      });
    }
  },
  computed: {
    sidebarData() {
      var entity = this.$store.getters.getSidebarEntity;
      return entity;
    },
    sidebarEntity() {
      return this.sidebarData.entity;
    }
  },
  mounted() {
    this.getAggregationRules();
  }
}
</script>
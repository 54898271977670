<template>
    <div class="w-full overflow-x-auto">
        <TransitionGroup name="fade">
            <!---   Lista  -->
            <RemFormCard v-if="store.getters.getRemTab == rvgTab">
                <template #remcardheader>
                    <h2 class="text-xl text-secondary w-full font-bold mb-8">
                        {{ title }}
                    </h2>
                    <RemPrimaryButton class="mb-8" :method="addNewMethod" :label="$t('message.addNew')"></RemPrimaryButton>

                </template>
                <template #remcardmain>
                    <RobberVueGrid ref="centralHeatingGrid" :config="rvgConfig" />
                </template>
            </RemFormCard>

            <!---   szerkesztés   -->
            <RemFormCard v-if="store.getters.getRemTab == formTab">
                <template #remcardheader>
                    <RemFormBackbutton :method="backMethod"></RemFormBackbutton>
                    <h2 class="text-xl text-secondary w-full font-bold">
                        {{ title }}
                    </h2>
                </template>
                <template #remcardmain>
                    <div v-for="item in formData" v-bind:key="item.model" class="mb-8">
                        <div v-if="store.getters.getRemFormEdit == false">
                            <ImFormLabel :item="item"></ImFormLabel>
                        </div>
                        <div v-if="store.getters.getRemFormEdit == true">
                            <!-- eslint-disable -->
                            <ImFormInput v-model:inputValue="item.value" v-model:parent="item.parent" v-model:validated="item.validated"
                                :title="$t('message.' + item.title)" :type="item.type" :valueLabel="item.valueLabel"
                                :optionValue="item.optionValue" :endPoint="item.endPoint"
                                :manualOptions="item.manualOptions" :displayValue="item.displayValuet"
                                :searchable="item.searchable" :onchangeMethod="item.onchangeMethod"
                                :validationMethod="item.validationMethod" :mandatory="item.mandatory">
                            </ImFormInput>
                            <!-- eslint-enable -->
                        </div>
                    </div>

                    <!-- eslint-disable -->
                    <RemFormButtonRowStored :saveMethod="saveMethod" :deleteMethod="deleteMethod" v-model:saveValidation="saveValidation">
                    </RemFormButtonRowStored>
                    <!-- eslint-enable -->
                </template>
            </RemFormCard>
        </TransitionGroup>

    </div>
</template>

<script setup>
import RobberVueGrid from "../RVG/RobberVueGrid.vue";
import RemFormBackbutton from "./RemFormBackbutton.vue";
import RemPrimaryButton from "./RemPrimaryButton.vue";
import RemFormButtonRowStored from "./RemFormButtonRowStored.vue";
import RemFormCard from "./RemFormCard.vue";
import ImFormInput from "../ImFormInput.vue";
import ImFormLabel from "../ImFormLabel.vue";

import store from "@/misc/vuex-store";
import { defineModel } from 'vue';

defineProps(['title', 'rvgTab', 'formTab', 'addNewMethod', 'saveMethod', 'deleteMethod', "backMethod", "rvgConfig"])
const formData = defineModel('formData')
const saveValidation = defineModel('saveValidation')

</script>

<style scoped>
.cardBg {
    background-color: hsl(220, 17.647%, 20%);
}

.im-main-card {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
    padding: 3rem;
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    height: fit-content;
}

.im-main-card-starter {
    animation: 0.5s ease-out 0s 1 scaleupY;

}

.im-main-card-starter div {
    animation: 1.5s ease-out 0s 1 fadein;
}

@keyframes scaleupY {
    0% {
        transform-origin: left top;
        transform: scaleY(0);
    }

    100% {
        transform: scaleY(1);
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 100%;
    }
}

@keyframes fadeinbtn {
    0% {
        opacity: 0;
        display: flex;

    }

    50% {
        opacity: 0;

    }

    100% {
        opacity: 100%;

    }
}

@keyframes fadeoutbtn {
    0% {
        opacity: 100%;
        display: flex;

    }

    100% {
        opacity: 0;
        display: none;

    }
}

@keyframes widenbtn {
    0% {
        width: 10rem;
    }

    50% {
        width: 10rem;
    }

    100% {
        width: 18rem;
    }
}

.im-sidemenu-contanier {
    transition: width 0.5s;
    animation: 0.5s ease-out 0s 1 scaleupY;
    padding: 1rem;
    height: fit-content;
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
}

.im-sidemnu-row {
    width: 14rem;
}

.im-w-auto {
    margin-right: 2rem;
    width: 10rem;
}

.im-w-auto .tooltip-bottom {

    display: none;
}

.im-w-auto:hover {

    width: 18rem;

    .tooltip-bottom {
        animation: 0.5s ease-out 0s 1 fadeinbtn;
        display: flex;
    }
}


.im-sidemenu-contanier div {
    animation: 1.5s ease-out 0s 1 fadein;
}

.im-card-form {
    padding-top: 2rem;
}

.fade-enter-active {
    transition: opacity 1s ease 1s, padding 1s ease 1s, width 1s ease 1s;
}

.fade-leave-active {
    transition: opacity 0.5s ease, padding 0.5s ease, width 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    padding: 0;
    width: 0;
    opacity: 0;
}

:deep(.im-round-btn) {
    border-radius: 9999px;
}

/* button */


.im-button-active {
    border-color: lighter hsl(var(--s));
    ;
}

.im-button-disabled {
    border-color: #a6adbb;
    color: #a6adbb;
    background-color: rgba(0, 0, 0, 0);
}
</style>
<template>
    <div class="p-0 h-full overflow-y-scroll">
        <div class="p-5 pb-2 mr-2 flex flex-col grow border-b-0 border-base-300">
            <div class="flex flex-row items-center gap-2 justify-center">
                <button class="text-white" @click="() => { backButtonFunction(); }">
                    <SVGRenderer svgKey="back_icon" class="w-4 h-4" />
                </button>
                <h2 class="text-base font-sans font-semibold uppercase text-secondary flex flex-row items-center gap-2 grow">
                <span class="text-white">{{ sidebarEntity.entity == null ? "" : sidebarEntity.entity.name }}</span>
                <span v-if="sidebarEntity.entity == null">{{ $t('message.addNewSiteDesign') }}</span>
                <span v-else>- {{ $t('message.editSiteDesign') }}</span>
                </h2>
                <a href="javascript:void(null)" class="hover:text-secondary" @click="hideSidebar">
                    <SVGRenderer svgKey="x_icon" class="w-6 h-6" />
                </a>
            </div>
        </div>
        <div class="px-5 text-center">
            <div v-if="sidebarEntity.params.type == 'area'"  class="form-control w-full mt-0">
                <label class="label mt-0 pt-0">
                    <span class="label-text">{{ $t("message.displayName") }}</span>
                    <span class="label-text-alt"></span>
                </label>
                <input type="text" v-model="state.name" :placeholder="$t('message.siteDesignPlaceholder')" class="input input-bordered w-full mb-3" />
            </div>
            <div v-if="sidebarEntity.params.type == 'building'" class="form-control w-full mt-0">
                <label class="label mt-0 pt-0">
                    <span class="label-text">{{ $t("message.displayName") }}</span>
                    <span class="label-text-alt"></span>
                </label>
                <input type="text" v-model="state.name" :placeholder="$t('message.siteDesignPlaceholder')" class="input input-bordered w-full mb-3" />
                <label class="label mt-0 pt-0">
                    <span class="label-text">{{ $t("message.latitude") }}</span>
                    <span class="label-text-alt"></span>
                </label>
                <input type="text" v-model="state.latitude" placeholder="37.23554395" class="input input-bordered w-full mb-3" />
                <label class="label mt-0 pt-0">
                    <span class="label-text">{{ $t("message.longitude") }}</span>
                    <span class="label-text-alt"></span>
                </label>
                <input type="text" v-model="state.longitude" placeholder="-115.80581942" class="input input-bordered w-full mb-3" />
                <label class="label mt-0 pt-0">
                    <span class="label-text">{{ $t("message.address") }}</span>
                    <span class="label-text-alt"></span>
                </label>
                <input type="text" v-model="state.address" placeholder="1025 Budapest, Pusztaszeri út 48." class="input input-bordered w-full mb-3" />

            </div>
            <div v-if="sidebarEntity.params.type == 'floor'" class="form-control w-full mt-0">
                <label class="label mt-0 pt-0">
                    <span class="label-text">{{ $t("message.displayName") }}</span>
                    <span class="label-text-alt"></span>
                </label>
                <input type="text"  v-model="state.name" :placeholder="$t('message.siteDesignPlaceholder')" class="input input-bordered w-full mb-3" />
                <label class="label mt-0 pt-0">
                    <span class="label-text">{{ $t("message.mapImage") }}</span>
                    <span class="label-text-alt"></span>
                </label>
                <input @change="handleFileChange" type="file" accept=".jpg, .jpeg" class="w-full mb-3" />
            </div>
        </div>
        <div class="w-full mt-3 flex justify-center">
          <button :disabled="!validation()" @click="addNewSiteDesign" class="disabled:opacity-60 btn btn-sm bg-magenta text-white font-light text-xs border-none mr-1">
            {{ $t("message.save") }}
          </button>
          <button v-if="sidebarEntity.entity != null && User.hasPermission('DeleteSiteDesign')" @click="() => { state.showRemoveConfirmation = true }" class="btn btn-sm bg-magenta text-white font-light text-xs border-none ml-1">
            {{ $t("message.delete") }}
          </button>
        </div>
        <input type="checkbox" class="modal-toggle" v-model="state.showRemoveConfirmation" />
        <div class="modal">
            <div class="modal-box">
                <h3 class="text-lg text-left font-normal">{{ $t("message.confirmRemove") }}</h3>
                <div class="modal-action">
                    <label class="btn btn-sm text-white font-light text-xs" @click="() => { state.showRemoveConfirmation = false }">{{ $t("message.deny") }}</label>
                    <label class="btn btn-sm bg-magenta text-white font-light text-xs" @click="deleteConfirmed">{{ $t("message.confirm") }}</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import User from '@/misc/User';
    import { computed, onMounted, reactive, ref } from 'vue';
    import store from '@/misc/vuex-store';
    import SVGRenderer from '../../components/SVGRenderer.vue';
    import Axios from '@/misc/axios';
    import toast from '@/misc/toast.js';
    import TenantConfigHandler from '@/misc/TenantConfigHandler';
    const selectedFile = ref(null);

    const state = reactive({
        id: "",
        name: "",
        latitude: "",
        longitude: "",
        address: "",
        showRemoveConfirmation: false
    })

    const hideSidebar = () => {
        store.commit('hideSidebar');
    }
    const sidebarEntity = computed(() => {
        var entity = store.getters.getSidebarEntity;
        return entity;
    });
    const backButtonFunction = () => {
        if(sidebarEntity.value.params && sidebarEntity.value.params.back) {
            sidebarEntity.value.params.back();
        }
    }
    const handleFileChange = (event) => {
        selectedFile.value = event.target.files[0];
    };
    onMounted(() => {
        state.id = sidebarEntity.value.entity ? sidebarEntity.value.entity.id : "";
        state.name = sidebarEntity.value.entity ? sidebarEntity.value.entity.name : "";
        state.latitude = sidebarEntity.value.entity ? sidebarEntity.value.entity.latitude : "";
        state.longitude = sidebarEntity.value.entity ? sidebarEntity.value.entity.longitude : "";
        state.address = sidebarEntity.value.entity ? sidebarEntity.value.entity.address : "";
        state.showRemoveConfirmation = false;
    });
    const validation = () => {
        switch(sidebarEntity.value.params.type) {
            case 'floor':
                return sidebarEntity.value.entity == null ? state.name != "" && selectedFile.value != null : state.name != "";
            case 'building':
                return state.name != "" && state.latitude != "" && state.longitude != "" && state.address != "";
            case 'area':
                return state.name != "";
        }
    }

    const addNewSiteDesign = () => {
        let uri = TenantConfigHandler.getVariable("ADMINV2_API_URL") + '/site-design/' + sidebarEntity.value.params.parentId + "/manual/" + sidebarEntity.value.params.type;
        let formData = new FormData();
        formData.append('id', state.id);
        formData.append('name', state.name);
        formData.append('latitude', state.latitude);
        formData.append('longitude', state.longitude);
        formData.append('address', state.address);
        formData.append('image', selectedFile.value);

        Axios.post(uri, formData, {
            headers: {
                'content-type': 'multipart/form-data'
            },
            onTokenRefreshed: () => {
                addNewSiteDesign()
            }
        }).then((response) => {
            store.commit("addSiteDesign", response.data.entity);
            for(let child of response.data.children)
            {
                store.commit("updateSiteNameHierarchy", child);
            }
            state.id = "";
            state.name = "";
            state.latitude = "";
            state.longitude = "";
            state.address = "";
            sidebarEntity.value.params?.back?.call();
        })
        .catch((err) => {
            toast.error(err.response.data.message);
            console.error(err)
        })
    }
    const deleteConfirmed = () => {
        state.showRemoveConfirmation = false;
        Axios.delete(TenantConfigHandler.getVariable("ADMINV2_API_URL") + '/site-design/' + sidebarEntity.value.entity.id + '/manual').then(() => {
            let parent = store.getters.getSiteDesigns.find(x => x.id == sidebarEntity.value.entity.parentId);
            let id = sidebarEntity.value.entity.id;
            store.commit("removeSiteDesign", id);
            if(parent)
            {
                store.commit("showSidebar", { type: "siteDesign", entity: parent });
            }
            else
            {
                hideSidebar();
            }
        }).catch((err) => {
            console.error(err);
        });
    }
</script>
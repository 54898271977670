<template>
  <div class="flex flex-col">
    <div class="flex flex-row items-center justify-end">
      <a
        href="javascript:void(null)"
        class="hover:text-gray-500 text-secondary"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-2 -2 30 30" stroke-width="1.5" stroke="currentColor" class="w-9 h-9">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z"/>
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardFlipperButton',
};
</script>

<style>

</style>
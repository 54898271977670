<template>
  <div class="flex flex-row gap-2 my-2 ">
    <div class="w-1/2">
      <label>Művelet:</label>
      <select @change="changeValue" v-model="operation" class="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent">
        <option v-for="(option, key) in possibleOperations" :value="key" :key="key">{{ option }}</option>
      </select>
    </div>
    <div class="w-1/2">
      <label>Értékek kiválasztása:</label>
      <select :disabled="formatterConfig.mapping.length === 0" @change="e => changeValue(e.target.value)" v-model="currentSelectedValue" class="form-select mt-1.5 w-full rounded-lg border border-slate-300 bg-white px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent">
        <option value="null">Válasszon az értékek közül</option>
        <option v-for="(option, key) in formatterConfig.mapping" :value="key" :key="key">{{ option.title }}</option>
      </select>
    </div>
  </div>
</template>

<script>

import Config from './RVGFilterableStatus.config.js'

export default {
  name: "RVGFilterableStatus",
  props: ['data', 'config', 'id', 'formatterConfig'],
  components: {
  },
  data() {
    return {
      operation: 'eq',
      currentValue: [],
      currentSelectedValue: "null"
    }
  },
  computed: {
    possibleOperations() {
      return Config.possibleOperations()
    },
    validation() {
      return this.currentSelectedValue != null;
    }
  },
  methods: {
    getOptionById(optId) {
      for(var i in this.formatterConfig.mapping) {
        if(i == optId) {
          return this.formatterConfig.mapping[i]
        }
      }

      return null;
    },
    changeValue(currentValue) {
      let type = "text";
      
      if (this.formatterConfig.dataType == "integer") {
        currentValue = window.parseInt(currentValue)
        type = "number"
      }
      if (this.formatterConfig.dataType == "boolean") {
        currentValue = currentValue == "null"? null : currentValue == "true" ? true : false;
        type = "boolean"
      }

      this.$emit('changeValue', this.id, {
        field: this.id,
        filterKey: this.config.filterKey ? this.config.filterKey : this.id,
        type: type,
        operation: this.operation,
        value: currentValue,
        isValid: this.validation,
        textual: Config.getTextual(this.operation, this.currentValue)
      })
    }
  }
};
</script>

<style scoped>
  label {
    float: left;
  }

  .pull-left {
    float: left;
    text-align: left;
  }

  .inliner {
    display: inline-block;
  }

  .inlineLabel {
    width: 100%;
    text-align: left;;
  }

  .selectorStatusSelector {
    float: left;
  }
</style>

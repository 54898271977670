<template>
    <div ref="reportContainer"></div>
</template>

<script setup>
  import * as powerbi from "powerbi-client";
  import "powerbi-report-authoring";
  import Axios from "../../misc/axios";
  import { ref, onMounted } from "vue";
  import TenantConfigHandler from '@/misc/TenantConfigHandler';

  let refreshCount = 0;
  let powerbiService = null;
  let embedConfig = {
    type: "report",
    id: null,
    embedUrl: null,
    accessToken: null,
    permissions: powerbi.models.Permissions.All,
    tokenType: powerbi.models.TokenType.Embed,
    bootstrap:true,
    settings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
    }
  }
  const reportContainer = ref(null);
  function embedReport(){
    const container = reportContainer.value;
    if(powerbiService == null)
    {
      powerbiService = new powerbi.service.Service(powerbi.factories.hpmFactory, powerbi.factories.wpmpFactory, powerbi.factories.routerFactory);
    }
    powerbiService.reset(container)
    if(embedConfig.accessToken && embedConfig.id && embedConfig.embedUrl)
    {
      let embed = powerbiService.embed(container, embedConfig);
      embed.on('error', () => {
        handleError("Token Error", () => {
          updateConfig();
        });
      });
    }
    else
    {
      powerbiService.bootstrap(container, embedConfig);
    }
  }
  function handleError(err, onReady) {
    if(refreshCount < 3)
    {
      refreshCount++;
      onReady();
    }
    else
    {
      console.error(err);
    }
  }
  function updateConfig() {
    Axios.get(TenantConfigHandler.getVariable('FRONTENDV2_API_URL') + '/reports/pbi-key')
    .then((res) => {
      embedConfig = {
        type: "report",
        id: res.data.reportId,
        embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${res.data.reportId}&groupId=${res.data.groupId}`,
        accessToken: res.data.token,
        permissions: powerbi.models.Permissions.All,
        tokenType: powerbi.models.TokenType.Embed,
        settings: {
          filterPaneEnabled: true,
          navContentPaneEnabled: true,
        },
        viewMode: powerbi.models.ViewMode.View,
      }
      embedReport();
    })
    .catch((err) => {      
      handleError(err, () => {
        updateConfig();
      });
    });
  }
  onMounted(() => {
    embedReport();
    updateConfig();
  });

</script>

<style>
</style>
import Axios from "@/misc/axios";

import TenantConfigHandler from "@/misc/TenantConfigHandler";

export default {
  data: [
    {
      title: "addressType",
      value: "",
      model: "addressType",
      type: "option",
      optionValue: "PARTNER_ADDRESS_TYPE",
      mandatory: true,
      validated: true,
    },
    {
      title: "postal",
      value: "",
      model: "postalCode",
      type: "number",
      mandatory: true,
      validated: true,
      validationMethod: (
        options,
        optionsRepo,
        value,
        newValue,
        oldValue,
        inputValue,
        inputError
      ) => {
        if (newValue < 1000 || newValue > 9999) {
          inputError.value = "message.postalError";
        } else {
          inputError.value = "";
        }
      },
    },
    {
      title: "country",
      value: "",
      model: "country",
      type: "option",
      optionValue: "COUNTRY",
      mandatory: true,
      validated: true,
    },
    {
      title: "county",
      value: "",
      model: "county",
      type: "optionapi",
      mandatory: true,
      validated: true,
      //endpoint: TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/partner/settlement/postal",
      onchangeMethod: async (
        options,
        optionsRepo,
        value,
        inputValue,
        inputError
      ) => {
        if (value > 1000 && value < 9999) {
          try {
            await Axios.get(
              TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
                "/rem/partner/settlement/postal?postalCode=" +
                value
            ).then((resp) => {
              if (resp.data) {
                inputError.value = "";
                options.value = [];
                resp.data.forEach((elem) => {
                  if(!options.value.some(o => o.label == elem.county)){
                  options.value.push({
                    label: elem.county,
                    value: elem.county,
                  });
                }
                });
                optionsRepo = options.value;
                if (options.value.length == 1) {
                  inputValue.value = resp.data[0].county;
                }
              }
            });
          } catch (error) {
            options.value = [];
            inputValue.value = "";
            inputError.value = "message.postalError";
          }
        }
      },
    },
    {
      title: "city",
      value: "",
      model: "city",
      type: "optionapi",
      mandatory: true,
      validated: true,
      //endpoint: TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/partner/settlement/postal",
      onchangeMethod: async (
        options,
        optionsRepo,
        value,
        inputValue,
        inputError
      ) => {
        if (value > 1000 && value < 9999) {
          try {
            await Axios.get(
              TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
                "/rem/partner/settlement/postal?postalCode=" +
                value
            ).then((resp) => {
              console.log(resp.data);

              if (resp.data) {
                inputError.value = "";
                options.value = [];
                resp.data.forEach((elem) => {
                  options.value.push({
                    label: elem.name,
                    value: elem.name,
                  });
                });
                optionsRepo = options.value;
                if (options.value.length == 1) {
                  inputValue.value = resp.data[0].name;
                }
              }
            });
          } catch (error) {
            console.log(error);

            options.value = [];
            inputValue.value = "";
            inputError.value = "message.postalError";
          }
        }
      },
    },
    {
      title: "district",
      value: "",
      model: "district",
      type: "optionapi",
      mandatory: true,
      validated: true,
      //endpoint: TenantConfigHandler.getVariable("FRONTENDV2_API_URL") + "/rem/partner/settlement/postal",
      onchangeMethod: async (
        options,
        optionsRepo,
        value,

        inputValue,
        inputError
      ) => {
        if (value > 1000 && value < 9999) {
          try {
            await Axios.get(
              TenantConfigHandler.getVariable("FRONTENDV2_API_URL") +
                "/rem/partner/settlement/postal?postalCode=" +
                value
            ).then((resp) => {             
              if (resp.data) {
                inputError.value = "";
                options.value = [];
                resp.data.forEach((elem) => {
                  options.value.push({
                    label: elem.district,
                    value: elem.district,
                  });
                });
                
                if (options.value.length == 1) {
                  inputValue.value = resp.data[0].district;
                }
              }
            });
          } catch (error) {
            options.value = [];
            inputValue.value = "";
            inputError.value = "message.postalError";
          }
        }
      },
    },
    {
      title: "publicAreaName",
      value: "",
      model: "publicAreaName",
      type: "text",
      mandatory: true,
      validated: true,
    },
    {
      title: "publicAreaType",
      value: "",
      model: "publicAreaType",
      type: "option",
      searchable: true,
      optionValue: "PUBLIC_AREA_TYPE",
      mandatory: true,
      validated: true,
    },
    {
      title: "houseNumber",
      value: "",
      model: "houseNumber",
      type: "text",
      mandatory: true,
      validated: true,
      parent:"",
    },
    {
      title: "stairway",
      value: "",
      model: "stairWay",
      type: "text",
      mandatory: false,
      validated: true,
      parent:"",
    },
    {
      title: "floor",
      value: "",
      model: "floor",
      type: "text",
      validated: true,
    },
    {
      title: "door",
      value: "",
      model: "door",
      type: "text",
      validated: true,
    },
  ]
}
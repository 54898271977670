
export default [
    {
      title: "stairwayId",
      value: "",
      model: "basicDataStairwayIdentifier",
      type: "text",
    },
    {
      title: "stairwayType",
      value: "",
      model: "basicDataStairwayType",
      type: "text",
    },
    {
      title: "isEscapeRoute",
      value: "",
      model: "basicDataFireRoute",
      type: "checkbox",
    },
    {
      title: "isSmokeFree",
      value: "",
      model: "basicDataSmokeFree",
      type: "checkbox",
    },
  ]
<template>
  <div id="MessageEditorPage" class="h-screen max-h-screen">
    <BaseLayout>
      <template #leftButtons>
        <BackToMapButton />
      </template>
      <template #mainContent>

        <div class=" h-full max-h-full mx-auto px-2 overflow-y-auto k">
          <h2 class="text-lg font-sans font-semibold uppercase text-white my-8">{{ $t("message.messages") }}</h2>
          <div class="my-4 ">

            <div class="input-group input-groupsm mb-4">
              <input class="input input-bordered " type="text" v-model="state.keyQuery">
              <button class="btn btn-secondary">{{ $t('message.search') }}</button>
            </div>

            <div class="input-group input-groupsm mb-4">
              <input class="input input-bordered " type="text" v-model="state.langQuery">
              <select class="btn btn-secondary" name="" id="" v-model="state.langQueryLang">
                <option value="en">en</option>
                <option value="hu">hu</option>
                <option value="es">es</option>
                <option value="el">el</option>
                <option value="de">de</option>
              </select>
              <button class="btn btn-secondary">{{ $t('message.search') }}</button>
            </div>

            <div class="input-group input-groupsm">
              <input class="input input-bordered " type="text" v-model="state.newMessage.key" placeholder="key">
              <input class="input input-bordered " type="text" v-model="state.newMessage.hu" placeholder="hu">
              <input class="input input-bordered " type="text" v-model="state.newMessage.en" placeholder="en">
              <input class="input input-bordered " type="text" v-model="state.newMessage.es" placeholder="es">
              <input class="input input-bordered " type="text" v-model="state.newMessage.es" placeholder="el">
              <input class="input input-bordered " type="text" v-model="state.newMessage.de" placeholder="de">
              <button class="btn btn-secondary" @click="addNewMessage">{{ $t('message.addNew') }}</button>
            </div>
          </div>

          <button class="btn btn-secondary" @click="downloadMessage">{{ $t('message.download') }}</button>

          <div class="my-6 h-full max-h-80">
            <table class="table table-xs table-pin-cols table-pin-rows table-auto w-full ">
              <thead>
                <th class="text-white text-base">key</th>
                <!--<th class="text-white text-base">Módosítás</th> -->
                <th class="text-white text-base">HU</th>
                <th class="text-white text-base">EN</th>
                <th class="text-white text-base">ES</th>
                <th class="text-white text-base">EL</th>
                <th class="text-white text-base">DE</th>
              </thead>

              <tr v-for="item in state.options" v-bind:key="item.key">
                <td>{{ item.key }}</td>
                <td><input class="input input-sm input-bordered" type="text" v-model="item.hu"></td>
                <td><input class="input input-sm input-bordered" type="text" v-model="item.en"></td>
                <td><input class="input input-sm input-bordered" type="text" v-model="item.es"></td>
                <td><input class="input input-sm input-bordered" type="text" v-model="item.el"></td>
                <td><input class="input input-sm input-bordered" type="text" v-model="item.de"></td>
              </tr>

            </table>
          </div>
        </div>

      </template>
    </BaseLayout>
  </div>
</template>

<script setup>
import BaseLayout from '../layouts/BaseLayout.vue'
import BackToMapButton from '../components/BackToMapButton.vue'
import toast from "@/misc/toast.js";

import { watch, onMounted, reactive } from 'vue';
import messages from "../messages.js"
import { useI18n } from "vue-i18n";




const { t } = useI18n();

const state = reactive({
  keyQuery: "",
  langQuery: "",
  langQueryLang: "hu",
  options: [],
  optionsRepo: [],
  newMessage: {
    key: '',
    hu: '',
    en: '',
    es: '',
    el: '',
    de: ''
  }
});

//const language = computed(() => store.getters.getLanguage);

onMounted(() => {

  for (const [key, value] of Object.entries(messages.hu.message)) {
    state.optionsRepo.push({
      key: key,
      hu: value,
      en: messages.en.message[key],
      es: messages.es.message[key],
      el: messages.el.message[key],
      de: messages.de.message[key]
    })
    state.optionsRepo.sort((a, b) => {
      if (a.key < b.key) {
        return -1;
      }
      if (a.key > b.key) {
        return 1;
      }

      // names must be equal
      return 0;
    });
    state.options = state.optionsRepo
  }

  //console.log(state.optionsRepo)
})

watch(() => state.keyQuery, (newValue, oldValue) => {
  if (newValue == "") {
    state.options = state.optionsRepo;
  } else if (newValue != oldValue) {
    state.options = state.optionsRepo.filter(elem => (elem.key.toLowerCase()).includes(newValue.toLowerCase()));

  }
}
);

watch(() => state.langQuery, (newValue, oldValue) => {
  if (newValue == "") {
    state.options = state.optionsRepo;
  } else if (newValue != oldValue) {
    //console.log(state.langQueryLang);
    state.options = state.optionsRepo.filter((elem) => {
      if (elem[state.langQueryLang]) {
        return (elem[state.langQueryLang].toLowerCase()).includes(newValue.toLowerCase());
      }
      return false
    })
  }
});


const addNewMessage = () => {

let check = state.optionsRepo.find((element) => element.key == state.newMessage.key);
//console.log(check);

if(check){
  toast.error("Duplicate key");
}else{
  state.optionsRepo.push(state.newMessage);
  state.newMessage = {
    key: '',
    hu: '',
    en: '',
    es: '',
    el: '',
    de: ''
  }

  state.optionsRepo.sort((a, b) => {
    if (a.key < b.key) {
      return -1;
    }
    if (a.key > b.key) {
      return 1;
    }

    // names must be equal
    return 0;
  });
  state.options = state.optionsRepo
  toast.success(t("message.successfulSave"));
}

}


const downloadMessage = () => {
  let messageBuffer = "const messages = ";
  let objectBuffer = {
    hu: { message: {} },
    en: { message: {} },
    es: { message: {} },
    el: { message: {} },
    de: { message: {} }
  }

  for (const item of state.optionsRepo) {
    objectBuffer.hu.message[item.key] = item.hu;
    objectBuffer.en.message[item.key] = item.en;
    objectBuffer.es.message[item.key] = item.es;
    objectBuffer.el.message[item.key] = item.el;
    objectBuffer.de.message[item.key] = item.de;
  }

  messageBuffer +=  JSON.stringify(objectBuffer);
  messageBuffer += ";export default messages;";

  const url = window.URL.createObjectURL(new Blob([messageBuffer]));
  const link = document.createElement('a');
 
  let filename = 'messages.js';

  
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();

}



</script>

<style scoped></style>